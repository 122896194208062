const isStagingEnv = window.location.href.includes('staging');

function getClientUrl() {
  if (isStagingEnv) {
    return 'https://staging.floroteka.ua';
  }
  if (process.env.NODE_ENV === 'production') {
    return 'https://floroteka.ua';
  }
  return 'localhost:3001';
}

export const API_SERVER = process.env.REACT_APP_API_ENDPOINT || 'https://api.floroteka.ua';
export const CLIENT_URL = process.env.REACT_APP_FRONTEND_URL || getClientUrl();
// const API_SERVER = 'http://ec2-35-181-59-68.eu-west-3.compute.amazonaws.com:3000';
export const API_URI = `${API_SERVER}/admin`;
export const AUTH_URI = `${API_SERVER}/auth`;
export const TOKEN_EXPIRE_TIME = 30 * 24 * 60 * 60 * 1000;
export const SELF_NAME = (() => {
  if (window.location.hostname === 'localhost') {
    return 'Floroteka local';
  }
  if (isStagingEnv) {
    return 'Floroteka staging';
  }
  return 'Floroteka production';
})();
