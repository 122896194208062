import React from 'react';
import { TextField, Labeled, NumberInput, ReferenceField, useRecordContext } from 'react-admin';
import { Card, CardContent, Grid, Box } from '@mui/material';
import { useStyles } from '../styles';
import FormToolbar from '../../../common/FormToolbar';
import { RedirectField } from '../../../fields/RedirectField';
import LocalDateField from '../../../fields/LocalDateField';
import { PageTitle } from '../../../common/PageTitle';

export const TransactionEditForm = formProps => {
  const classes = useStyles();
  const record = useRecordContext();

  const redirectUrl = record.operation.model === 'Order' ? 'sales/orders' : 'sales/payments';

  return (
    <Box maxWidth="70em">
      <Card>
        <PageTitle record={record} titleText={`Транзакция ${record.operation?.code}`} />
        <CardContent className={classes.cardContent}>
          <Grid container>
            <Grid item sm={12} md={2}>
              <Labeled source="operationType" resource="sales/transactions">
                <TextField source="operationType" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={2}>
              <Labeled source="operation.docType" resource="sales/transactions">
                <TextField source="operation.docType" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={2}>
              <Labeled source="currency.name" resource="sales/transactions">
                <TextField source="currency.name" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={4}>
              <Labeled source="details" resource="sales/transactions">
                <RedirectField
                  record={record.operation}
                  fieldText={record.details}
                  linkIdSource="id"
                  linkUrl={redirectUrl}
                />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={2}>
              <Labeled source="operation.date" resource="sales/transactions">
                <LocalDateField source="operation.date" record={record} showTime />
              </Labeled>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={12} md={10} className={classes.itemsMargin}>
              <NumberInput resource="sales/transactions" source="amountBase" record={record} />
              <NumberInput resource="sales/transactions" source="amount" record={record} />
              <NumberInput resource="sales/transactions" source="balance" record={record} />
              <NumberInput resource="sales/transactions" source="currencyRate" record={record} />
            </Grid>
            <Grid item sm={12} md={2}>
              <ReferenceField reference="clients" source="client">
                <Labeled label="Клиент">
                  <TextField source="profile.businessName" />
                </Labeled>
              </ReferenceField>
            </Grid>
          </Grid>
        </CardContent>
        <FormToolbar {...formProps} />
      </Card>
    </Box>
  );
};
