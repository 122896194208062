import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles({
  headerText: {
    marginTop: 30,
  },
  tableContainer: {
    maxWidth: 1000,
  },
});

export const TransitStockQuantities = () => {
  const record = useRecordContext();
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      <Typography variant="h6" classes={{ root: classes.headerText }}>
        Транзитні позиції
      </Typography>
      <TableContainer classes={{ root: classes.tableContainer }} component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Код транзита</TableCell>
              <TableCell>Дата прибуття</TableCell>
              <TableCell>Код склада</TableCell>
              <TableCell>Назва склада</TableCell>
              <TableCell>{translate('resources.catalog/products.stock.supplier')}</TableCell>
              <TableCell>{translate('resources.catalog/products.stock.inStock')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.transits &&
              record.transits.map((item, idx) => {
                const { offerRule } = item || {};
                const { warehouse } = offerRule || {};
                if (!offerRule) return '';
                return (
                  <TableRow key={`transit-item-${idx}`}>
                    <TableCell>{offerRule?.code || ''}</TableCell>
                    <TableCell>{offerRule?.endDate || ''}</TableCell>
                    <TableCell>{warehouse?.code || ''}</TableCell>
                    <TableCell>{warehouse?.name || ''}</TableCell>
                    <TableCell>{item.inStock || 0}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
