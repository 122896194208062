import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  IconButton,
  ImageListItem,
  ImageListItemBar,
  ImageList as ImageListMUI,
  Tooltip,
} from '@mui/material';

import { StrictModeDroppable } from './StrictModeDroppable';
import { API_SERVER } from '../../../../consts';

const placeholderImage = '/flower-no-image.svg';

function downloadUrl(id) {
  return `${API_SERVER}/static/product/image/${id}/download/png`;
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  opacity: isDragging ? 0.3 : 1,
  cursor: 'move',
  ...draggableStyle,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DraggableImageList = ({ source = 'images' }) => {
  const { getValues, setValue } = useFormContext();
  const images = getValues(source);

  if (!images || !Array.isArray(images)) {
    return null;
  }

  const setImages = (imgs) => {
    setValue(source, imgs, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(images, result.source.index, result.destination.index);

    setImages(items);
  };

  const removeImage = (imgId) => {
    setImages(images.filter((i) => i !== imgId));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <ImageListMUI
            ref={provided.innerRef}
            rowHeight={150}
            sx={{ minWidth: 300, minHeight: 50 }}
            cols={4}
            gap={10}
            {...provided.droppableProps}
          >
            {images.map((imageId, index) => (
              <Draggable key={imageId} draggableId={imageId} index={index}>
                {(provided, snapshot) => (
                  <ImageListItem
                    sx={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div
                      style={{
                        // backgroundImage: `url(${image.url})`,
                        backgroundImage: imageId
                          ? `url(${API_SERVER}/static/product/image/${imageId}.png)`
                          : `url(${placeholderImage})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        width: 200,
                        height: 200,
                      }}
                    />
                    <ImageListItemBar
                      actionIcon={
                        <>
                          <Tooltip title="Завантажити">
                            <a
                              href={downloadUrl(imageId)}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}>
                                <CloudDownloadOutlined />
                              </IconButton>
                            </a>
                          </Tooltip>
                          <Tooltip title="Видалити">
                            <IconButton
                              onClick={() => removeImage(imageId)}
                              sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      }
                    />
                  </ImageListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ImageListMUI>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};

export { DraggableImageList };
