import React from 'react';
import { Form } from 'react-admin';
import { CustomEdit } from '../../../common/CustomEdit';
import { onSaveLoyalty } from '../helpers';
import { LoyaltyProgramForm } from './components/LoyaltyProgramForm';

export const LoyaltyProgramEdit = ({ classes, ...props }) => (
  <CustomEdit {...props} component="div" transform={onSaveLoyalty}>
    <Form>
      <LoyaltyProgramForm />
    </Form>
  </CustomEdit>
);
