import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { OrderTypesList } from './OrderTypesList';
import { OrderTypesCreate } from './OrderTypesCreate';
import { OrderTypesEdit } from './OrderTypesEdit';

export const Icon = LocalOfferIcon;

export default {
  list: OrderTypesList,
  edit: OrderTypesEdit,
  create: OrderTypesCreate,
};
