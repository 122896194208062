import SupplierList from './SupplierList';
import SupplierCreate from './SupplierCreate';
import SupplierEdit from './SupplierEdit';
import Icon from '@mui/icons-material/House';

export default {
  list: SupplierList,
  create: SupplierCreate,
  edit: SupplierEdit,
  icon: Icon,
};
