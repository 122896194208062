import React from 'react';
import { SimpleForm, ArrayInput, SelectInput, SimpleFormIterator } from 'react-admin';
import FormToolbar from '../../../../common/FormToolbar';

const TYPE_PRODUCT = 'product';
const TYPE_CATALOG_CATEGORY = 'catalogCategory';
const TYPE_COLOR = 'color';
const TYPE_COUNTRY = 'country';
const TYPE_HEIGHT = 'height';
const TYPE_MANUFACTURER = 'manufacturer';
const RESULT_TYPES = [
  TYPE_PRODUCT,
  TYPE_CATALOG_CATEGORY,
  TYPE_COLOR,
  TYPE_COUNTRY,
  TYPE_HEIGHT,
  TYPE_MANUFACTURER,
];
export const RESULT_TYPES_NAMES = {
  [TYPE_PRODUCT]: 'Продукты',
  [TYPE_CATALOG_CATEGORY]: 'Категории',
  [TYPE_COLOR]: 'Цвет',
  [TYPE_COUNTRY]: 'Страна',
  [TYPE_HEIGHT]: 'Высота',
  [TYPE_MANUFACTURER]: 'Производитель',
};

const searchResultsTypes = RESULT_TYPES.map(t => ({ id: t, name: RESULT_TYPES_NAMES[t] }));

export const SearchPreferencesForm = props => (
  <SimpleForm style={{ width: '100%' }} {...props} toolbar={<FormToolbar />}>
    <ArrayInput source="resultsOrder">
      <SimpleFormIterator>
        <SelectInput choices={searchResultsTypes} />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
);
