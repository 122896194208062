import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomCreate } from '../../../common/CustomCreate';
import { SearchPreferencesForm } from './components/SearchPreferencesForm';
import { transformPreferences } from './transformPreferences';

const SearchPreferencesTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? <span>{translate('resources.system/seo/search-preferences.title')}</span> : null;
};

export const SearchPreferencesCreate = props => (
  <CustomCreate
    title={<SearchPreferencesTitle />}
    component="div"
    transform={transformPreferences}
    redirect="list"
    {...props}
  >
    <Form>
      <SearchPreferencesForm />
    </Form>
  </CustomCreate>
);
