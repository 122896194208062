import React from 'react';
import { Create } from 'react-admin';
import CashierForm from './CashierForm';

const CashierCreate = props => (
  <Create redirect="list" {...props}>
    <CashierForm />
  </Create>
);

export default CashierCreate;
