import React from 'react';
import { TextField, NumberField, useRecordContext } from 'react-admin';
import LocalDateField from '../../fields/LocalDateField';
import { NewTabLinkDataGrid } from '../../common/NewTabLinkDataGrid';
import { RedirectField } from '../../fields/RedirectField';

const RedirectDetailsField = props => {
  const record = useRecordContext();
  return (
    <RedirectField
      linkUrl={record.operation?.model === 'Order' ? 'sales/orders' : 'sales/payments'}
      {...props}
    />
  );
};

export const TransactionsTable = props => {
  return (
    <NewTabLinkDataGrid rowClick="edit" {...props}>
      <TextField source="operationType" />
      <RedirectDetailsField source="details" linkIdSource="operation.id" />
      <TextField source="operation.docType" />
      <NumberField source="amountBase" options={{ style: 'currency', currency: 'UAH' }} />
      <NumberField source="amount" options={{ style: 'currency', currency: 'UAH' }} />
      <NumberField source="balance" options={{ style: 'currency', currency: 'UAH' }} />
      <NumberField source="currencyRate" />
      <TextField source="currencyBase.name" />
      <TextField source="currency.name" />
      <RedirectField
        source="client.businessName"
        linkIdSource="client.id"
        linkUrl="clients"
        label="Бизнес имя"
      />
      <LocalDateField source="operation.date" showTime />
      <LocalDateField source="createdAt" showTime />
      <LocalDateField source="updatedAt" showTime />
    </NewTabLinkDataGrid>
  );
};
