import React from 'react';
import Card from '@mui/material/Card';
import { useTranslate } from 'react-admin';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

const Dashboard = () => {
  const translate = useTranslate();
  return (
    <Card>
      <CardHeader title={translate('pos.dashboard.welcome.title')} />
      <CardContent></CardContent>
    </Card>
  );
};

export default Dashboard;
