import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';
import grey from '@mui/material/colors/grey';
import brown from '@mui/material/colors/brown';
import {
  USER_REG_STATUS_NO_REG,
  USER_REG_STATUS_APPROVED,
  USER_REG_STATUS_DOC_REQUIRED,
  USER_REG_STATUS_PENDING,
  USER_REG_STATUS_DECLINED,
} from '../../models/User';

const rowStyle = record => {
  let style = {};
  if (!record) {
    return style;
  }
  if (record.registrationStatus === USER_REG_STATUS_NO_REG)
    return {
      ...style,
      borderLeftColor: grey[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.registrationStatus === USER_REG_STATUS_APPROVED)
    return {
      ...style,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.registrationStatus === USER_REG_STATUS_PENDING)
    return {
      ...style,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.registrationStatus === USER_REG_STATUS_DOC_REQUIRED)
    return {
      ...style,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.registrationStatus === USER_REG_STATUS_DECLINED)
    return {
      ...style,
      borderLeftColor: brown[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  return style;
};

export default rowStyle;
