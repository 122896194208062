import PriceTypeList from './PriceTypeList';
import PriceTypeCreate from './PriceTypeCreate';
import PriceTypeEdit from './PriceTypeEdit';
import Icon from '@mui/icons-material/House';

export default {
  list: PriceTypeList,
  create: PriceTypeCreate,
  edit: PriceTypeEdit,
  icon: Icon,
};
