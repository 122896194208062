import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  itemsMargin: {
    '& > *': {
      marginRight: 30,
    },
  },
  cardContent: {
    paddingTop: 0,
  },
});
