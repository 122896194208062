import React from 'react';
import { TextField, SearchInput } from 'react-admin';
import { Chip } from '@mui/material';
import { ColorField } from 'react-admin-color-picker';
import ImagePreview from '../../../common/ImagePreview';
import { RedirectField } from '../../../fields/RedirectField';
import LocalDateField from '../../../fields/LocalDateField';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const offersFilter = [
  <SearchInput source="q" alwaysOn />,
  <Chip source="enabled" defaultValue={true} />,
];

export const OfferList = props => (
  <CustomList filters={offersFilter} {...props}>
    <NewTabLinkDataGrid rowClick="edit">
      <RedirectField
        label="resources.homePage.offers.fields.product"
        source="product.name"
        linkIdSource="product.id"
        linkUrl="catalog/products"
      />
      <TextField label="resources.homePage.offers.fields.header" source="header" />
      <ColorField label="resources.homePage.offers.fields.color" source="color" />
      <LocalDateField
        source="expiredTime"
        label="resources.homePage.offers.fields.expiredTime"
        showTime
      />
      <BooleanColoredField source="enabled" label="resources.homePage.offers.fields.enabled" />
      <ImagePreview
        urlSource="static/offer-images"
        label="resources.homePage.offers.fields.image"
      />
      <LocalDateField label="resources.commonFields.createdAt" source="createdAt" showTime />
      <LocalDateField label="resources.commonFields.updatedAt" source="updatedAt" showTime />
    </NewTabLinkDataGrid>
  </CustomList>
);
