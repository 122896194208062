import React from 'react';
import { DateInput, SearchInput, BooleanInput } from 'react-admin';
import { CustomList } from '../../common/CustomList';
import { OrdersTableContainer } from './components/OrdersTableContainer';

const orderFilter = [
  <SearchInput source="q" alwaysOn />,
  <SearchInput source="code" placeholder="Код" />,
  <DateInput source="createdAt" label="Дата заказа" />,
  <BooleanInput source="isSynced" label="Синхронизирован" />,
];

export const OrderList = props => (
  <CustomList filters={orderFilter} {...props}>
    <OrdersTableContainer />
  </CustomList>
);

export default OrderList;
