import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomEdit } from '../../common/CustomEdit';
import { DeliveryTimeForm } from './components/DeliveryTimeForm';
import { transformDeliveryTimes } from './transformDeliveryTimes';

const DeliveryTimeTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? <span>{translate('resources.sales/shipping/delivery-times.name')}</span> : null;
};

export const DeliveryTimesEdit = props => (
  <CustomEdit
    title={<DeliveryTimeTitle />}
    component="div"
    transform={transformDeliveryTimes}
    {...props}
  >
    <Form>
      <DeliveryTimeForm />
    </Form>
  </CustomEdit>
);
