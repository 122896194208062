import React from 'react';
import { BooleanField, useRecordContext } from 'react-admin';
import get from 'lodash/get';

export const BooleanColoredField = ({ className, reverse, source, ...props }) => {
  const record = useRecordContext();
  const state = get(record, source);
  const modifiedState = reverse ? !state : state;

  return (
    <BooleanField
      className={className}
      sx={{
        color: modifiedState ? 'lightgreen' : 'red',
      }}
      record={{
        ...record,
        [source]: modifiedState,
      }}
      source={source}
      {...props}
    />
  );
};
