import React from 'react';
import {
  BooleanInput,
  FormTab,
  SelectArrayInput,
  TabbedForm,
  NumberInput,
  ReferenceArrayInput,
  TextInput,
} from 'react-admin';
import FormToolbar from '../../common/FormToolbar';
import DeliverySchedule from '../../fields/DeliverySchedule';

const LocationsForm = (props) => {
  const {readonly = false} = props;
  return (
    <TabbedForm style={{ width: "100%" }} toolbar={<FormToolbar/>} {...props}>
      <FormTab label="resources.sales/shipping/locations.tabs.details">
        <TextInput source="name" readonly={readonly} label={"resources.sales/shipping/locations.fields.name"} />
        <TextInput source="state" readonly={readonly} label={"resources.sales/shipping/locations.fields.state"} />
        <TextInput source="region" readonly={readonly} label={"resources.sales/shipping/locations.fields.region"} />
        <TextInput source="city" readonly={readonly} label={"resources.sales/shipping/locations.fields.city"} />
        <ReferenceArrayInput
          source={'deliveryType'}
          reference="sales/shipping/methods"
          label={"resources.sales/shipping/locations.fields.deliveryType"}
        >
          <SelectArrayInput src={"name"}/>
        </ReferenceArrayInput>
        <NumberInput source="deliveryPricePerCity" label={"resources.sales/shipping/locations.fields.deliveryPricePerCity"} />
      </FormTab>
      <FormTab label={"resources.sales/shipping/locations.tabs.flags"}>
        <BooleanInput source="isCoveredByDeliveryNetwork" label={"resources.sales/shipping/locations.fields.isCoveredByDeliveryNetwork"} />
        <BooleanInput source="isDeliveryFree" label={"resources.sales/shipping/locations.fields.isDeliveryFree"} />
        <BooleanInput source="ignoreParentParams" label={"resources.sales/shipping/locations.fields.ignoreParentParams"} />
        <BooleanInput source="approved" label={"resources.sales/shipping/locations.fields.approved"} />
      </FormTab>
      <FormTab label={"resources.sales/shipping/locations.tabs.schedule"}>
        <DeliverySchedule />
      </FormTab>
    </TabbedForm>
  );
};

export default LocationsForm;
