import React from 'react';
import {
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  DateTimeInput,
  required,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  TextField,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';
import { CustomEdit } from '../../../common/CustomEdit';
import FormToolbar from '../../../common/FormToolbar';
import ImagePreview from '../../../common/ImagePreview';

async function onSave({ image, ...restData }) {
  const formData = new FormData();
  if (image) {
    formData.append('files[]', image.rawFile);
  }

  if (typeof restData.product !== 'string') {
    restData.product = restData.product.id;
  }

  formData.append('data', JSON.stringify(restData));

  return formData;
}

function filterToQuery(searchText) {
  return { q: searchText };
}

export const OfferEdit = ({ classes, ...props }) => (
  <CustomEdit redirect="list" {...props} transform={onSave}>
    <SimpleForm toolbar={<FormToolbar />}>
      <BooleanInput label="resources.homePage.offers.fields.enabled" source="enabled" />
      <TextField label="resources.homePage.offers.fields.oldProduct" source="product.name" />
      <ReferenceInput
        label="resources.homePage.offers.fields.newProduct"
        source="product"
        reference="catalog/products"
        filterToQuery={filterToQuery}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput
        label="resources.homePage.offers.fields.header"
        source="header"
        validate={[required()]}
        autoFocus
      />
      <ColorInput
        label="resources.homePage.offers.fields.color"
        source="color"
        validate={[required()]}
      />
      <DateTimeInput label="resources.homePage.offers.fields.expiredTime" source="expiredTime" />
      <ImagePreview
        urlSource="static/offer-images"
        label="resources.homePage.offers.fields.image"
        imageStyles={{ width: 300, height: 300 }}
      />
      <ImageInput
        label="resources.homePage.offers.fields.image"
        source="image"
        accept={{ 'image/*': ['.*'] }}
      >
        <ImageField source="image" title="Background image" validate={[required()]} />
      </ImageInput>
    </SimpleForm>
  </CustomEdit>
);
