import React from 'react';
import { TextField, ChipField, ReferenceField, TextInput } from 'react-admin';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const priceTypesFilter = [<TextInput source="q" alwaysOn label="Поиск" />];

const PriceTypeList = props => {
  return (
    <CustomList filters={priceTypesFilter} {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="code" />
        <TextField source="name" />
        <BooleanColoredField source="allowImport" />

        <BooleanColoredField source="isDefault" />
        <BooleanColoredField source="isTransit" />
        <BooleanColoredField source="isPreOrder" />

        <ReferenceField reference="system/price-type-groups" source="priceTypeGroup">
          <ChipField source="name" />
        </ReferenceField>

        <ChipField source="currency.name" />

        <BooleanColoredField source="deleted" />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};

export default PriceTypeList;
