import React from 'react';
import { TextInput, TextField, ReferenceField } from 'react-admin';
import { CustomList } from '../../../common/CustomList';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const orderTypesListFilter = [<TextInput source="q" alwaysOn label="Поиск" />];

export const OrderTypesList = props => {
  return (
    <CustomList filters={orderTypesListFilter} {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="code" />
        <ReferenceField reference="system/suppliers" source="supplier">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="system/price-types" source="priceType">
          <TextField source="name" />
        </ReferenceField>
        <BooleanColoredField source="deleted" />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};
