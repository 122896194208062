import {
  ArrayInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useRecordContext,
} from 'react-admin';
import FormToolbar from '../../../common/FormToolbar';
import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import { decodeHtmlFields } from '../../../../utils/transforms';

/*
export const transform = ({ contentArray, ...data }) => {
  return { ...data, content: JSON.stringify(contentArray) };
};
*/

export const transform = ({ content, ...data }) => {
  return { ...data, content: JSON.stringify([{content}]) };
};

const StaticPagesForm = props => {
  const record = useRecordContext();
  let modifiedRecord, contentArray;
  try {
    modifiedRecord = record ? decodeHtmlFields(['content'], record) : { content: '' };
    contentArray = modifiedRecord.content ? JSON.parse(modifiedRecord.content) : [{ content: '' }];
    modifiedRecord.content = Array.isArray(contentArray) && contentArray[0]?.content || ''
  } catch (e) {
    console.log(e);
  }

  return (
    <SimpleForm
      style={{ width: '100%' }}
      toolbar={<FormToolbar />}
      {...props}
      record={modifiedRecord}
    >
      <TextInput source="alias" />
      <TextInput source="title" fullWidth />
      <TextInput source="subtitle" fullWidth multiline />
      {/*
      <ArrayInput source="contentArray" defaultValue={contentArray}>
        <SimpleFormIterator>
          <RichTextInput
            toolbar={<RichTextInputToolbar size="medium" />}
            label="content"
            source="content"
            fullWidth
          />
        </SimpleFormIterator>
      </ArrayInput>
      */}
      <RichTextInput
        toolbar={<RichTextInputToolbar size="medium" />}
        label="content"
        source="content"
        fullWidth
      />
    </SimpleForm>
  );
};

export default StaticPagesForm;
