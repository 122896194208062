import UserIcon from '@mui/icons-material/People';

import PaymentsList from './PaymentsList';
import PaymentViewContainer from './PaymentViewContainer';

export default {
  list: PaymentsList,
  edit: PaymentViewContainer,
  icon: UserIcon,
};
