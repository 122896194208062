import {
  ArrayInput,
  BooleanInput,
  DateTimeInput,
  DeleteButton,
  FormDataConsumer,
  FormTab,
  NumberInput,
  ReferenceField,
  SaveButton,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  useEditController,
  useNotify,
} from 'react-admin';
import { useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
// import CategoryReferenceInput from './CategoryReferenceInput';
import CategoryReferenceInput from '../../home-page/featured-products-sections/CategoryReferenceInput';
import CategoryReferenceField from './CategoryReferenceField';
import { BackButton } from '../../../common/BackButton';
import { CLIENT_URL } from '../../../../consts';
import { SupplierPrices } from './components/SupplierPrices';
import AttributesOrder from '../../../common/AttributesOrder';
import { StockQuantities } from './components/StockQuantities';
import { AttributesList } from './components/AttributesList';
import { CustomEdit } from '../../../common/CustomEdit';
import { ResetButton } from '../../../common/ResetButton';
import { TransitStockQuantities } from './components/TransitStockQuantities';
import { DraggableImageList } from './DraggableImageList';
import { CustomImageInput } from '../../../inputs/CustomImageInput';

const useStyles = makeStyles({
  inlineBlock: {
    display: 'inline-flex',
    marginRight: '1rem',
  },
});

const RedirectToProduct = ({ record }) => {
  const link =
    record && record.catalogCategory && record.catalogCategory.seoKey
      ? `${CLIENT_URL}/catalog/${record.catalogCategory.seoKey.split('/')[0]}/product=${
          record.code
        }`
      : '';

  return (
    <Link target="_blank" rel="noopener noreferrer" href={link}>
      Просмотреть на сайте
    </Link>
  );
};

const FormToolbar = () => {
  const navigate = useNavigate();
  const notify = useNotify();
  const { isDirty, isSubmitting } = useFormState();
  const isDisabled = !isDirty || isSubmitting;
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <BackButton />
      {isDirty && <ResetButton />}
      <DeleteButton
        mutationMode="pessimistic"
        mutationOptions={{ onSuccess: () => navigate(-1) }}
      />
      <SaveButton
        label="actions.apply"
        type="button"
        mutationOptions={{ onSuccess: () => notify(`Продукт збережено.`, { type: 'success' }) }}
        variant="text"
        disabled={isDisabled}
      />
      <SaveButton
        label="ra.action.save"
        variant="text"
        type="button"
        mutationOptions={{ onSuccess: () => navigate(-1) }}
        disabled={isDisabled}
      />
    </Toolbar>
  );
};

const ProductEdit = (props) => {
  const classes = useStyles();
  const { record } = useEditController(props);
  const formVideos = record?.videos ? record.videos.map((url) => ({ url })) : [];

  async function onSave({ newImages, formVideos, ...restData }) {
    const formData = new FormData();
    if (newImages && newImages.length) {
      newImages.forEach((img) => {
        formData.append('files[]', img.rawFile);
      });
    }

    formData.append(
      'data',
      JSON.stringify({
        images: restData.images,
        id: restData.id,
        catalogCategory: restData.catalogCategory ? restData.catalogCategory.id : null,
        hidden: restData.hidden,
        showOnMainPage: restData.showOnMainPage,
        prices: restData.prices,
        stock: restData.stock,
        alias: restData.alias,
        videos: formVideos.map((video) => video.url),
        // ! change after attributes will be changed
        // ! also on create
        // attributes: newAttributes ? [...restData.attributes, ...newAttributes] : restData.attributes,
        attributes: restData.attributes,
      })
    );

    return formData;
  }

  return (
    <CustomEdit redirect="list" transform={onSave} {...props}>
      <TabbedForm toolbar={<FormToolbar />}>
        <FormTab label="resources.products.tabs.details">
          <TextInput source="name" style={{ width: 400 }} disabled />
          <TextInput source="fullName" style={{ width: 400 }} disabled />
          <TextInput source="alias" style={{ width: 400 }} />
          <TextInput source="code" disabled style={{ width: 400 }} />
          <TextInput source="catalogCategory1C" disabled style={{ width: 400 }} />
          <TextInput source="unit.name" disabled style={{ width: 400 }} />
          <ReferenceField reference={'system/product-types'} source={'productType'}>
            <TextField source={'name'} />
          </ReferenceField>
          <NumberInput source="inPackageCount" disabled style={{ width: 400 }} />
          <DateTimeInput source="syncDate" disabled style={{ width: 400 }} />
          <DateTimeInput source="createdAt" disabled style={{ width: 400 }} />
          <DateTimeInput source="updatedAt" disabled style={{ width: 400 }} />
          <RedirectToProduct record={record} />
        </FormTab>
        <FormTab label="resources.products.tabs.attributes" path="attributes">
          <AttributesList />
          {/* TODO feature for adding new attributes */}
          {/* <ArrayInput source="newAttributes">
            <SimpleFormIterator>
              <TextInput source="attribute" formClassName={classes.inlineBlock} />
              <TextInput source="value" formClassName={classes.inlineBlock} />
            </SimpleFormIterator>
          </ArrayInput> */}
          <AttributesOrder updateRoute={'products'} notIncludeAllData />
        </FormTab>
        <FormTab label="resources.products.tabs.settings" path="settings">
          <BooleanInput source="hidden" formClassName={classes.inlineBlock} />
          <BooleanInput source="showOnMainPage" formClassName={classes.inlineBlock} />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData && formData.productType ? (
                <CategoryReferenceInput
                  productType={formData.productType}
                  source="catalogCategory.id"
                  {...rest}
                />
              ) : (
                <span>
                  Катерія товару буде проставлена автоматично при переміщенні в категорію каталогу
                </span>
              )
            }
          </FormDataConsumer>
          <CategoryReferenceField
            source="catalogCategory1C"
            reference="catalog/categories-1c"
            label="Категория 1C"
          />
        </FormTab>
        <FormTab label="resources.products.tabs.images" path="images">
          <DraggableImageList />
          <CustomImageInput source="newImages" multiple={true} maxSize={204800} />
          <ArrayInput label="Лінки на відео" source="formVideos" defaultValue={formVideos}>
            <SimpleFormIterator>
              <TextInput source="url" label="Лінк" style={{ width: 450 }} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="resources.products.tabs.stocks-prices" path="stocks-prices">
          <SupplierPrices />
          <StockQuantities />
          <TransitStockQuantities />
        </FormTab>
      </TabbedForm>
    </CustomEdit>
  );
};

export default ProductEdit;
