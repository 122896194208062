import React from 'react';
import { API_SERVER } from '../../consts';
import { useRecordContext } from 'react-admin';

const ImagePreview = ({ imageStyles, urlSource, imageId, source }) => {
  const record = useRecordContext();
  const placeholderImage = '/flower-no-image.svg';
  const image = source ? record[source] : imageId || (record && record.image);
  return image ? (
    <div
      style={{
        backgroundImage: image
          ? `url(${API_SERVER}/${urlSource}/${image}.png)`
          : `url(${placeholderImage})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: 60,
        height: 60,
        ...imageStyles,
      }}
    />
  ) : null;
};

export default ImagePreview;
