import React from 'react';
import { Create, FormTab, TabbedForm, TextInput, SelectInput, BooleanInput } from 'react-admin';
import FormToolbar from '../../common/FormToolbar';
import { useStyles } from './styles';

const ClientCreate = props => {
  const classes = useStyles();

  return (
    <Create redirect="list" {...props}>
      <TabbedForm toolbar={<FormToolbar />}>
        <FormTab label="resources.users.tabs.identity">
          <TextInput source="username" autoFocus />
          <TextInput source="password" />
          <TextInput
            type="email"
            source="email"
            validation={{ email: true }}
            fullWidth={true}
            formClassName={classes.email}
          />
          <TextInput source="phone" />
          <TextInput source="profile.businessName" />
        </FormTab>
        <FormTab label="resources.users.tabs.system" path="system">
          <SelectInput
            source="status"
            choices={[
              { id: 'active', name: 'Active' },
              { id: 'disabled', name: 'Disabled' },
              { id: 'deleted', name: 'Deleted' },
            ]}
          />
          <SelectInput source="role" choices={[{ id: 'user', name: 'Client' }]} />
          <BooleanInput source='allowMultipleSessions' label='Дозволити авторизацію з кількох девайсів' />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default ClientCreate;
