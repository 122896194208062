import React from 'react';
import { SearchInput } from 'react-admin';
import { CustomList } from '../../../common/CustomList';
import { WebsiteDiscountTable } from './components/WebsiteDiscountTable';

const websiteDiscountsFilters = [<SearchInput source="q" alwaysOn />];

export const WebsiteDiscountList = props => (
  <CustomList filters={websiteDiscountsFilters} {...props}>
    <WebsiteDiscountTable />
  </CustomList>
);
