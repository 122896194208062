import HomeIcon from '@mui/icons-material/Home';
import ProductIcon from '@mui/icons-material/FolderSpecial';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import { SliderList } from './slider/SliderList';
import { SliderCreate } from './slider/SliderCreate';
import { SliderEdit } from './slider/SliderEdit';

import { OfferList } from './offer/OfferList';
import { OfferCreate } from './offer/OfferCreate';
import { OfferEdit } from './offer/OfferEdit';

import { FeaturedProductsSectionList } from './featured-products-sections/FeaturedProductsSectionList';
import { FeaturedProductsSectionCreate } from './featured-products-sections/FeaturedProductsSectionCreate';
import { FeaturedProductsSectionEdit } from './featured-products-sections/FeaturedProductsSectionEdit';

export const HomePageIcon = HomeIcon;

export const Slider = {
  icon: ViewCarouselIcon,
  list: SliderList,
  create: SliderCreate,
  edit: SliderEdit,
};

export const Offer = {
  icon: LocalOfferIcon,
  list: OfferList,
  create: OfferCreate,
  edit: OfferEdit,
};

export const FeaturedProductsSections = {
  icon: ProductIcon,
  list: FeaturedProductsSectionList,
  create: FeaturedProductsSectionCreate,
  edit: FeaturedProductsSectionEdit,
};
