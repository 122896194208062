import React from 'react';
import { TextField, NumberField, SearchInput } from 'react-admin';
import { NewTabLinkDataGrid } from '../../common/NewTabLinkDataGrid';
import { CustomList } from '../../common/CustomList';
import { BooleanColoredField } from '../../fields/BooleanColoredField';

const deliveryTimesFilter = [<SearchInput source="q" alwaysOn />];

export const DeliveryTimesList = props => (
  <CustomList filters={deliveryTimesFilter} {...props}>
    <NewTabLinkDataGrid rowClick="edit">
      <TextField source="name" />
      <TextField source="alias" />
      <TextField source="origin" />
      <TextField source="description" />
      <NumberField source="hoursFrom" />
      <NumberField source="hoursTo" />
      <BooleanColoredField source="active" />
    </NewTabLinkDataGrid>
  </CustomList>
);
