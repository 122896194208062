import React from 'react';
import { ReferenceField, TextField } from 'react-admin';
import { NewTabLinkDataGrid } from '../../../../common/NewTabLinkDataGrid';
import LocalDateField from '../../../../fields/LocalDateField';

export const TransitOffersTable = props => (
  <NewTabLinkDataGrid rowClick="show" {...props}>
    <TextField source="code" />
    <ReferenceField reference="system/price-types" source="priceType">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField reference="system/suppliers" source="supplier">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField reference="system/warehouses" source="warehouse">
      <TextField source="name" />
    </ReferenceField>
    <LocalDateField source="endDate" showTime />
  </NewTabLinkDataGrid>
);
