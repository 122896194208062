import React from 'react';
import { TextInput, BooleanInput, NumberInput, TextField, useRecordContext } from 'react-admin';
import { Card, CardContent, Grid, Box } from '@mui/material';
import { useStyles } from '../styles';
import FormToolbar from '../../../common/FormToolbar';
import { PageTitle } from '../../../common/PageTitle';

export const DeliveryTimeForm = formProps => {
  const classes = useStyles();
  const record = useRecordContext();

  return (
    <Box maxWidth="70em">
      <Card>
        <PageTitle record={record} titleText={`Временной промежуток ${record.name}`} />
        <CardContent className={classes.cardContent}>
          <Grid container spacing={5}>
            <Grid item sm={12} md={4}>
              <NumberInput label="Время от, час" source="hoursFrom" record={record} />
            </Grid>
            <Grid item sm={12} md={4}>
              <NumberInput label="Время до, час" source="hoursTo" record={record} />
            </Grid>
            <Grid item sm={12} md={4}>
              <TextField label="Источник" source="origin" record={record} />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item sm={12} md={8}>
              <TextInput label="Описание" source="description" record={record} fullWidth />
            </Grid>
            <Grid item sm={12} md={4}>
              <BooleanInput label="Активный" source="active" record={record} />
            </Grid>
          </Grid>
        </CardContent>
        <FormToolbar {...formProps} />
      </Card>
    </Box>
  );
};
