import { defaultTheme } from 'react-admin';
import { deepmerge } from '@mui/utils';

export const theme = deepmerge(defaultTheme, {
  components: {
    MuiFormControl: {
      defaultProps: { fullWidth: undefined },
      styleOverrides: { root: { minWidth: 265 } },
    },
    MuiTextField: {
      defaultProps: { fullWidth: undefined },
      styleOverrides: { root: { minWidth: 265 } },
    },
    MuiAutocomplete: {
      defaultProps: { fullWidth: undefined },
      styleOverrides: { root: { minWidth: 265 } },
    },
    RaSimpleFormIterator: {
      defaultProps: { fullWidth: undefined },
      styleOverrides: { root: { minWidth: 265 } },
    },
    RaTranslatableInputs: {
      defaultProps: { fullWidth: undefined },
      styleOverrides: { root: { minWidth: 265 } },
    },
  },
});
