import React from 'react';
import {
  TextInput,
  TextField,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  ReferenceField,
} from 'react-admin';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import LocalDateField from '../../../fields/LocalDateField';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const cashierFilter = [
  <TextInput source="q" alwaysOn label="Поиск" />,
  <ReferenceArrayInput reference="system/price-types" source="allowedPriceTypes">
    <SelectArrayInput style={{ width: 180 }} />
  </ReferenceArrayInput>,
  <ReferenceArrayInput reference="system/warehouses" source="allowedWarehouses">
    <SelectArrayInput style={{ width: 190 }} />
  </ReferenceArrayInput>,
];

const CashierList = props => {
  return (
    <CustomList filters={cashierFilter} {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="name" />
        <TextField source="login" />
        <TextField source="pinCode" />
        <BooleanColoredField source="isActive" />
        <ReferenceField reference="sales/business/cash-registers" source="cashRegister">
          <ChipField source="name" />
        </ReferenceField>
        <LocalDateField source="createdAt" showTime />
        <LocalDateField source="updatedAt" showTime />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};

export default CashierList;
