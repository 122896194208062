export function transformDeliveryTimes({ hoursFrom, hoursTo, ...rest }) {
  const from = hoursFrom < 10 ? `0${hoursFrom}` : hoursFrom;
  const to = hoursTo < 10 ? `0${hoursTo}` : hoursTo;
  return {
    ...rest,
    hoursFrom,
    hoursTo,
    name: `${from}:00 - ${to}:00`,
    alias: `${from}-00-${to}-00`,
    code: `${from}:00 - ${to}:00`
  }
}
