import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomEdit } from '../../../common/CustomEdit';
import { SearchPreferencesForm } from './components/SearchPreferencesForm';
import { transformPreferences } from './transformPreferences';

const SearchPreferencesTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? <span>{translate('resources.system/seo/search-preferences.title')}</span> : null;
};

export const SearchPreferencesEdit = props => (
  <CustomEdit
    title={<SearchPreferencesTitle />}
    component="div"
    transform={transformPreferences}
    redirect="list"
    {...props}
  >
    <Form>
      <SearchPreferencesForm />
    </Form>
  </CustomEdit>
);
