import { HttpError } from 'react-admin';
import { jwtDecode } from 'jwt-decode';
import { AUTH_URI, TOKEN_EXPIRE_TIME } from '../consts';

export const authProvider = {
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpires');
    return Promise.resolve();
  },
  checkAuth: () => {
    const nowTime = new Date().getTime();
    const hasToken = localStorage.getItem('token');
    if (hasToken !== null) {
      const tokenExpires = parseInt(localStorage.getItem('tokenExpires') || '', 10);
      if (tokenExpires > nowTime) {
        return Promise.resolve();
      } else {
        return Promise.reject({ redirectTo: '/login', logoutUser: true });
      }
    }
    return Promise.reject({ redirectTo: '/login', logoutUser: true });
  },
  login: ({ username, password }) => {
    const nowTime = new Date().getTime();
    const request = new Request(`${AUTH_URI}/login`, {
      method: 'POST',
      body: JSON.stringify({ authId: username, password, remember: true }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          return Promise.reject(
            new HttpError('Unauthorized', 401, {
              message: 'Invalid username or password',
            })
          );
        }
        return response.json();
      })
      .then(({ token }) => {
        const decodedToken = jwtDecode(token);
        const permissions = decodedToken?.permissions;
        localStorage.setItem('permissions', JSON.stringify(permissions));
        localStorage.setItem('token', token);
        localStorage.setItem('tokenExpires', `${nowTime + TOKEN_EXPIRE_TIME}`);
      });
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    try {
      const permissions = localStorage.getItem('permissions') || '';
      const permissionsJson = JSON.parse(permissions);
      return Promise.resolve(permissionsJson);
    } catch {
      return Promise.reject();
    }
  },
};

export default authProvider;
