import React from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  extendedField: {
    minWidth: 350,
  },
});

const getOptionText = record => {
  if (!record) return;

  const { parent, name } = record;
  return `${parent ? parent.name + ' - ' : ''}${name}`;
};

const CategoryReferenceInput = props => {
  const classes = useStyles();

  return (
    <ReferenceInput
      reference="catalog/productType/all"
      sortBy="catalogCategory.name"
      link={false}
      {...props}
      className={`${props.className} ${classes.extendedField}`}
    >
      <AutocompleteInput optionText={getOptionText} />
    </ReferenceInput>
  );
};

CategoryReferenceInput.defaultProps = {
  source: 'catalogCategory',
  reference: 'catalog/productType/all',
  label: 'Категория',
};

export default CategoryReferenceInput;
