import React from 'react';
import { TextInput, SimpleForm, BooleanInput } from 'react-admin';
import FormToolbar from '../../../common/FormToolbar';

const CashRegisterForm = props => {
  return (
    <SimpleForm style={{ width: '100%' }} {...props} toolbar={<FormToolbar />}>
      <TextInput source="code" required={true} />
      <TextInput source="name" required={true} />
      <BooleanInput source="isActive" />
      <TextInput source="licenseKey" required={true} />
    </SimpleForm>
  );
};

export default CashRegisterForm;
