import React from 'react';
import { Card as MuiCard, CardContent } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { FilterList, FilterListItem, FilterLiveSearch, useGetList } from 'react-admin';

export const LocationsListAside = () => {
  const { data: locationsData } = useGetList('sales/shipping/locations', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'name', order: 'ASC' },
    filter: { statesOnly: 1 },
  });

  const { data: methodsData } = useGetList('sales/shipping/methods', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'name', order: 'ASC' },
    filter: {},
  });

  return (
    <MuiCard sx={{ order: -2, width: '16em', marginRight: '1em' }}>
      <CardContent>
        <FilterLiveSearch />
        <FilterList
          label="resources.sales/shipping/locations.fields.deliveryType"
          icon={<LocalShippingIcon />}
        >
          {methodsData?.map(({ id, name }) => (
            <FilterListItem label={name} key={id} value={{ deliveryType: id }} />
          )) || ''}
        </FilterList>
        <FilterList
          label="resources.sales/shipping/locations.fields.state"
          icon={<LocationOnIcon />}
        >
          {locationsData?.map(({ id, state }) => (
            <FilterListItem label={state} key={id} value={{ state }} />
          )) || ''}
        </FilterList>
      </CardContent>
    </MuiCard>
  );
};
