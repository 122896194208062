import React from 'react';
import {
  TextInput,
  TextField,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  ReferenceField,
} from 'react-admin';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import LocalDateField from '../../../fields/LocalDateField';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const supplierFilter = [
  <TextInput source="q" alwaysOn label="Поиск" />,
  <ReferenceArrayInput reference="system/price-types" source="allowedPriceTypes">
    <SelectArrayInput style={{ width: 180 }} />
  </ReferenceArrayInput>,
  <ReferenceArrayInput reference="system/warehouses" source="allowedWarehouses">
    <SelectArrayInput style={{ width: 190 }} />
  </ReferenceArrayInput>,
];

const SupplierList = props => {
  return (
    <CustomList filters={supplierFilter} {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="code" />
        <TextField source="displayName" />
        <TextField source="name" />
        <TextField source="region" />
        <BooleanColoredField source="isDefault" />

        <ReferenceArrayField reference="system/price-types" source="allowedPriceTypes">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField reference="system/price-types" source="defaultPriceType">
          <ChipField source="name" />
        </ReferenceField>
        <ReferenceArrayField reference="system/warehouses" source="allowedWarehouses">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <LocalDateField source="createdAt" showTime />
        <LocalDateField source="updatedAt" showTime />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};

export default SupplierList;
