import { SearchKeywordsList } from './SearchKeywordsList';
import { SearchKeywordsCreate } from './SearchKeywordsCreate';
import { SearchKeywordsEdit } from './SearchKeywordsEdit';
import SearchIcon from '@mui/icons-material/Search';

export const icon = SearchIcon;

export default {
  list: SearchKeywordsList,
  create: SearchKeywordsCreate,
  edit: SearchKeywordsEdit,
  icon: SearchIcon,
};
