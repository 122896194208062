import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles({
  tableContainer: {
    maxWidth: 700,
    marginBottom: 30,
  },
});

export const SupplierPrices = () => {
  const record = useRecordContext();
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      <Typography variant="h6">{translate('resources.catalog/products.prices.title')}</Typography>
      <TableContainer classes={{ root: classes.tableContainer }} component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{translate('resources.catalog/products.prices.supplier')}</TableCell>
              <TableCell>{translate('resources.catalog/products.prices.priceType')}</TableCell>
              <TableCell>{translate('resources.catalog/products.prices.price')}</TableCell>
              <TableCell>{translate('resources.catalog/products.prices.updatedAt')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.prices &&
              record.prices.map((item, idx) => (
                <TableRow key={`price-item-${idx}`}>
                  <TableCell>{item.supplier || ''}</TableCell>
                  <TableCell>{(item.priceType && item.priceType.name) || ''}</TableCell>
                  <TableCell>
                    {(item.price && item.price[0]) || ''}
                    &nbsp;
                    {(item.priceType && item.priceType.currency && item.priceType.currency.name) ||
                      ''}
                  </TableCell>
                  <TableCell>
                    {item.updatedAt ? new Date(item.updatedAt).toLocaleString('uk-UA') : ''}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
