import React from 'react';
import {
  TextInput, SimpleForm, ReferenceInput, AutocompleteInput, required,
} from 'react-admin';
import FormToolbar from '../../common/FormToolbar';

export const transform = data => ({ ...data, systemOutlet: true })

const OutletSystemForm = (props) => {
  return (
    <SimpleForm style={{ width: "100%" }} toolbar={<FormToolbar/>} {...props}>
      <TextInput source="code"/>
      <TextInput source="name"/>
      <TextInput source="region"/>
      <TextInput source="city"/>
      <TextInput source="street"/>
      <TextInput source="building"/>
      <TextInput source="latitude"/>
      <TextInput source="longitude"/>
      <ReferenceInput reference={"sales/shipping/locations"} source={"location"} perPage={999}>
        <AutocompleteInput source={"name"} />
      </ReferenceInput>
      <ReferenceInput reference={"system/suppliers"} source={"supplier"}>
        <AutocompleteInput source={"name"} validate={required()} />
      </ReferenceInput>
    </SimpleForm>
  );
};

export default OutletSystemForm;