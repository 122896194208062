import get from 'lodash/get';
import Link from '@mui/material/Link';
import { useRecordContext } from 'react-admin';

function onClick(e) {
  e.stopPropagation();
}

export const RedirectField = ({ linkUrl, linkIdSource, source, fieldText, ...rest }) => {
  const record = useRecordContext();
  const text = fieldText || get(record, source) || '';
  const linkId = get(record, linkIdSource);
  return (
    <Link href={`/#/${linkUrl}/${linkId}`} onClick={onClick} {...rest}>
      {text}
    </Link>
  );
};
