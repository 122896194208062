import React from 'react';
import { SearchInput } from 'react-admin';
import { CustomList } from '../../../common/CustomList';
import { TransitOffersTable } from './components/TransitOffersTable';

const transitFilters = [<SearchInput source="q" alwaysOn />];

export const TransitOffersList = props => (
  <CustomList filters={transitFilters} {...props}>
    <TransitOffersTable />
  </CustomList>
);
