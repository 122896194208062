import WarehouseList from './WarehouseList';
import WarehouseCreate from './WarehouseCreate';
import WarehouseEdit from './WarehouseEdit';
import Icon from '@mui/icons-material/House';

export default {
  list: WarehouseList,
  create: WarehouseCreate,
  edit: WarehouseEdit,
  icon: Icon,
};
