import React from 'react';
import { Create } from 'react-admin';

export const CustomCreate = props => {
  return (
    <Create mutationMode="optimistic" {...props}>
      {props.children}
    </Create>
  );
};
