import { useRecordContext } from 'react-admin';
import Typography from '@mui/material/Typography';

const DeliveryTimeField = () => {
  const record = useRecordContext();
  if (!record) return null;

  const timeName = record.selfPickUpTime?.name || record.deliveryTime?.name || '-';

  return (
    <Typography variant="body2" component="span">
      {timeName}
    </Typography>
  );
};

export default DeliveryTimeField;
