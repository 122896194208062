import React from 'react';
import { Form } from 'react-admin';
import { TransitOfferForm } from './components/TransitOfferForm';
import { CustomEdit } from '../../../common/CustomEdit';

export const TransitOfferView = ({ classes, ...props }) => (
  <CustomEdit {...props} component="div" hasShow={false}>
    <Form>
      <TransitOfferForm />
    </Form>
  </CustomEdit>
);
