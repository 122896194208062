function imageTransformer(rawData) {
  return new Promise(function (resolve) {
    const reader = new FileReader();

    reader.onloadend = function () {
      resolve(reader.result);
    };

    reader.readAsBinaryString(rawData.rawFile);
  });
}

export async function transformImage(imageData) {
  let image;
  if (imageData && typeof imageData !== 'string') {
    image = await imageTransformer(imageData);
  }

  return image;
}

export async function transformImages(rawImages) {
  let images;
  if (rawImages && Array.isArray(rawImages) && rawImages.length) {
    await Promise.all(rawImages.map(img => imageTransformer(img)))
      .then(imgs => images = imgs);
  }

  return images;
}

export function getFileType(fileData) {
  if (!fileData || typeof fileData === 'string') {
    return '';
  }

  try{
    return `.${fileData.mimetype.split('/')[1]}`
  } catch (e) {

  }


  let fileType = '';
  const fileName = fileData.fileName || fileData;
  if (fileName.search(/\.(jpg|jpeg|png)$/) === -1) {
    fileType = fileData.mimetype ? fileData.mimetype.replace('image/', '.') : '';
  }
  return fileType;
}
