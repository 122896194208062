import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import MTextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  TextField,
  Loading,
  useTranslate,
  useGetOne,
  useUpdate,
  useList,
  useGetList,
  ListContextProvider,
  DatagridConfigurable,
} from 'react-admin';
import { BackButton } from '../../../common/BackButton';
import ThumbnailField from '../products/ThumbnailField';
import { RedirectField } from '../../../fields/RedirectField';
import LocalDateField from '../../../fields/LocalDateField';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';
import { useParams } from 'react-router-dom';

const styles = () => ({
  wrapper: {
    display: 'flex',
  },
  buttonWrapper: {
    marginTop: 100,
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    width: '45%',
  },
  backBtn: {
    width: 100,
  },
  table: {
    width: 'auto',
  },
});

const CategoryProducts = ({ classes }) => {
  const translate = useTranslate();
  const [filterAvailable, setFilterAvailable] = useState('');
  const [filterCategory, setFilterCategory] = useState('');

  const [selectedCategoryProductsIds, setSelectedCategoryProductsIds] = useState([]);
  const [selectedAvailableProductsIds, setSelectedAvailableProductsIds] = useState([]);

  const [update, { isLoading: updateLoading }] = useUpdate();
  const params = useParams();
  const { id: categoryId, productType } = params || {};

  const { data, isPending } = useGetOne('catalog/categories', { id: categoryId });

  const {
    data: categoryProducts,
    isPending: categoryPending,
    refetch: refetchCategory,
  } = useGetList('catalog/products', {
    pagination: { page: 1, perPage: 10000 },
    filter: { catalogCategory: categoryId, q: filterCategory },
  });
  const categoryContext = useList({ data: categoryProducts, isPending: categoryPending });

  const {
    data: availableProducts,
    isPending: productsPending,
    refetch: refetchProducts,
  } = useGetList('catalog/products', {
    pagination: { page: 1, perPage: 10000 },
    filter: { withoutCategory: true, q: filterAvailable },
  });
  const productsContext = useList({ data: availableProducts, isPending: productsPending });

  const resetPageState = () => {
    refetchCategory();
    refetchProducts();
  };

  const updateCategoryProducts = (payload) => {
    update(
      `catalog/category/${productType}/products`,
      { id: categoryId, data: { products: payload } },
      {
        onSuccess: (data) => {
          setSelectedCategoryProductsIds([]);
          setSelectedAvailableProductsIds([]);
          resetPageState();
        },
        onFailure: (error) => console.log(error),
      }
    );
  };

  const removeFromCategory = () => {
    const categoryProductsIds = categoryProducts.map(({ id }) => id);
    const payload = categoryProductsIds.filter((id) => !selectedCategoryProductsIds.includes(id));
    updateCategoryProducts(payload);
  };

  const moveToCategory = () => {
    const categoryProductsIds = categoryProducts.map(({ id }) => id);
    const payload = [...categoryProductsIds, ...selectedAvailableProductsIds];
    updateCategoryProducts(payload);
  };

  const toggleCategorySelected = (recordId) => {
    if (selectedCategoryProductsIds.includes(recordId)) {
      setSelectedCategoryProductsIds((ids) => ids.filter((id) => id !== recordId));
    } else {
      setSelectedCategoryProductsIds((prods) => [...prods, recordId]);
    }
  };

  const toggleAvailableSelected = (recordId) => {
    if (selectedAvailableProductsIds.includes(recordId)) {
      setSelectedAvailableProductsIds((ids) => ids.filter((id) => id !== recordId));
    } else {
      setSelectedAvailableProductsIds((prods) => [...prods, recordId]);
    }
  };

  const isLoading = isPending || productsPending || categoryPending;

  if (isLoading || updateLoading) return <Loading />;
  // if (error) return <p>ERROR</p>;
  if (!data) return <p>Категорію не знайдено</p>;

  return (
    <>
      <BackButton sx={{ width: 100 }} />
      <div className={classes.titleWrapper}>
        <Typography variant="h5" className={classes.title} gutterBottom>
          {translate('resources.categories.productsInCategory')} {data?.name || ''}
        </Typography>
        <Typography variant="h5" className={classes.title} gutterBottom>
          {translate('resources.categories.availableProducts')}
        </Typography>
      </div>
      <Grid
        container
        justify={'space-between'}
        spacing={6}
        style={{ marginLeft: 0, width: '100%' }}
      >
        <Grid item xs style={{ paddingLeft: 0, paddingRight: 40 }}>
          <MTextField
            fullWidth
            onChange={(e) => setFilterCategory(e.currentTarget.value.toLowerCase())}
            value={filterCategory}
            label="Пошук по категорії"
            style={{ maxWidth: 400 }}
          />
        </Grid>
        <Grid item xs style={{ paddingLeft: 0 }}>
          <MTextField
            fullWidth
            onChange={(e) => setFilterAvailable(e.currentTarget.value.toLowerCase())}
            value={filterAvailable}
            label="Пошук по товарам"
            style={{ maxWidth: 400 }}
          />
        </Grid>
      </Grid>
      <div className={classes.wrapper}>
        <ListContextProvider value={categoryContext}>
          <DatagridConfigurable
            resource={'catalog/products'}
            data={categoryProducts}
            onToggleItem={toggleCategorySelected}
            selectedIds={selectedCategoryProductsIds}
            onSelect={setSelectedCategoryProductsIds}
            className={classes.table}
          >
            <RedirectField source="code" linkIdSource="id" linkUrl="catalog/products" label="Код" />
            <TextField source="name" label="Назва" />
            <ThumbnailField label="Фото" />
            <BooleanColoredField source="hidden" label="Прихований" />
            <BooleanColoredField source="showOnMainPage" label="Для головної" />
            <TextField source="fullName" label="Повне ім'я" />
            <TextField source="alias" label="Псевдонім" />
            <LocalDateField source="createdAt" label="Дата створення" showTime />
            <LocalDateField source="updatedAt" label="Дата редагування" showTime />
          </DatagridConfigurable>
        </ListContextProvider>

        <div className={classes.buttonWrapper}>
          <IconButton onClick={moveToCategory}>
            <ArrowBackIcon />
          </IconButton>
          <IconButton onClick={removeFromCategory}>
            <ArrowForwardIcon />
          </IconButton>
        </div>

        <ListContextProvider value={productsContext}>
          <DatagridConfigurable
            resource={'catalog/products'}
            data={availableProducts}
            onToggleItem={toggleAvailableSelected}
            selectedIds={selectedAvailableProductsIds}
            onSelect={setSelectedAvailableProductsIds}
            className={classes.table}
          >
            <RedirectField source="code" linkIdSource="id" linkUrl="catalog/products" label="Код" />
            <TextField source="name" label="Назва" />
            <ThumbnailField label="Фото" />
            <BooleanColoredField source="hidden" label="Прихований" />
            <BooleanColoredField source="showOnMainPage" label="Для головної" />
            <TextField source="fullName" label="Повне ім'я" />
            <TextField source="alias" label="Псевдонім" />
            <LocalDateField source="createdAt" label="Дата створення" showTime />
            <LocalDateField source="updatedAt" label="Дата редагування" showTime />
          </DatagridConfigurable>
        </ListContextProvider>
      </div>
    </>
  );
};

export default withStyles(styles)(CategoryProducts);
