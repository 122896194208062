import React, { useEffect, useState } from "react";
import {
  DatagridConfigurable,
  ListContextProvider,
  SearchInput,
  SelectArrayInput,
  TextField,
  useDataProvider,
  useListContext,
} from "react-admin";
import LocalDateField from "../../fields/LocalDateField";
import { CustomList } from "../../common/CustomList";
import { RedirectField } from "../../fields/RedirectField";

const filterStyles = {
  warehouseSelect: {
    width: 500,
  },
};

const CustomView = () => {
  const listContext = useListContext();
  const { data } = listContext;
  if (!data) {
    return null;
  }

  const ids = data.map(({ id }) => id);

  return (
    <ListContextProvider value={{ ...listContext, selectedIds: ids }}>
      <DatagridConfigurable bulkActionButtons={false}>
        <TextField source="warehouse.name" />
        <TextField source="warehouse.code" />
        <RedirectField
          source="product.name"
          linkIdSource="product.id"
          linkUrl="catalog/products"
        />
        <TextField source="product.code" label="Код продукту" />
        <TextField source="qty" label="Кількість" />
        <RedirectField
          source="client.name"
          linkIdSource="client.id"
          linkUrl="clients"
        />
        <TextField source="client.code" label="Код клієнта" />
        <LocalDateField source="createdAt" showTime />
      </DatagridConfigurable>
    </ListContextProvider>
  );
};

export const ActiveCartsList = (props) => {
  const dataProvider = useDataProvider();
  const [warehouseChoices, setWarehouseChoices] = useState([]);

  const activeCartsFilter = [
    <SearchInput source="q" alwaysOn name="search" />,
    <SelectArrayInput
      label="Склад"
      sx={filterStyles.warehouseSelect}
      source="warehouses"
      choices={warehouseChoices}
      optionValue="code"
      optionText="parsedName"
      alwaysOn
      name="warehouses"
    />,
  ];

  useEffect(() => {
    dataProvider
      .getList("system/warehouses", {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setWarehouseChoices(
          data && Array.isArray(data)
            ? data.map((i) => ({ ...i, parsedName: `${i.name} - ${i.code}` }))
            : []
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <CustomList {...props} filters={activeCartsFilter} perPage={999}>
      <CustomView />
    </CustomList>
  );
};
