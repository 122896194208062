import React from 'react';
import { TextInput, SimpleForm, ArrayInput, SimpleFormIterator, required } from 'react-admin';
import FormToolbar from '../../../../common/FormToolbar';

export const SearchKeywordsForm = props => {
  return (
    <SimpleForm style={{ width: '100%' }} {...props} toolbar={<FormToolbar />}>
      <TextInput source="keyword" validate={[required()]} />
      <ArrayInput source="replacement" validate={[required()]}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};
