import React from 'react';
import { AutocompleteArrayInput } from 'react-admin';
import { useWatch } from 'react-hook-form';

export const UserIPsWhitelist = (props) => {
  const allowedIPs = useWatch({ name: 'allowedIPs' });

  const [currentIp, setCurrentIp] = React.useState('');

  React.useEffect(() => {
    fetch('https://www.geoplugin.net/json.gp')
      .then(async (data) => {
        try {
          data = await data.json();
          /*
          {
            "geoplugin_request":"62.80.164.39",
            "geoplugin_status":200,
            "geoplugin_delay":"2ms",
            "geoplugin_credit":"Some of the returned data includes GeoLite2 data created by MaxMind, available from <a href='https:\/\/www.maxmind.com'>https:\/\/www.maxmind.com<\/a>.",
            "geoplugin_city":"Kyiv",
            "geoplugin_region":"Kyiv City",
            "geoplugin_regionCode":"30",
            "geoplugin_regionName":"Kyiv City",
            "geoplugin_areaCode":"",
            "geoplugin_dmaCode":"",
            "geoplugin_countryCode":"UA",
            "geoplugin_countryName":"Ukraine",
            "geoplugin_inEU":0,
            "geoplugin_euVATrate":false,
            "geoplugin_continentCode":"EU",
            "geoplugin_continentName":"Europe",
            "geoplugin_latitude":"50.458",
            "geoplugin_longitude":"30.5303",
            "geoplugin_locationAccuracyRadius":"10",
            "geoplugin_timezone":"Europe\/Kyiv",
            "geoplugin_currencyCode":"UAH",
            "geoplugin_currencySymbol":"₴",
            "geoplugin_currencySymbol_UTF8":"₴",
            "geoplugin_currencyConverter":41.0899
          }
          */
          setCurrentIp(data?.geoplugin_request || '');
        } catch (e) {}
      })
      .catch(() => {
        return; // temporary disable
        // if ('1' !== localStorage.getItem('disableAdBlockerRequest')) {
        //   if (
        //     window.confirm(
        //       'Неможливо визначити ІР адресу, вимкніть блокувальник реклами на сайті та перезавантажте сторінку.'
        //     )
        //   ) {
        //     localStorage.setItem('disableAdBlockerRequest', '1');
        //   }
        // }
      });
  }, []);

  const IPs = (allowedIPs || []).map((ip) => ({ name: ip, id: ip }));

  return (
    <AutocompleteArrayInput
      onCreate={() => {
        let newValue = prompt('Enter IP', IPs.includes(currentIp) ? '' : currentIp);
        if (newValue) {
          newValue = newValue.trim().toLowerCase();
          const newOption = { id: newValue, name: newValue };
          IPs.push(newOption);
          return newOption;
        } else {
          return { id: '', name: '' };
        }
      }}
      source="allowedIPs"
      choices={IPs}
      label={props.label}
    />
  );
};
