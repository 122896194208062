import {
  TextField,
  SearchInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import React from 'react';
import { filterToQuery } from '../home-page/offer/OfferCreate';
import { NewTabLinkDataGrid } from '../../common/NewTabLinkDataGrid';
import LocalDateField from '../../fields/LocalDateField';
import { CustomList } from '../../common/CustomList';

const locationsFilter = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    label="Клиент"
    reference="clients"
    source={'client'}
    filterToQuery={filterToQuery}
  >
    <AutocompleteInput optionText="username" />
  </ReferenceInput>,
];

export const OutletsSystemList = props => (
  <CustomList filters={locationsFilter} {...props}>
    <NewTabLinkDataGrid rowClick="edit">
      <TextField source="code" />
      <TextField source="name" />
      <TextField source="region" />
      <TextField source="city" />
      <TextField source="street" />
      <TextField source="building" />
      <TextField source="latitude" />
      <TextField source="longitude" />
      <ReferenceField reference="sales/shipping/locations" source="location">
        <TextField source="name" />
      </ReferenceField>
      <LocalDateField source="createdAt" showTime />
      <LocalDateField source="updatedAt" showTime />
    </NewTabLinkDataGrid>
  </CustomList>
);

export default OutletsSystemList;
