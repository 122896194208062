import ukrainianMessages from 'ra-language-ukrainian';

const updatedDefaults = {
  ...ukrainianMessages,
  ra: {
    ...ukrainianMessages.ra,
    navigation: {
      ...ukrainianMessages.ra.navigation,
      skip_nav: 'Пропустити навігацію',
    },
    message: {
      ...ukrainianMessages.ra.message,
      clear_array_input: 'Очистити',
    },
    action: {
      ...ukrainianMessages.ra.action,
      unselect: 'Зняти виділення',
      apply: 'Застосувати',
      save: 'Зберегти',
      select_columns: 'Налаштування',
      create: 'Створити',
      export: 'Експорт',
    },
    code: 'Код',
  },
  actions: {
    apply: 'Застосувати',
  },
};

const defaultFields = {
  code: 'Код',
  name: "Ім'я",
  alias: 'Аліас',
  deleted: 'Видалено',
  firstLevel: '1 рівень',
  updatedAt: 'Оновлено',
  createdAt: 'Створено',
  isDefault: 'За замовченням',
  balanceAdjustment: 'Початковий баланс',
  displayName: "Відображуване ім'я",
  allowedPriceTypes: 'Дозволені типи ціни',
  defaultPriceType: 'Тип ціни за замовченням',
  allowedWarehouses: 'Дозволені склади',
  priceTypes: 'Типи ціни',
  sortOrder: 'Порядок при сортуванні',
};

const salesOrdersFields = {
  code: 'Код замовлення',
  orderId: '№ замовлення',
  orderType: 'Тип замовлення',
  outlet: 'Адреса',
  docType: 'Тип',
  deliveryMethod: 'Метод доставки',
  deliveryDate: 'Дата доставки',
  orderStatus: 'Статус',
  totalQty: 'Кількість',
  totalSum: 'Сума',
  totalDiscount: 'Знижка',
  createdAt: 'Створено',
  updatedAt: 'Оновлено',
  notes: 'Коментар',
  isSynced: 'Синхронізовано',
  address: 'Адреса доставки',
  orderDetails: {
    warehouse: {
      displayName: "Ім'я складу",
    },
  },
  warehouse: {
    name: 'Склад',
    displayName: "Ім'я складу",
    code: 'Код складу',
  },
  client: {
    businessName: "Бізнес ім'я клієнта",
    phone: 'Номер телефону клієнта',
  },
  packageReturn: 'З поверненням тари',
  deliveryTime: {
    name: 'Час доставки',
  },
  selfPickUpTime: {
    name: 'Точний час доставки',
  },
};

const salesPaymentsFields = {
  alias: 'Аліас',
  name: "Ім'я",
  docType: 'Тип',
  description: 'Опис',
  balance_1c: '1С Баланс',
  amount: 'Сума',
  code: 'Код',
  status: 'Статус',
  client: 'Клієнт',
  syncAttempts: 'Спроб синхронізації',
  type: 'Тип',
  deleted: 'Видалено',
  isSynced: 'Синхронізовано',
  payment: 'Платіж',
  currencyCode: 'Код валюти',
  'currency.name': 'Валюта',
  'client.customerId': 'ID клієнта',
  'client.code': 'Код клієнта',
  'client.businessName': "Бізнес ім'я клієнта",
  createdAt: 'Створено',
  updatedAt: 'Оновлено',
  region: 'Регіон',
};

export default {
  ...updatedDefaults,
  pos: {
    search: 'Поиск',
    configuration: 'Настройки',
    language: 'Язык',

    theme: {
      name: 'Тема',
      light: 'Светлая',
      dark: 'Темная',
    },
    dashboard: {
      welcome: {
        title: 'Добро пожаловать',
      },
    },
    menu: {
      catalog: 'Каталог',
      system: 'Система',
      users: 'Пользователи',
      homePage: 'Главная страница',
      sales: 'Финансы',
      logistic: 'Логистика',
      settings: 'Настройки',
      configs: 'Конфиги',
      cronJobs: 'Крон',
      errorLog: 'Лог ошибок',
      log1c: 'Лог 1С',
      orderTypes: 'Типы ордеров',
      searchQueries: 'Лог запросов',
      searchKeywords: 'Поисковые ключи',
      search: 'Поиск',
      searchPreferences: 'Настройки',
      integrations: 'Интеграции',
      priceTypes: 'Типы цен',
      productTypes: 'Типи продуктів',
      priceGroups: 'Группы цен',
      suppliers: 'Поставщики',
      warehouses: 'Склады',
      attributes: 'Аттрибуты',
      loyaltyProgram: 'Программа лояльности',
      offers: 'Пропозиції',
      seoSettings: 'SEO настройки',
      cms: 'CMS',
      staticPage: 'Статичні сторінки',
    },
    tree: {
      collapse: 'Свернуть',
      expand: 'Раскрыть',
      collapseAll: 'Свернуть все',
      expandAll: 'Раскрыть все',
    },
  },
  resources: {
    commonFields: {
      ...defaultFields,
    },
    users: {
      name: 'Пользователь |||| Пользователи',
      fields: {
        username: 'Логин',
        email: 'e-mail',
        phone: 'Телефон',
        role: 'Роль',
        status: 'Статус',
        supplier: 'Постоянный поставщик',
        registrationStatus: 'Статус регистрации',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
        commands: 'Заказы',
        last_seen_gte: 'Последний визит',
        featureFlags: 'Включить расширения',
        profile: {
          code: 'Код',
          firstName: 'Имя',
          lastName: 'Фамилия',
          businessName: 'Бизнес Имя',
          balance: 'Баланс',
          credit: 'Кредит',
          region: 'Регион',
          tmpCredit: 'Временный кредит',
          tmpCreditExpireOn: 'Дата окончания временного кредита',
          priceType: {
            name: 'Группа цен',
          },
          priceTypeGroup: {
            name: 'Группа цен',
          },
          warehouse: 'Имя склада',
          registrationStatus: 'Статус регистрации',
          declineReason: 'Причина отказа',
        },
      },
      tabs: {
        identity: 'Личность',
        system: 'Система',
        documents: 'Документы',
        orders: 'Заказы',
        ordersRefund: 'Повернення товарів',
        reviews: 'Отзывы',
        stats: 'Статистика',
        uploadDocuments: 'Загрузить документы',
        balance: 'Баланс',
        transactions: 'Транзакции',
        payments: 'Платежі',
        paymentsRefund: 'Повернення коштів',
        region: 'Регион',
        outlets: 'Адреса',
      },
      page: {
        delete: 'Удалить пользователя',
      },
    },
    clients: {
      name: 'Клиент |||| Клиенты',
      fields: {
        username: 'Логин',
        email: 'e-mail',
        phone: 'Телефон',
        role: 'Роль',
        status: 'Статус',
        registrationStatus: 'Статус регистрации',
        region: 'Регион',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
        commands: 'Заказы',
        last_seen_gte: 'Последний визит',
        featureFlags: 'Включить расширения',
        profile: {
          customerId: 'ID клиента',
          code: 'Код',
          firstName: 'Имя',
          lastName: 'Фамилия',
          region: 'Регион',
          businessName: 'Бизнес Имя',
          balance: 'Баланс',
          credit: 'Кредит',
          tmpCredit: 'Временный кредит',
          tmpCreditExpireOn: 'Дата окончания временного кредита ',
          priceTypeGroup: 'Группа цен',
        },
      },
      tabs: {
        identity: 'Личность',
        system: 'Система',
        documents: 'Документы',
        orders: 'Заказы',
        reviews: 'Отзывы',
        stats: 'Статистика',
        balance: 'Баланс',
        transactions: 'Транзакции',
        region: 'Регион',
        outlets: 'Адреса',
      },
      page: {
        delete: 'Удалить пользователя',
      },
    },
    featureFlags: {
      name: 'Расширение |||| Расширения',
    },
    transactions: {
      name: 'Транзакция |||| Транзакции',
    },
    'system/attributes': {
      fields: defaultFields,
      name: 'Аттрибуты',
      origin: 'Принадлежность',
      measurement: 'Ед. измерения',
    },
    reclamations: {
      name: 'Рекламация |||| Рекламации',
      fields: {
        order: 'Заказ',
        reclamation: 'Рекламация',
        comment: 'Комментари',
        ratingQuality: 'Оценка качества',
        ratingManager: 'Оценка менеджера',
        ratingDelivery: 'Оценка доставки',
        createdAt: 'Создан',
        updatedAt: 'Обновлён',
      },
    },
    forms: {
      fields: {
        name: 'Алиас',
      },
    },
    'sales/transactions': {
      fields: {
        operationType: 'Операция',
        details: 'Детали',
        operation: {
          docType: 'Тип',
          date: 'Операция создана',
        },
        amountBase: 'Базовая сумма',
        amount: 'Сумма',
        balance: 'Балланс',
        currencyRate: 'Валютный рейт',
        'currencyBase.name': 'Базовая валюта',
        'currency.name': 'Валюта',
        'client.customerId': 'ID клиента',
        'client.businessName': 'Бизнес имя клиента',
        operationData: {
          createdAt: 'Операция создана',
        },
        createdAt: 'Создан',
        updatedAt: 'Обновлён',
        region: 'Регион',
      },
    },
    'system/warehouses': {
      title: 'Склад',
      fields: {
        alias: 'Алиас',
        code: 'Код',
        deleted: 'Удалён',
        displayName: 'Отображаемое имя',
        isDefault: 'За замовченням',
        selfPickUp: 'Є самовивіз',
        selfPickUpTimeSlots: 'Часи самовивозу',
        maxClientsOnTimeSlot: 'Клієнтів одночасно',
        name: 'Имя',
        supplier: 'Поставщик',
        redirect: 'Редирект',
        outlets: 'Адреса',
        createdAt: 'Создан',
        updatedAt: 'Обновлён',
      },
    },
    'system/suppliers': {
      fields: defaultFields,
    },
    'system/product-types': {
      fields: {
        ...defaultFields,
      },
    },
    'system/price-type-groups': {
      fields: defaultFields,
    },
    'system/seo/settings': {
      title: 'SEO настройки',
      fields: {
        ...defaultFields,
        url: 'Url',
        metaTitle: 'Заголовок',
        metaDesc: 'Описание',
        metaTagsH1: 'Теги h1',
        active: 'Активный',
      },
    },
    'system/order-types': {
      title: 'Типы ордеров',
      fields: {
        code: 'Код',
        priceType: 'Тип цены',
      },
    },
    'system/cron-jobs': {
      title: 'Крон',
      fields: {
        ...defaultFields,
        timeout: 'Через, сек',
        isSystem: 'Системний',
        status: 'Статус',
        disabled: 'Активний',
        lastError: 'Помилка',
        executionProgress: 'Прогрес',
        description: 'Опис',
        tickRate: 'Інтервал',
        options: {
          ignoreMaintenance: 'Ігнор режим налаш',
          silent: 'У фоні',
          startNow: 'Запустити зараз',
          runOnInit: 'Запустити після старту',
        },
      },
    },
    'system/seo/search-keywords': {
      title: 'Поисковые ключи',
      fields: {
        keyword: 'Ключ',
        replacement: 'Синонимы',
      },
    },
    'system/seo/search-queries': {
      fields: {
        ...defaultFields,
      },
    },
    'system/seo/search-preferences': {
      title: 'Настройки поиска',
      fields: {
        ...defaultFields,
        resultsOrder: 'Порядок отображения результатов',
      },
    },
    'common/product-attributes': {
      fields: defaultFields,
    },
    'sales/payments': {
      name: 'Платежи',
      nameOne: 'Платеж',
      fields: salesPaymentsFields,
    },
    'sales/payments-refund': {
      name: 'Повернення коштів',
      nameOne: 'Повернення коштів',
      fields: salesPaymentsFields,
    },
    'sales/discounts/clients': {
      name: 'Клиентские скидки',
      nameOne: 'Клиентская скидка',
      fields: {
        ...defaultFields,
      },
    },
    'sales/discounts/rules/catalog': {
      name: 'По группе без условия',
      nameOne: 'По группе без условия',
      fields: {
        ...defaultFields,
        description: 'Описание',
        percent: 'Процент %',
        isActive: 'Активный',
        allCatalog: 'Весь каталог',
        allClients: 'Все клиенты',
        categories: 'Категории',
        clients: 'Клиенты',
        image: 'Фото',
        offersType: 'Тип оферов',
        startDate: 'Начало',
        endDate: 'Конец',
        productType: 'Типы',
        products: 'Продукты',
      },
    },
    'sales/discounts/rules/website': {
      name: 'По сайту',
      nameOne: 'По сайту',
      fields: {
        ...defaultFields,
        description: 'Описание',
        percent: 'Процент %',
        isActive: 'Активный',
        allCatalog: 'Весь каталог',
        allClients: 'Все клиенты',
        categories: 'Категории',
        clients: 'Клиенты',
        image: 'Фото',
        offersType: 'Тип оферов',
        startDate: 'Начало',
        endDate: 'Конец',
        productType: 'Типы',
        products: 'Продукты',
      },
    },
    'sales/discounts/rules/progressive': {
      name: 'По группе с условием',
      fields: {
        ...defaultFields,
        description: 'Описание',
        percent: 'Процент %',
        isActive: 'Активный',
        allCatalog: 'Весь каталог',
        allClients: 'Все клиенты',
        categories: 'Категории',
        clients: 'Клиенты',
        image: 'Фото',
        offersType: 'Тип оферов',
        startDate: 'Начало',
        endDate: 'Конец',
        productType: 'Типы',
        products: 'Продукты',
        conditions: 'Условия',
        qty: 'Количество',
        categories1C: 'Категория 1С',
      },
    },
    'sales/discounts/rules/balance': {
      name: 'Накопительные скидки',
      nameOne: 'Накопительная скидка',
      fields: {
        ...defaultFields,
        type: 'Тип',
        isActive: 'Активный',
        productGroupCode: 'Группа продуктов',
        conditions: 'Условия скидки',
        conditionsArray: 'Условия скидки',
        percent: 'Процент',
        qty: 'Количество',
        checkDay: 'День недели проверки баланса',
        checkTime: 'Время проверки баланса',
      },
    },
    'sales/offers/special-offers': {
      name: 'Акции',
      fields: {
        ...defaultFields,
      },
    },
    'sales/offers/transit-offers': {
      name: 'Товари в дорозі',
      fields: {
        ...defaultFields,
      },
    },
    'sales/business/merchants': {
      name: 'Мерчанти',
      nameOne: 'Мерчант',
      fields: {
        ...defaultFields,
        code: 'Код 1С',
      },
    },
    'sales/business/cashiers': {
      name: 'Касири',
      nameOne: 'Касир',
      fields: {
        ...defaultFields,
      },
    },
    'sales/business/cash-registers': {
      name: 'Каси',
      nameOne: 'Каса',
      fields: {
        ...defaultFields,
      },
    },
    'users/payments': {
      fields: {
        operationType: 'Операция',
        details: 'Детали',
        amountBase: 'Базовая сумма',
        amount: 'Сумма',
        balance: 'Балланс',
        currencyRate: 'Валютный рейт',
        'currencyBase.name': 'Базовая валюта',
        'currency.name': 'Валюта',
        'client.customerId': 'ID клиента',
        'client.businessName': 'Бизнес имя клиента',
        createdAt: 'Создан',
        updatedAt: 'Обновлён',
        region: 'Регион',
      },
    },
    'sales/orders': {
      name: 'Заказ |||| Заказы',
      nameOne: 'Заказ',
      fields: salesOrdersFields,
    },
    'sales/orders-refund': {
      name: 'Повернення товарів',
      nameOne: 'Повернення товарів',
      fields: salesOrdersFields,
    },
    'sales/expired-carts': {
      name: 'Истекшие заказы',
      fields: {
        order: 'Порядок отображения',
        'client.customerId': 'ID клиента',
        discount: 'Скидка',
        resolved: 'Завершено',
        total: 'Общая сумма',
        subtotal: 'Сумма',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    'sales/active-carts': {
      name: 'Корзина',
      fields: {
        warehouse: { name: 'Склад', code: 'Код складу' },
        product: { name: 'Продукт', code: 'Код продукту' },
        client: { name: 'Клієнт', code: 'Код клієнта' },
        qty: 'Кількість',
        createdAt: 'Створена',
      },
    },
    'client/outlets': {
      name: 'Адреса клиентов',
      fields: {
        code: 'Код',
        client: 'Клиент',
        name: 'Название',
        state: 'Область',
        region: 'Регион',
        city: 'Город',
        street: 'Улица',
        building: 'Дом',
        location: 'Локация',
        latitude: 'Широта',
        longitude: 'Долгота',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    'client/payment-types': {
      fields: {
        paymentType: 'Доступный тип оплаты',
      },
    },
    'system/outlets': {
      name: 'Адреса системы',
      fields: {
        code: 'Код',
        client: 'Клиент',
        name: 'Название',
        state: 'Область',
        region: 'Регион',
        city: 'Город',
        street: 'Улица',
        building: 'Дом',
        latitude: 'Широта',
        longitude: 'Долгота',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
    },
    'sales/shipping/locations': {
      name: 'Города',
      fields: {
        name: 'Название',
        state: 'Область',
        region: 'Регион',
        city: 'Город',
        deliveryPricePerCity: 'Цена доставки',
        isCoveredByDeliveryNetwork: 'Включён в сеть доставки',
        isDeliveryFree: 'Бесплатная доставка',
        createdAt: 'Создан',
        updatedAt: 'Обновлён',
        deleted: 'Удалён',
        approved: 'Подтверждён',
        ignoreParentParams: 'Игнорировать наследование параметров ',
        deliveryType: 'Способы доставки',
      },
      tabs: {
        details: 'Детали',
        schedule: 'График доставки',
        flags: 'Флаги',
      },
      days: {
        monday: 'Понедельник',
        tuesday: 'Вторник',
        wednesday: 'Среда',
        thursday: 'Четверг',
        friday: 'Пятница',
        saturday: 'Суббота',
        sunday: 'Воскресенье',
      },
    },
    'sales/shipping/delivery-times': {
      name: 'Периоды доставки',
      fields: {
        ...defaultFields,
        description: 'Описание',
        hoursFrom: 'От, часов',
        hoursTo: 'До, часов',
        active: 'Активный',
        origin: 'Источник',
      },
    },
    'sales/shipping/self-pickup-times': {
      name: 'Час самовивозу',
      fields: {
        ...defaultFields,
        hoursTo: 'Година',
        minutesTo: 'Хвилина',
        active: 'Активный',
        origin: 'Джерело',
      },
    },
    'sales/shipping/preorder-shipping-rules': {
      name: 'Правила предзаказов',
      fields: {
        ...defaultFields,
        country: 'Страна',
        manufacturer: 'Производитель',
        dayMargin: 'Через дней',
        active: 'Активный',
        days: 'Дни недели',
      },
    },
    'home/featured-products-sections': {
      name: 'Секция избранных товаров |||| Секции избранных товаров',
      fields: {
        order: 'Порядок отображения',
        catalogCategory: 'Категория',
        productType: '1с тип продукта',
        image: 'Промо изображение',
        enabled: 'Активный',
        color: 'Цвет фона промо',
      },
    },
    products: {
      name: 'Товар |||| Товары',
      productsToCategories: 'Продукты в категории',
      fields: {
        category_id: 'Категория',
        height_gte: 'Минимальная высота',
        height_lte: 'Максимальная высота',
        height: 'Высота',
        images: 'Изображения',
        price: 'Цена',
        reference: 'SKU',
        stock_lte: 'Заканчивается',
        thumbnail: 'Иконка',
        width_gte: 'Минимальная ширина',
        width_lte: 'Максимальная ширина',
        width: 'Ширина',
        name: 'Имя',
        fullName: 'Полное имя',
        code: 'Код',
        alias: 'Чпу url',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
        syncDate: 'Последняя синхронизация',
        stock: 'Количество на складах',
        inStock: 'Количество',
        'price.0': 'Цена',
        supplier: 'Поставщик',
        priceType: 'Тип цены',
        productType: 'Тип продукту',
        showOnMainPage: 'Для главной',
        hidden: 'Скрыть',
        attribute: 'Аттрибут',
        attributes: 'Аттрибуты',
        newAttributes: 'Новые аттрибуты',
        value: 'Значение',
        inPackageCount: 'В упаковке',
        unit: 'Единица',
        warehouse: {
          name: 'Имя',
          code: 'Код',
        },
      },
      tabs: {
        images: 'Изображения',
        details: 'Детали',
        description: 'Описание',
        reviews: 'Отзывы',
        settings: 'Настройки',
        attributes: 'Аттрибуты',
        'stocks-prices': 'Склады/Цены',
      },
    },
    catalog: {
      name: 'Каталог',
    },
    'catalog/products': {
      prices: {
        title: 'Цены',
        supplier: 'Поставщик',
        priceType: 'Тип цены',
        price: 'Цена',
        updatedAt: 'Дата изменения',
      },
      stock: {
        title: 'Склады',
        code: 'Код',
        name: 'Имя',
        supplier: 'Поставщик',
        inStock: 'Количество',
      },
      attributes: {
        title: 'Атрибуты',
        name: 'Название',
        value: 'Значение',
      },
      fields: {
        category_id: 'Категория',
        height_gte: 'Минимальная высота',
        height_lte: 'Максимальная высота',
        height: 'Высота',
        images: 'Изображения',
        price: 'Цена',
        reference: 'SKU',
        stock_lte: 'Заканчивается',
        thumbnail: 'Иконка',
        width_gte: 'Минимальная ширина',
        width_lte: 'Максимальная ширина',
        width: 'Ширина',
        name: 'Имя',
        fullName: 'Полное имя',
        code: 'Код',
        alias: 'Чпу url',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
        syncDate: 'Последняя синхронизация',
        stock: 'Количество на складах',
        inStock: 'Количество',
        'price.0': 'Цена',
        supplier: 'Поставщик',
        priceType: 'Тип цены',
        productType: 'Тип продукту',
        showOnMainPage: 'Для главной',
        hidden: 'Скрыть',
        attribute: 'Аттрибут',
        attributes: 'Аттрибуты',
        newAttributes: 'Новые аттрибуты',
        value: 'Значение',
        inPackageCount: 'В упаковке',
        unit: 'Единица',
        warehouse: {
          name: 'Имя',
          code: 'Код',
        },
      },
    },
    categories: {
      name: 'Категорія |||| Категорії',
      nameAccessories: 'Фурнітура',
      nameShearFlowers: 'Зріз',
      namePottedFlowers: 'Вазони',
      nameStabilizedFlowers: 'Стабілізовані Квіти',
      nameCeramics: 'Горщики',
      nameOther: 'Інші',
      edit: 'Редагування',
      create: 'Створити',
      productsInCategory: 'Продукти в категорії',
      availableProducts: 'Продукти без категорії',
      viewProducts: 'До продуктів',
      back: 'Назад',
      fields: {
        name: "Им'я",
        alias: 'Аліас',
        isMainMenu: 'Для меню в хедере',
        hidden: 'Сховати категорию',
        productType: '1с тип продукта',
        enabled: 'Активний',
      },
    },
    homePage: {
      sliders: {
        name: 'Слайдер',
        fields: {
          btnLabel: 'Текст кнопки',
          href: 'Посилання',
          header: 'Заголовок',
          description: 'Опис',
          image: 'Фон десктоп',
          mobileImage: 'Фон мобайл',
          color: 'Колір тексту',
          order: 'Порядок',
          newImageDesktop: 'Новий фон десктоп',
          newImageMobile: 'Новий фон мобайл',
          enabled: 'Активный',
          createdAt: 'Створений',
          updatedAt: 'Оновлений',
        },
      },
      productSections: {
        name: 'Избранные товары',
      },
      offers: {
        name: 'Спец предложения',
        fields: {
          header: 'Заголовок',
          product: 'Продукт',
          newProduct: 'Новый продукт',
          oldProduct: 'Старый продукт',
          color: 'Цвет',
          expiredTime: 'Время окончания',
          image: 'Фон',
          newImage: 'Новый фон',
          enabled: 'Активный',
          createdAt: 'Создан',
          updatedAt: 'Обновлен',
        },
      },
    },
    settings: {
      fields: {
        number: 'Телефонный номер',
        header: 'Заголовок',
        description: 'Описание',
        image: 'Фон',
        name: ' Ключ',
        label: 'Название',
        type: 'Тип',
        value: 'Значение',
        createdAt: 'Создан',
        updatedAt: 'Обновлен',
      },
      types: {
        simpleText: 'Текст',
        multilineText: 'Расширенный текст',
      },
      validation: {
        name_invalid: 'Должен быть ключ без цифр, пробелов и спецсимволов',
      },
    },
    reviews: {
      name: 'Отзыв |||| Отзывы',
      detail: 'Детально',
      fields: {
        user_id: 'Покупатель',
        command_id: 'Заказ',
        product_id: 'Продукт',
        date_gte: 'Оставлен после',
        date_lte: 'Оставлен до',
        date: 'Дата',
        comment: 'Комментарий',
        rating: 'Рейтинг',
      },
      action: {
        accept: 'Утвердить',
        reject: 'Отклонить',
      },
      notification: {
        approved_success: 'Отзыв утвержден',
        approved_error: 'Ошибка: Отзыв не утвержден',
        rejected_success: 'Отзыв отклонен',
        rejected_error: 'Ошибка: Отзыв не отклонен',
      },
    },
    'sales/shipping/days': {
      mon: 'Понедельник',
      tue: 'Вторник',
      wed: 'Среда',
      thu: 'Четверг',
      fri: 'Пятница',
      sat: 'Субота',
      sun: 'Воскресенье',
    },
  },
};
