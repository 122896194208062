import React from 'react';
import {
  TextInput,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceManyField,
} from 'react-admin';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const warehouseFilter = [<TextInput source="q" alwaysOn label="Поиск" />];

const WarehouseList = props => {
  return (
    <CustomList filters={warehouseFilter} {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="alias" />
        <TextField source="code" />
        <TextField source="displayName" />
        <TextField source="name" />
        <ReferenceManyField source="suppliers" reference="system/suppliers" target="code">
          <SingleFieldList>
            <ChipField source="displayName" />
          </SingleFieldList>
        </ReferenceManyField>
        <ReferenceArrayField reference="system/outlets" source="outlets">
          <SingleFieldList>
            <TextField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <BooleanColoredField source="deleted" />
        <BooleanColoredField source="selfPickUp" />
        <BooleanColoredField source="isDefault" />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};

export default WarehouseList;
