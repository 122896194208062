import React from 'react';
import { Create, Form } from 'react-admin';
import { SliderCreateForm } from './components/SliderCreateForm';
import { onSave } from './components/sliderSaveTransformer';

export const SliderCreate = ({ classes, ...props }) => (
  <Create mutationMode="optimistic" {...props} transform={onSave}>
    <Form>
      <SliderCreateForm />
    </Form>
  </Create>
);
