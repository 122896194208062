import React from 'react';
import { Link, TextField, SearchInput, DateInput, useRecordContext } from 'react-admin';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { useStyles } from '../../expired-carts/ExpiredCardDetails';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { CustomList } from '../../../common/CustomList';
import { RedirectField } from '../../../fields/RedirectField';
import LocalDateField from '../../../fields/LocalDateField';

const Product = () => {
  const record = useRecordContext();
  const classes = useStyles();
  const { products } = record.results;

  return products && products.length ? (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" padding="none">
        <TableHead>
          <TableRow>
            <TableCell align="left">Ссылка</TableCell>
            <TableCell align="left">Код</TableCell>
            <TableCell>Категория</TableCell>
            <TableCell align="right">Код Категории</TableCell>
            <TableCell align="right">Алиас</TableCell>
            <TableCell align="right">Имя</TableCell>
            <TableCell align="right">Отображаемое имя</TableCell>
            <TableCell align="right">Полное Имя</TableCell>
            <TableCell align="right">Группа Товаров</TableCell>
            <TableCell align="right">Код Группы Товаров</TableCell>
            <TableCell align="right">Тип продукта</TableCell>
            <TableCell align="right">Код типа продукта</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map(item => (
            <TableRow key={`cart-item-${item.id}`}>
              <TableCell align="right">
                <Link to={`/products/${item.id}`}>{item.id}</Link>
              </TableCell>
              <TableCell align="right">{item.code}</TableCell>
              <TableCell align="right">{item.catalogCategory}</TableCell>
              <TableCell align="right">{item.catalogCategoryCode}</TableCell>
              <TableCell align="right">{item.alias}</TableCell>
              <TableCell align="right">{item.name}</TableCell>
              <TableCell align="right">{item.displayName}</TableCell>
              <TableCell align="right">{item.fullName}</TableCell>
              <TableCell align="right">{item.productGroup}</TableCell>
              <TableCell align="right">{item.productGroupCode}</TableCell>
              <TableCell align="right">{item.productType}</TableCell>
              <TableCell align="right">{item.productTypeCode}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <p>Результатов по запросу {record.queryText} не найдено</p>
  );
};

const searchQueriesFilter = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="createdAt" label="Дата создания" />,
];

export default props => {
  return (
    <CustomList filters={searchQueriesFilter} {...props}>
      <NewTabLinkDataGrid expand={<Product />}>
        <TextField source="queryText" label="Запрос" />
        <RedirectField
          source="client.businessName"
          linkIdSource="client.id"
          linkUrl="clients"
          label="Бизнес имя"
        />
        <TextField source="fullQuery.productTypeCode" label="Код продукта" />
        <LocalDateField source="createdAt" showTime />
        <LocalDateField source="updatedAt" showTime />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};
