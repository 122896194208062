import React from 'react';
import { Create, Form } from 'react-admin';
import { onSaveSpecialOffers } from '../helpers';
import { SpecialOfferForm } from './components/SpecialOfferForm';

export const SpecialOfferCreate = ({ classes, ...props }) => (
  <Create mutationMode="optimistic" {...props} transform={onSaveSpecialOffers}>
    <Form>
      <SpecialOfferForm />
    </Form>
  </Create>
);
