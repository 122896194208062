import React, { useState } from 'react';
import { useGetOne, Error, useRecordContext } from 'react-admin';
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';

export const Show1cLog = () => {
  const record = useRecordContext();
  const { data, isLoading, error } = useGetOne('system/1c-log', {
    id: record.id,
  });

  if (isLoading)
    return (
      <div
        style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <CircularProgress />
      </div>
    );
  if (error) return <Error error="Error... please try again." />;
  if (!data) return null;
  const {request, response} = data

  if (!request && !response) return 'No request, no response';

  return (
    <>
      <Accordion TransitionProps={{ timeout: 0 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Request data</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <pre>{JSON.stringify(request, null, 2)}</pre>
        </AccordionDetails>
      </Accordion>
      <Accordion TransitionProps={{ timeout: 0 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Response data</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
