import React from 'react';
import { SimpleForm, TextInput, SelectInput, FormDataConsumer, useTranslate } from 'react-admin';
import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import { settingValueTypes } from './constants/settingValueTypes';
import FormToolbar from '../../../common/FormToolbar';
import { CustomEdit } from '../../../common/CustomEdit';

export const SettingsEdit = props => {
  const translate = useTranslate();

  return (
    <CustomEdit redirect="list" {...props}>
      <SimpleForm toolbar={<FormToolbar />}>
        <TextInput source="label" />
        <TextInput source="name" />
        <SelectInput
          source="type"
          choices={[
            { id: settingValueTypes.text, name: translate(`resources.settings.types.simpleText`) },
            {
              id: settingValueTypes.multiText,
              name: translate(`resources.settings.types.multilineText`),
            },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type === settingValueTypes.text ? (
              <TextInput source="value" title="Value" {...rest} />
            ) : formData.type === settingValueTypes.multiText ? (
              <RichTextInput
                toolbar={<RichTextInputToolbar size="medium" />}
                source="value"
                title="Value"
                {...rest}
              />
            ) : null
          }
        </FormDataConsumer>
      </SimpleForm>
    </CustomEdit>
  );
};
