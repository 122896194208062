import React from 'react';
import { TextField } from 'react-admin';
import { CustomList } from '../../common/CustomList';
import { NewTabLinkDataGrid } from '../../common/NewTabLinkDataGrid';

const FeatureFlagList = props => (
  <CustomList {...props}>
    <NewTabLinkDataGrid rowClick="edit">
      <TextField source="id" />
      <TextField source="code" />
      <TextField source="name" />
      <TextField source="description" />
    </NewTabLinkDataGrid>
  </CustomList>
);

export default FeatureFlagList;
