import React from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import { useTranslate } from 'react-admin';

const SubMenu = ({ handleToggle, sidebarIsOpen, isOpen, name, icon, children }) => {
  const translate = useTranslate();
  return (
    <>
      <ListItem dense button onClick={handleToggle} sx={{ paddingLeft: '1rem' }}>
        <ListItemIcon sx={{ minWidth: 'auto' }}>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
        <ListItemText
          inset
          primary={isOpen ? translate(name) : ''}
          secondary={isOpen ? '' : translate(name)}
          sx={{ paddingLeft: 2, fontSize: '1rem' }}
        />
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense
          component="div"
          disablePadding
          sx={
            sidebarIsOpen
              ? {
                  paddingLeft: '25px',
                  transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                }
              : {
                  paddingLeft: 0,
                  transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                }
          }
        >
          {children}
        </List>
        <Divider />
      </Collapse>
    </>
  );
};

export default SubMenu;
