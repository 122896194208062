import { useRecordContext } from 'react-admin';
import { OrderStatusOptions } from '../models/Order';

const OrderStatusField = () => {
  const record = useRecordContext();
  return <span>{OrderStatusOptions[record.orderStatus]}</span>;
};

OrderStatusField.defaultProps = {
  source: 'orderStatus',
  label: 'resources.sales/orders.fields.orderStatus',
};

export default OrderStatusField;
