export async function onSave({
  image,
  mobileImage,
  ...restData
}) {
  const formData = new FormData();
  if (image) {
    formData.append('files', image.rawFile);
  }

  if (mobileImage) {
    formData.append('mobileFiles', mobileImage.rawFile);
  }

  formData.append('data', JSON.stringify(restData));

  return formData;
}
