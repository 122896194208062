import { Loading, useGetList, useRecordContext, useTheme, useUpdate } from 'react-admin';
import { useState } from 'react';
import SortableTree from '@nosferatu500/react-sortable-tree';
import classes from './styles.module.scss';

const AttributesOrder = ({ attributes = [], updateRoute, notIncludeAllData }) => {
  const [theme] = useTheme();
  const record = useRecordContext();
  const [state, setState] = useState(
    (record.attributesOrder || [])
      // attrOrder is [] of string-aliases, so get full objects from all attributes
      .map((alias) => attributes.find(({ alias: alias2 }) => alias === alias2))
  );

  const [update, { isLoading }] = useUpdate();

  const treeData = [
    {
      title: 'Порядок аттрибутов',
      expanded: true,
      children: state,
      canDrag: false,
    },
    {
      title: 'Доступные аттрибуты',
      expanded: true,
      canDrag: false,
      children: attributes
        // exclude those which are in 1st list
        .filter(({ id }) => !state.find(({ id: id2 }) => id === id2))
        .map((item) => ({ ...item, title: item.name })),
    },
  ];

  const onSave = function (newValue) {
    const data = {
      ...(notIncludeAllData ? {} : record),
      attributesOrder: newValue.map((item) => item.alias),
    };

    update(updateRoute, { id: record.id, data, previousData: record });
  };

  const onChange = function (newValue) {
    setState(newValue[0].children);
    onSave(newValue[0].children); // request api every change
  };

  return (
    <div style={{ height: (attributes.length + 2) * 40, paddingBottom: 10, width: '100%' }}>
      <SortableTree
        treeData={treeData}
        onChange={onChange}
        maxDepth={2}
        rowHeight={38}
        canDrag={() => !isLoading}
        canDrop={(item) => !!item.nextParent}
        className={theme === 'dark' ? classes.sortableTreeRow : ''}
      ></SortableTree>
    </div>
  );
};

export const AttributesOrderWithAttributes = (props) => {
  const { data, isLoading, error } = useGetList('common/product-attributes', {
    pagination: { page: 1, perPage: 1000000 },
  });

  if (isLoading) return <Loading />;
  if (error) return <p>ERROR {error.message}</p>;

  const attributes =
    data && Array.isArray(data) && data.map((item) => ({ ...item, title: item.name }));

  return attributes ? <AttributesOrder {...props} attributes={attributes} /> : null;
};

export default AttributesOrderWithAttributes;
