import React from 'react';
import {
  TextInput,
  BooleanInput,
  required,
  NumberInput,
  ImageInput,
  ImageField,
  useRecordContext,
} from 'react-admin';
import { Card, CardContent, Grid, Box } from '@mui/material';
import { ColorInput } from 'react-admin-color-picker';
import Typography from '@mui/material/Typography';
import FormToolbar from '../../../../common/FormToolbar';
import ImagePreview from '../../../../common/ImagePreview';
import { Spacer } from '../../../../common/Spacer';

export const SliderEditForm = (formProps) => {
  const record = useRecordContext();

  return (
    <Box maxWidth="70em">
      <Card>
        <CardContent>
          <Grid container spacing={8}>
            <Grid item sm={12} md={4}>
              <BooleanInput
                label="resources.homePage.sliders.fields.enabled"
                source="enabled"
                record={record}
                fullWidth
              />
              <TextInput
                label="resources.homePage.sliders.fields.header"
                source="header"
                record={record}
                validate={[required()]}
                fullWidth
              />
              <TextInput
                label="resources.homePage.sliders.fields.description"
                source="description"
                record={record}
                validate={[required()]}
                fullWidth
              />
              <NumberInput
                label="resources.homePage.sliders.fields.order"
                source="order"
                record={record}
                fullWidth
              />
              <ColorInput
                label="resources.homePage.sliders.fields.color"
                source="color"
                record={record}
                fullWidth
              />
              <TextInput
                label="resources.homePage.sliders.fields.btnLabel"
                source="btnLabel"
                record={record}
                fullWidth
              />
              <TextInput
                label="resources.homePage.sliders.fields.href"
                source="href"
                record={record}
                fullWidth
              />
            </Grid>
            <Grid item sm={12} md={8}>
              <Typography variant="h6">Фото десктоп</Typography>
              <ImagePreview
                urlSource="static/slider-images"
                label="resources.homePage.sliders.fields.image"
                imageStyles={{ width: 500, height: 300 }}
                record={record}
              />
              <Typography variant="body2">Фото должно быть не менее 1368x650 пикселей</Typography>
              <ImageInput
                label="resources.homePage.sliders.fields.newImageDesktop"
                source="image"
                record={record}
                accept={{ 'image/*': ['.*'] }}
                validate={[required()]}
              >
                <ImageField
                  label="resources.homePage.sliders.fields.image"
                  source="image"
                  title="Background image"
                />
              </ImageInput>
              <Spacer />
              <Typography variant="h6">Фото мобайл</Typography>
              <ImagePreview
                urlSource="static/slider-images"
                source="mobileImage"
                label="resources.homePage.sliders.fields.mobileImage"
                imageStyles={{ width: 500, height: 300 }}
                record={record}
              />
              <Typography variant="body2">Фото должно быть не менее 360x470 пикселей</Typography>
              <ImageInput
                label="resources.homePage.sliders.fields.newImageMobile"
                source="mobileImage"
                accept={{ 'image/*': ['.*'] }}
                record={record}
                validate={[required()]}
              >
                <ImageField
                  label="resources.homePage.sliders.fields.image"
                  source="mobileImage"
                  title="Background image"
                />
              </ImageInput>
            </Grid>
          </Grid>
        </CardContent>
        <FormToolbar {...formProps} />
      </Card>
    </Box>
  );
};
