import React from 'react';
import { Create } from 'react-admin';
import PriceTypeGroupsForm from './PriceTypeGroupsForm';

const PriceTypeGroupsCreate = props => {
  return (
    <Create redirect="list" {...props}>
      <PriceTypeGroupsForm />
    </Create>
  );
};

export default PriceTypeGroupsCreate;
