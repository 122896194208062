import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomEdit } from '../../../common/CustomEdit';
import { OrderTypesForm } from './components/OrderTypesForm';

const OrderTypesTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? (
    <span>
      {translate('resources.system/order-types.title')}
      &nbsp;{record.name}
    </span>
  ) : null;
};

export const OrderTypesEdit = props => (
  <CustomEdit title={<OrderTypesTitle />} component="div" {...props}>
    <Form>
      <OrderTypesForm />
    </Form>
  </CustomEdit>
);
