import React from 'react';
import {
  TextInput,
  TextField,
  DateInput,
  SelectInput,
  SearchInput,
  DatagridConfigurable,
} from 'react-admin';
import LocalDateField from '../../../fields/LocalDateField';
import { Show1cLog } from './show1cLog';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const operationTypes = [
  'export-customer',
  'export-discount',
  'export-order',
  'export-outlet',
  'export-payment',
  'export-product-image',
  'import-client-balance',
  'import-customer',
  'import-order',
  'import-outlet',
  'import-payment',
  'import-price',
  'import-products',
  'import-stock',
  'import-special-offer',
  'import-transit-offer',
  'import-transit-offers-update',
  'virtual-cart',
  'virtual-cart-export',
];

const operationChoices = operationTypes.map((id) => ({ id, name: id }));

const errors1cFilter = [
  <SearchInput source="q" alwaysOn placeholder="Поиск" />,
  <TextInput source="status" alwaysOn />,
  <SelectInput source="operation" choices={operationChoices} />,
  <DateInput source="url" />,
];

const ErrorList = (props) => (
  <CustomList filters={errors1cFilter} {...props}>
    <DatagridConfigurable expand={<Show1cLog />}>
      <TextField source="operation" />
      <TextField source="method" />
      <TextField source="status" />
      <LocalDateField source="createdAt" showTime />
      <LocalDateField source="updatedAt" showTime />
      <TextField source="url" />
      <BooleanColoredField source="deleted" />
    </DatagridConfigurable>
  </CustomList>
);

export default ErrorList;
