import React from 'react';
import {
  ReferenceArrayField,
  TextField,
  SingleFieldList,
  ChipField,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import LocalDateField from '../../fields/LocalDateField';
import { QuickFilter } from '../catalog/products/ProductList';
import { NewTabLinkDataGrid } from '../../common/NewTabLinkDataGrid';
import { CustomList } from '../../common/CustomList';
import { BooleanColoredField } from '../../fields/BooleanColoredField';
import { LocationsListAside } from './components/LocationsListAside';
import { weekDays } from '../../../constants/weekDays';

const locationsFilter = [
  <QuickFilter source={'statesOnly'} label={'Только области'} defaultValue={1} />,
  <BooleanInput
    source={'isCoveredByDeliveryNetwork'}
    defaultValue={true}
    label={'resources.sales/shipping/locations.fields.isCoveredByDeliveryNetwork'}
  />,
  <BooleanInput
    source={'ignoreParentParams'}
    defaultValue={true}
    label={'resources.sales/shipping/locations.fields.ignoreParentParams'}
  />,
  <BooleanInput
    source={'approved'}
    defaultValue={true}
    label={'resources.sales/shipping/locations.fields.approved'}
  />,
  <SelectArrayInput
    label={'Дни доставки'}
    source={'delivery'}
    style={{ minWidth: 200 }}
    choices={weekDays}
  />,
  <ReferenceArrayInput
    label="Города"
    source="city"
    reference="sales/shipping/locations"
    style={{ minWidth: 200 }}
    format={values => {
      return values || [];
    }}
  >
    <AutocompleteArrayInput optionText="city" source={'city'} optionValue={'city'} />
  </ReferenceArrayInput>,
];

export const LocationsList = props => (
  <CustomList filters={locationsFilter} aside={<LocationsListAside />} {...props}>
    <NewTabLinkDataGrid rowClick="edit">
      <TextField source="name" />
      <TextField source="state" />
      <TextField source="region" />
      <TextField source="city" />
      <TextField source="deliveryPricePerCity" />
      <ReferenceArrayField
        source={'deliveryType'}
        reference="sales/shipping/methods"
        style={{ padding: '10px auto' }}
      >
        <SingleFieldList style={{ margin: '10px auto', pointerEvents: 'none' }}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <LocalDateField source="createdAt" showTime />
      <LocalDateField source="updatedAt" showTime />
      <BooleanColoredField source="deleted" />
      <BooleanColoredField source="isCoveredByDeliveryNetwork" />
      <BooleanColoredField source="ignoreParentParams" />
      <BooleanColoredField source="approved" />
      <BooleanColoredField source="isDeliveryFree" />
    </NewTabLinkDataGrid>
  </CustomList>
);

export default LocationsList;
