import { useRecordContext } from 'react-admin';

export const ShortTextField = ({ source }) => {
  const record = useRecordContext();
  let text = '';
  if (record[source]) {
    if (record[source].length > 50) {
      text = record[source].slice(0, 50);
    } else {
      text = record[source];
    }
  }
  return <div style={{ minWidth: 200 }}>{text ? `${text}...` : ''}</div>;
};
