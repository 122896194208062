import React from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { RedirectField } from '../../fields/RedirectField';
import { ClientDataView } from '../../common/ClientDataView';
import { useRecordContext } from 'react-admin';

export const useStyles = makeStyles({
  table: {
    width: 'auto',
  },
  orderInfo: {
    display: 'flex',
    '& > div': {
      width: 'auto',
    },
    '& > div:first-child': {
      marginRight: 40,
    },
  },
});

export const ExpiredCardDetails = () => {
  const record = useRecordContext();
  const classes = useStyles();
  const { cartItems, client } = record;
  return (
    <div className={classes.orderInfo}>
      <ClientDataView data={client} />
      {cartItems && cartItems.length ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Код</TableCell>
                <TableCell>Товар</TableCell>
                <TableCell align="right">Цена клиента</TableCell>
                <TableCell align="right">Цена предложения</TableCell>
                <TableCell align="right">Скидка</TableCell>
                <TableCell align="right">Скидка на единицу</TableCell>
                <TableCell align="right">Количество</TableCell>
                <TableCell align="right">Сумма</TableCell>
                <TableCell align="right">На сладе</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItems.map(item => (
                <TableRow key={`cart-item-${item.id}`}>
                  <TableCell align="left">
                    <RedirectField
                      record={item.product || {}}
                      source="code"
                      linkIdSource="id"
                      linkUrl="catalog/products"
                      label="Код"
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {(item.product && item.product.fullName) || ''}
                  </TableCell>
                  <TableCell align="right">UAH&nbsp;{item.clientPrice || ''}</TableCell>
                  <TableCell align="right">UAH&nbsp;{item.offerPrice || ''}</TableCell>
                  <TableCell align="right">{item.discountPercent}%</TableCell>
                  <TableCell align="right">{item.discountPerItem}</TableCell>
                  <TableCell align="right">{item.qty}</TableCell>
                  <TableCell align="right">UAH&nbsp;{item.total}</TableCell>
                  <TableCell align="right">{item.product && item.product.inStockTotal}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </div>
  );
};
