import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomEdit } from '../../common/CustomEdit';
import { ExpiredCartEditForm } from './components/ExpiredCartEditForm';

const ExpiredCartTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? (
    <span>
      {translate('resources.sales/orders.nameOne')}
      &nbsp;{record.orderId}
    </span>
  ) : null;
};

function transform({
  deliveryMethod,
  deliveryDate,
  deliveryTime,
  packageReturn,
  totalSum,
  orderStatus,
  client,
  ...rest
}) {
  return {
    deliveryMethod,
    deliveryDate,
    deliveryTime: deliveryTime ? deliveryTime.id : null,
    packageReturn,
    totalSum,
    orderStatus,
    client: client.id,
  };
}

const ExpiredCartEdit = props => (
  <CustomEdit title={<ExpiredCartTitle />} component="div" transform={transform} {...props}>
    <Form>
      <ExpiredCartEditForm />
    </Form>
  </CustomEdit>
);

export default ExpiredCartEdit;
