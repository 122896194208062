import { Create } from 'react-admin';
import React from 'react';
import LocationsForm from './LocationsForm';


const LocationsCreate = (props) => (
  <Create {...props}>
    <LocationsForm />
  </Create>
);

export default LocationsCreate;