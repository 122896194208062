import React, { useState } from 'react';
import { useDataProvider, useNotify, useRecordContext } from 'react-admin';
import { CronJobEditForm } from './CronJobEditForm';

export const CronJobEditFormContainer = props => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [pendingActions, setPendingActions] = useState(false);

  const handleJobAction = action => {
    setPendingActions(true);

    dataProvider
      .create(`system/cron-jobs/${record.id}/${action}`, {})
      .then(() => notify('Команда запущена', { type: 'success' }))
      .catch(() => notify('Ошибка: команда не запущена', { type: 'warning' }))
      .finally(() => setPendingActions(false));
  };
  return (
    <CronJobEditForm handleJobAction={handleJobAction} pendingActions={pendingActions} {...props} />
  );
};
