import AvatarField from './AvatarField';
import { useRecordContext } from 'react-admin';

const FullNameField = ({ size }) => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginRight: 20,
        order: 0,
      }}
    >
      <AvatarField record={record} size={size} />
      &nbsp;{record.profile ? record.profile.name : record.username}
    </div>
  );
};

FullNameField.defaultProps = {
  source: 'name',
  label: 'resources.users.fields.name',
};

export default FullNameField;
