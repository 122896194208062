import React from 'react';
import { SearchInput } from 'react-admin';
import { CustomList } from '../../../common/CustomList';
import { SpecialOffersTable } from './components/SpecialOffersTable';

const specialOffersFilters = [<SearchInput source="q" alwaysOn />];

export const SpecialOffersList = props => (
  <CustomList filters={specialOffersFilters} {...props}>
    <SpecialOffersTable />
  </CustomList>
);
