import React from 'react';
import {
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ArrayField,
  useRecordContext,
} from 'react-admin';
import Chip from '@mui/material/Chip';
import { NewTabLinkDataGrid } from '../../../../common/NewTabLinkDataGrid';
import { BooleanColoredField } from '../../../../fields/BooleanColoredField';
import LocalDateField from '../../../../fields/LocalDateField';
import { offersTypeOptions, extractPercent } from '../../helpers';
import { useStyles } from '../styles';

const OfferTypesField = () => {
  const record = useRecordContext();
  const offerType = offersTypeOptions.find((i) => record === i.id);
  return offerType ? <Chip size="small" label={offerType.name} /> : null;
};

const PercentField = () => {
  const record = useRecordContext();
  return extractPercent(record);
};

export const ProductDiscountTable = (props) => {
  const classes = useStyles();

  return (
    <NewTabLinkDataGrid rowClick="edit" style={{ padding: '0 8px 0 8px' }} {...props}>
      <TextField source="name" />
      <PercentField source="percent" />
      <BooleanColoredField source="isActive" />
      <BooleanColoredField source="allCatalog" />
      <BooleanColoredField source="allClients" />
      <TextField source="description" />
      <ReferenceArrayField
        source="productType"
        reference="system/product-types"
        className={classes.chipsFields}
      >
        <SingleFieldList>
          <ChipField size="small" source="name" className={classes.chipsFields} />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        source="categories"
        reference="catalog/productType/all"
        className={classes.chipsFields}
      >
        <SingleFieldList>
          <ChipField size="small" source="name" className={classes.chipsFields} />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        source="products"
        reference="catalog/products"
        className={classes.chipsFields}
      >
        <SingleFieldList>
          <ChipField size="small" source="name" className={classes.chipsFields} />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField source="clients" reference="clients">
        <SingleFieldList>
          <ChipField size="small" source="profile.businessName" className={classes.chipsFields} />
        </SingleFieldList>
      </ReferenceArrayField>
      <ArrayField source="offersType">
        <SingleFieldList>
          <OfferTypesField />
        </SingleFieldList>
      </ArrayField>
      <LocalDateField source="startDate" showTime />
      <LocalDateField source="endDate" showTime />
      <LocalDateField source="createdAt" showTime />
      <LocalDateField source="updatedAt" showTime />
    </NewTabLinkDataGrid>
  );
};
