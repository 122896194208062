import React, { useCallback } from 'react';
import {
  DateTimeInput,
  FormDataConsumer,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Card, CardContent, Grid } from '@mui/material';
import { useWatch } from 'react-hook-form';
import FormToolbar from '../../../../common/FormToolbar';
import { HelpOutlined } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { useStyles } from '../styles';
import { ProductRefField } from '../../../../fields/ProductRefField';

function optionText(record) {
  return `${record.name} - ${record.code}`;
}

export const TransitOfferForm = (formProps) => {
  const record = useRecordContext();
  const classes = useStyles();
  const incomingStock = useWatch({ name: 'incomingStock' });

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleGenerateAction = useCallback(
    (action) => {
      dataProvider
        .create(`sales/offers/transit-offers/${record.id}/${action}`, {}) // POST
        .then(() => notify('Команда запущена', { type: 'success' }))
        .catch((err) => notify(`Помилка: ${err.message}`, { type: 'warning' }));
    },
    [formProps]
  );

  return (
    <Box maxWidth="70em">
      <Card>
        <CardContent>
          <Grid container spacing={8}>
            <Grid item sm={12} md={4}>
              {/* @todo add valid resources */}
              <TextInput
                label="Code"
                source="code"
                validate={[required()]}
                fullWidth
                readOnly={true}
                disabled={true}
              />
              <DateTimeInput
                source="endDate"
                label="resources.sales/discounts/rules/website.fields.endDate"
                validate={[required()]}
                fullWidth
                readOnly={true}
                disabled={true}
              />
              <ReferenceInput
                source="priceType"
                reference="system/price-types"
                label="PriceType"
                fullWidth
                readOnly={true}
                disabled={true}
              >
                <SelectInput optionText="name" validate={[required()]} />
              </ReferenceInput>
              <ReferenceInput
                source="supplier"
                reference="system/suppliers"
                label="Поставщик"
                fullWidth
                readOnly={true}
                disabled={true}
              >
                <SelectInput optionText="name" validate={[required()]} />
              </ReferenceInput>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData && formData.supplier ? (
                    <ReferenceInput
                      source="warehouse"
                      reference="system/warehouses"
                      sort={{ field: 'name', order: 'ASC' }}
                      label="Склад"
                      fullWidth
                      readOnly={true}
                      disabled={true}
                      {...rest}
                    >
                      <SelectInput optionText={optionText} validate={[required()]} />
                    </ReferenceInput>
                  ) : null
                }
              </FormDataConsumer>
            </Grid>
            <Grid item sm={12} md={8}>
              <button
                className={classes.actionButton}
                onClick={() => handleGenerateAction('delete')}
              >
                Видалити пропозиції
              </button>
              <button
                className={classes.actionButton}
                onClick={() => handleGenerateAction('refresh')}
              >
                Оновити/згенерувати пропозиції
              </button>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell component="th">№</TableCell>
                  <TableCell component="th">Продукт</TableCell>
                  <TableCell component="th">Кількість</TableCell>
                  <TableCell component="th">Залишки</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(Array.isArray(incomingStock) ? incomingStock : []).map(
                  ({ product, qty, offer, error }, i) => {
                    return product && qty ? (
                      <TableRow key={`cart-item-${product}`}>
                        <TableCell>{++i}</TableCell>
                        <TableCell>
                          <ReferenceField
                            record={{ id: product }}
                            source="id"
                            reference="catalog/products"
                          >
                            <ProductRefField />
                          </ReferenceField>
                        </TableCell>
                        <TableCell>{qty}</TableCell>
                        <TableCell>
                          {offer ? (
                            offer.inStock || 0
                          ) : (
                            <div className={classes.offerQty}>
                              Пропозиція відсутня
                              <Tooltip
                                title={
                                  error ||
                                  'Недостатньо даних для формування пропозиції: ціна, склад'
                                }
                                className={classes.tooltip}
                              >
                                <HelpOutlined />
                              </Tooltip>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ''
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <FormToolbar {...formProps} />
      </Card>
    </Box>
  );
};
