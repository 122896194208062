import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  required,
  useTranslate,
  regex,
} from 'react-admin';
import { RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import { settingValueTypes } from './constants/settingValueTypes';
import FormToolbar from '../../../common/FormToolbar';

export const SettingsCreate = props => {
  const translate = useTranslate();
  const validateNameField = regex(
    /^([a-z]|[A-Z]){3,}\w+$/,
    translate('resources.settings.validation.name_invalid')
  );

  return (
    <Create redirect="list" title="Settings" {...props}>
      <SimpleForm toolbar={<FormToolbar />}>
        <TextInput source="label" title="Label" validate={[required()]} />
        <TextInput source="name" title="Name" validate={[required(), validateNameField]} />
        <SelectInput
          source="type"
          validate={[required()]}
          choices={[
            {
              id: settingValueTypes.text,
              name: translate(`resources.settings.types.simpleText`),
            },
            {
              id: settingValueTypes.multiText,
              name: translate(`resources.settings.types.multilineText`),
            },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.type === settingValueTypes.text ? (
              <TextInput source="value" title="Value" {...rest} validate={[required()]} />
            ) : formData.type === settingValueTypes.multiText ? (
              <RichTextInput
                toolbar={<RichTextInputToolbar size="medium" />}
                source="value"
                title="Value"
                {...rest}
                validate={[required()]}
              />
            ) : null
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
