import SearchPreferencesIcon from '@mui/icons-material/FindReplace';
import { SearchPreferencesList } from './SearchPreferencesList';
import { SearchPreferencesEdit } from './SearchPreferencesEdit';
import { SearchPreferencesCreate } from './SearchPreferencesCreate';

export const icon = SearchPreferencesIcon;

export default {
  list: SearchPreferencesList,
  create: SearchPreferencesCreate,
  edit: SearchPreferencesEdit,
  icon: SearchPreferencesIcon,
};
