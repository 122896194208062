import React from 'react';
import {
  SearchInput,
  NumberInput,
  TextField,
  NumberField,
  useTranslate,
  useRecordContext,
  ReferenceField,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import { Chip, Typography } from '@mui/material';
import ThumbnailField from './ThumbnailField';
import HideButton from './bulk-actions/hide';
import ShowButton from './bulk-actions/show';
import CategoryReferenceField from './CategoryReferenceField';
import LocalDateField from '../../../fields/LocalDateField';
import CategoryReferenceInput from './CategoryReferenceInput';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';
import { Link } from 'react-router-dom';

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: 8,
  },
}));

const ManufacturerField = () => {
  const record = useRecordContext();
  if (!record) return null;
  const manufacturer = record.flat?.attributes?.manufacturer;
  return <Typography variant="body2">{manufacturer ? manufacturer.value : ''}</Typography>;
};

export const QuickFilter = ({ label }) => {
  const translate = useTranslate();
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={translate(label)} />;
};

export const productFilter = [
  <SearchInput source="q" alwaysOn />,
  <CategoryReferenceInput source="catalogCategory" />,
  <QuickFilter source="hidden" label="Сховані" defaultValue={true} />,
  <QuickFilter source="withStock" label="В наявності" defaultValue={true} />,
  <QuickFilter source="withTransit" label="Має транзит" defaultValue={true} />,
  <QuickFilter source="withStockIssues" label="Проблемні пропозиції" defaultValue={true} />,
  <QuickFilter source="showOnMainPage" label="Для головної" defaultValue={true} />,
  <NumberInput source="inPackageCount" />,
];

const ProductBulkActionButtons = () => (
  <>
    <ShowButton />
    <HideButton />
  </>
);

const StockField = (props) => {
  const { source } = props;
  const record = useRecordContext();
  const sourceData = record[source] || [];
  return (
    <div style={{ display: 'inline-flex', gap: 10, flexWrap: 'wrap' }} key="stock-column">
      {Array.isArray(sourceData)
        ? sourceData.map((stockItem, index) => {
            const { inStock = 0, type, offerRule } = stockItem || {};
            switch (type) {
              case 'transit':
                return (
                  <Link
                    to={`/sales/offers/transit-offers/${offerRule}/show`}
                    key={index}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Chip
                      label={inStock}
                      clickable={true}
                      sx={{
                        root: {
                          backgroundColor: 'orange',
                          color: 'black',
                        },
                      }}
                    />
                  </Link>
                );
              default:
                return <Chip label={inStock} key={index} />;
            }
          })
        : ''}
    </div>
  );
};

export const ProductList = (props) => {
  return (
    <CustomList {...props} filters={productFilter}>
      <NewTabLinkDataGrid
        rowClick="edit"
        bulkActionButtons={<ProductBulkActionButtons />}
        // expand={<ProductPanel />}
      >
        <TextField source="name" />
        <ManufacturerField source="manufacturer" label="Виробник" />
        <TextField source="code" />
        <ThumbnailField label="resources.products.fields.images" />
        <BooleanColoredField source="hidden" />
        <BooleanColoredField source="showOnMainPage" />
        <ReferenceField reference="system/product-types" source="productType">
          <TextField source="name" />
        </ReferenceField>
        <CategoryReferenceField label="Категория" />
        <CategoryReferenceField
          source="catalogCategory1C"
          reference="catalog/categories-1c"
          label="Категория 1C"
        />
        {/*<ArrayField source="stock">
          <SingleFieldList>
            <ChipField source="inStock" />
          </SingleFieldList>
        </ArrayField>*/}
        <StockField source="stock" label="На складах" />
        <StockField source="transits" label="В транзиті" />
        <TextField source="fullName" />
        <TextField source="alias" />
        <NumberField source="inPackageCount" />
        <LocalDateField source="createdAt" showTime />
        <LocalDateField source="updatedAt" showTime />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};

export default ProductList;
