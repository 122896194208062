import React from 'react';
import { TextField, Labeled, ReferenceField, NumberField, useRecordContext } from 'react-admin';
import { Card, CardContent, Grid, Box } from '@mui/material';
import { useStyles } from '../styles';
import FormToolbar from '../../../common/FormToolbar';
import { PageTitle } from '../../../common/PageTitle';

export const PaymentView = props => {
  const classes = useStyles();
  const record = useRecordContext();

  return (
    <Box maxWidth="70em">
      <Card>
        <PageTitle record={record} titleText={`Платеж #${record.paymentId}`} />
        <CardContent className={classes.cardContent}>
          <Grid container>
            <Grid item sm={12} md={3}>
              <Labeled source="code" resource="sales/payments">
                <TextField source="code" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={2}>
              <Labeled source="name" resource="sales/payments">
                <TextField source="name" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={2}>
              <Labeled source="alias" resource="sales/payments">
                <TextField source="alias" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={2}>
              <Labeled source="type" resource="sales/payments">
                <TextField source="type" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={1}>
              <Labeled source="docType" resource="sales/payments">
                <TextField source="docType" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={2}>
              <Labeled source="status" resource="sales/payments">
                <TextField source="status" record={record} />
              </Labeled>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={12} md={3}>
              <Labeled source="amount" resource="sales/payments">
                <TextField source="amount" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={2}>
              <Labeled source="currencyCode" resource="sales/payments">
                <TextField source="currencyCode" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={2}>
              <Labeled source="syncAttempts" resource="sales/payments">
                <NumberField source="syncAttempts" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={2}>
              <Labeled source="currency.name" resource="sales/payments">
                <TextField source="currency.name" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={1} />
            <Grid item sm={12} md={2}>
              <ReferenceField reference="clients" source="client">
                <Labeled label="Клиент">
                  <TextField source="profile.businessName" />
                </Labeled>
              </ReferenceField>
            </Grid>
          </Grid>
          <Labeled source="description" resource="sales/payments">
            <TextField source="description" record={record} />
          </Labeled>
        </CardContent>
        <FormToolbar {...props} withoutSave withoutReset withExport />
      </Card>
    </Box>
  );
};
