import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  tabContainer: {
    maxWidth: 600,
    display: 'block',
  },
  tabTableRoot: {
    '& > .MuiToolbar-root': {
      marginRight: 80,
    },
  },
  tabTableContent: {
    boxShadow: 'none',
  },
  first_name: { display: 'inline-block' },
  last_name: { display: 'inline-block', marginLeft: 32 },
  email: { width: 544 },
  address: { maxWidth: 544 },
  city: { display: 'inline-block' },
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  files_field: {
    margin: '5px 0',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    '& button': {
      padding: 0,
    },
  },
  flexStyles: {
    display: 'inline-flex',
  },
  marginLeft: {
    marginLeft: '30%',
  },
  boxInput: {
    display: 'block',
  },
});
