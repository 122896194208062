import React from 'react';
import { Create } from 'react-admin';
import PriceTypeForm from './PriceTypeForm';

const PriceTypeCreate = props => {
  return (
    <Create redirect="list" {...props}>
      <PriceTypeForm />
    </Create>
  );
};

export default PriceTypeCreate;
