import MerchantList from './MerchantList';
import MerchantCreate from './MerchantCreate';
import MerchantEdit from './MerchantEdit';
import Icon from '@mui/icons-material/LocalAtm';

export default {
  list: MerchantList,
  create: MerchantCreate,
  edit: MerchantEdit,
  icon: Icon,
};
