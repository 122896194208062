import React from 'react';
import OutletSystemForm, { transform } from './OutletSystemForm';
import { CustomEdit } from '../../common/CustomEdit';

const OutletsSystemEdit = props => (
  <CustomEdit {...props} transform={transform}>
    <OutletSystemForm />
  </CustomEdit>
);

export default OutletsSystemEdit;
