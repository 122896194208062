import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  cardContent: {
    paddingTop: 0,
  },
  clientBox: {
    display: 'flex',
    alignItems: 'center',
  },
  inputsContainer: {
    marginTop: 40,

    '& > *': {
      marginRight: 30,
    },
  },
  itemMargin: {
    marginRight: 100,
  },
  table: {
    width: '100%',
  },
  field: {
    justifyContent: 'flex-end',
  },
});
