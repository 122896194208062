import React from 'react';
import { CustomEdit } from '../../../common/CustomEdit';
import PriceTypeForm from './PriceTypeForm';

const PriceTypeEdit = props => (
  <CustomEdit redirect="list" {...props}>
    <PriceTypeForm />
  </CustomEdit>
);

export default PriceTypeEdit;
