import TuneIcon from '@mui/icons-material/Tune';
import { SettingsList } from './SettingsList';
import { SettingsCreate } from './SettingsCreate';
import { SettingsEdit } from './SettingsEdit';

export const Settings = {
  icon: TuneIcon,
  list: SettingsList,
  create: SettingsCreate,
  edit: SettingsEdit,
};
