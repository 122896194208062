export function transformWarehouse(formData) {
  if (!formData.selfPickUp) {
    formData.selfPickUpTimeSlots = [];
  }

  if (formData.selfPickUp) {
    if (Array.isArray(formData.selfPickUpTimeSlots)) {
      formData.selfPickUpTimeSlots = formData.selfPickUpTimeSlots.sort();
    } else {
      console.warn('selfPickUpTimeSlots is not an array:', formData.selfPickUpTimeSlots);
    }
  }
  return formData;
}