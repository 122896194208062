import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './components/pages/configuration/Configuration';
import CategoryTreeWrapper from './components/pages/catalog/categories/CategoryTreeWrapper';
import CategoryProducts from './components/pages/catalog/categoryProducts/CategoryProducts';
import ProductsToCategories from './components/pages/catalog/productsToCategories/ProductsToCategories';
import { SystemConfigs } from './components/pages/system/Configs';

export default [
  <Route key="/configuration" exact path="/configuration" element={<Configuration />} />,
  <Route key="/system/configs" path="/system/configs" element={<SystemConfigs />} />,
  <Route
    key="/catalog/category/:productType"
    path="/catalog/category/:productType/products/:id"
    element={<CategoryProducts />}
  />,
  <Route
    key="/catalog/products-to-categories"
    path="/catalog/products-to-categories"
    element={<ProductsToCategories />}
  />,
  <Route
    key="/catalog/productType/:type"
    path="/catalog/productType/:type/*"
    element={<CategoryTreeWrapper />}
  />,
];
