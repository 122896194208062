import React from 'react';
import { TextField } from 'react-admin';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import LocalDateField from '../../../fields/LocalDateField';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const CashRegisterList = props => {
  return (
    <CustomList {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="code" />
        <TextField source="name" />
        <BooleanColoredField source="isActive" />
        <LocalDateField source="createdAt" showTime />
        <LocalDateField source="updatedAt" showTime />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};

export default CashRegisterList;
