import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomCreate } from '../../common/CustomCreate';
import { SelfPickupTimesForm, transform } from './SelfPickupTimesForm';

const Title = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? (
    <span>{translate('resources.sales/shipping/self-pickup-times.name')}</span>
  ) : null;
};

export const Create = props => (
  <CustomCreate title={<Title />} component="div" transform={transform} {...props}>
    <Form>
      <SelfPickupTimesForm />
    </Form>
  </CustomCreate>
);
