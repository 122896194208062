import React from 'react';
import { SearchInput } from 'react-admin';
import { CustomList } from '../../../common/CustomList';
import { ClientDiscountTable } from './components/ClientDiscountTable';

const clientDiscountFilters = [<SearchInput source="q" alwaysOn />];

export const ClientDiscountList = props => (
  <CustomList filters={clientDiscountFilters} {...props}>
    <ClientDiscountTable />
  </CustomList>
);
