import React from 'react';
import { BooleanInput, NumberInput, TextField, useRecordContext } from 'react-admin';
import { Card, CardContent, Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import FormToolbar from '../../common/FormToolbar';
import { PageTitle } from '../../common/PageTitle';

export function transform({ hoursTo, minutesTo, ...rest }) {
  hoursTo = hoursTo < 10 ? `0${hoursTo}` : hoursTo;
  minutesTo = minutesTo < 10 ? `0${minutesTo}` : minutesTo;

  return {
    ...rest,
    hoursTo,
    minutesTo,
    name: `${hoursTo}:${minutesTo}`,
    code: `${hoursTo}:${minutesTo}`,
    alias: `${hoursTo}-${minutesTo}`,
  };
}

export const SelfPickupTimesForm = formProps => {
  const classes = makeStyles({});
  const record = useRecordContext();

  return (
    <Box maxWidth="70em">
      <Card>
        <PageTitle record={record} titleText={`Временной промежуток ${record.name}`} />
        <CardContent className={classes.cardContent}>
          <Grid container spacing={5}>
            <Grid item sm={12} md={4}>
              <NumberInput label="Година" source="hoursTo" record={record} />
            </Grid>
            <Grid item sm={12} md={4}>
              <NumberInput label="Хвилина" source="minutesTo" record={record} />
            </Grid>
            <Grid item sm={12} md={4}>
              <TextField label="Источник" source="origin" record={record} />
            </Grid>
            <Grid item sm={12} md={4}>
              <BooleanInput label="Активный" source="active" record={record} />
            </Grid>
          </Grid>
        </CardContent>
        <FormToolbar {...formProps} />
      </Card>
    </Box>
  );
};
