import { Create } from 'react-admin';
import React from 'react';
import OutletSystemForm, { transform } from './OutletSystemForm';


const OutletsSystemCreate = (props) => (
  <Create {...props} transform={transform}>
    <OutletSystemForm />
  </Create>
);

export default OutletsSystemCreate;