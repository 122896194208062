import React, { useState } from 'react';
import {
  TextInput,
  BooleanInput,
  SelectArrayInput,
  ReferenceArrayInput,
  useTranslate,
  SelectInput,
  useGetList,
  useRecordContext,
} from 'react-admin';
import { Card, CardContent, Grid, Box, Tabs, Tab } from '@mui/material';
import FormToolbar from '../../../common/FormToolbar';
import { PageTitle } from '../../../common/PageTitle';
import { useStyles } from './styles';
import DeliverySchedule from './DeliverySchedule';

const WarehouseForm = (formProps) => {
  const translate = useTranslate();
  const [activeTab, setActiveTab] = useState('0');
  const classes = useStyles();
  const record = useRecordContext();
  const [hasSelfPickUp, setHasSelfPickUp] = useState(record.selfPickUp);

  const { data: suppliersChoices, isLoading: suppliersLoading } = useGetList('system/suppliers', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'name', order: 'ASC' },
    filter: {},
  });

  const title = `${translate('resources.system/warehouses.title')} ${record.displayName || ''}`;
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <Box maxWidth="70em">
      <Card>
        <PageTitle record={record} titleText={title} />
        <CardContent>
          <Tabs
            variant="fullWidth"
            centered
            value={activeTab}
            indicatorColor="primary"
            onChange={handleChange}
          >
            <Tab className={classes.tab} label="Основная информация" value="0" />
            <Tab className={classes.tab} label="Периоды точной доставки" value="1" />
          </Tabs>
          {activeTab === '0' && (
            <Grid container spacing={5}>
              <Grid item sm={12} md={3}>
                {/* <Labeled source="alias" resource="system/warehouses">
              </Labeled> */}
                <TextInput
                  resource="system/warehouses"
                  source="alias"
                  record={record}
                  className={classes.boxInput}
                  fullWidth
                />
                <TextInput
                  resource="system/warehouses"
                  source="code"
                  record={record}
                  className={classes.boxInput}
                  fullWidth
                />
                <TextInput
                  resource="system/warehouses"
                  source="name"
                  record={record}
                  className={classes.boxInput}
                  fullWidth
                />
                <TextInput
                  resource="system/warehouses"
                  source="displayName"
                  record={record}
                  className={classes.boxInput}
                  fullWidth
                />
              </Grid>
              <Grid item sm={12} md={1} />
              <Grid item sm={12} md={4}>
                <SelectInput
                  choices={suppliersChoices}
                  optionText="displayName"
                  optionValue="code"
                  label="resources.system/warehouses.fields.supplier"
                  loading={suppliersLoading}
                  source="supplier"
                />
                <ReferenceArrayInput
                  label="resources.system/warehouses.fields.outlets"
                  reference="system/outlets"
                  source="outlets"
                  fullWidth
                >
                  <SelectArrayInput source="name" />
                </ReferenceArrayInput>
              </Grid>
              <Grid item sm={12} md={1} />
              <Grid item sm={12} md={3}>
                <BooleanInput source="deleted" className={classes.boxInput} />
                <BooleanInput source="isDefault" className={classes.boxInput} />
                <BooleanInput source="isSystem" className={classes.boxInput} />
                <BooleanInput
                  source="selfPickUp"
                  className={classes.boxInput}
                  onChange={setHasSelfPickUp}
                />
              </Grid>
            </Grid>
          )}
          {activeTab === '1' && (
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {hasSelfPickUp ? <DeliverySchedule /> : <p>Нету самовывоза</p>}
            </div>
          )}
        </CardContent>
        <FormToolbar {...formProps} />
      </Card>
    </Box>
  );
};

export default WarehouseForm;
