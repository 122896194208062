import React, { useEffect } from 'react';
import {
  TextInput,
  NumberInput,
  required,
  BooleanInput,
  DateTimeInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  FormDataConsumer,
  AutocompleteArrayInput,
  ReferenceField,
  useRecordContext,
} from 'react-admin';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent, Grid, Box } from '@mui/material';
import { useWatch } from 'react-hook-form';
import FormToolbar from '../../../../common/FormToolbar';
import { useFormContext } from 'react-hook-form';
// @todo move to separate component
import CategoriesReferenceInput from '../../../loyalty-programs/condition-discounts/components/CategoriesReferenceInput';
import { ProductRefField } from '../../../../fields/ProductRefField';
//import CategoriesReferenceInput from '../../condition-discounts/components/CategoriesReferenceInput';

function filterToQuery(searchText, categories) {
  return { q: searchText, catalogCategory: categories };
}

function optionText(record) {
  return `${record.name} - ${record.code}`;
}

export const SpecialOfferForm = () => {
  const { reset, getValues } = useFormContext();
  const record = useRecordContext();
  const qtyConditionsFormValues = useWatch({ name: 'qtyConditions' });
  const productsFormValues = useWatch({ name: 'products' });

  useEffect(() => {
    setTimeout(() => {
      const emptyConditions = [null, null, null, null];
      const prevValues = getValues();
      if (record.productsPricing && record.productsPricing.length) {
        const qtyConditions = [
          ...Object.keys(record.productsPricing[0].qtyPriceConditions || {}),
          ...emptyConditions,
        ].slice(0, 4);
        const conditions = record.productsPricing.reduce(
          (acc, item, idx) => ({
            ...acc,
            [item.product]: Object.values(record.productsPricing[idx].qtyPriceConditions || {}),
          }),
          {}
        );
        const products = record.productsPricing.map((p) => p.product);

        reset({
          ...prevValues,
          products: products,
          qtyConditions: qtyConditions,
          conditions: conditions,
          supplier: typeof record.supplier === 'string' ? record.supplier : record.supplier.id,
          warehouses: record.warehouses,
        });
      } else {
        reset({
          ...prevValues,
          products: [],
          qtyConditions: emptyConditions,
          conditions: {},
          supplier: '',
          warehouses: '',
        });
      }
    }, 10);
  }, []);

  return (
    <Box maxWidth="70em">
      <Card>
        <CardContent>
          <Grid container spacing={8}>
            <Grid item sm={12} md={4}>
              <BooleanInput
                label="resources.sales/discounts/rules/balance.fields.isActive"
                source="isActive"
                record={record}
                fullWidth
              />
              <TextInput
                label="resources.sales/discounts/rules/balance.fields.name"
                source="name"
                validate={[required()]}
                fullWidth
              />
              <DateTimeInput
                source="startDate"
                label="resources.sales/discounts/rules/website.fields.startDate"
                validate={[required()]}
                fullWidth
              />
              <DateTimeInput
                source="endDate"
                label="resources.sales/discounts/rules/website.fields.endDate"
                validate={[required()]}
                fullWidth
              />
              <ReferenceInput
                source="supplier"
                reference="system/suppliers"
                sort={{ field: 'name', order: 'ASC' }}
                label="Поставщик"
                fullWidth
              >
                <SelectInput optionText="name" validate={[required()]} />
              </ReferenceInput>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData && formData.supplier ? (
                    <ReferenceArrayInput
                      source="warehouses"
                      reference="system/warehouses"
                      sort={{ field: 'name', order: 'ASC' }}
                      label="Склад"
                      validate={[required()]}
                      fullWidth
                      {...rest}
                    >
                      <SelectArrayInput optionText={optionText} />
                    </ReferenceArrayInput>
                  ) : null
                }
              </FormDataConsumer>
            </Grid>
            <Grid item sm={12} md={8}>
              <ReferenceArrayInput
                source="productType"
                reference="system/product-types"
                sort={{ field: 'name', order: 'ASC' }}
                label="Тип 1С"
                fullWidth
              >
                <SelectArrayInput optionText="name" />
              </ReferenceArrayInput>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData ? (
                    <CategoriesReferenceInput
                      productType={formData.productType}
                      fullWidth
                      perPage={999}
                      {...rest}
                    />
                  ) : null
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData ? (
                    <ReferenceArrayInput
                      label="Продукты"
                      source="products"
                      perPage={9999}
                      reference="catalog/products"
                      filterToQuery={(q) => filterToQuery(q, formData.categories)}
                      fullWidth
                      {...rest}
                    >
                      <AutocompleteArrayInput optionText="name" />
                    </ReferenceArrayInput>
                  ) : null
                }
              </FormDataConsumer>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="35%" component="th">
                    Имя
                  </TableCell>
                  {qtyConditionsFormValues
                    ? qtyConditionsFormValues.map((qc, idx) => (
                        <TableCell key={`qty-cond-${idx}`}>
                          <NumberInput label="Кол-во" source={`qtyConditions.${idx}`} />
                        </TableCell>
                      ))
                    : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {productsFormValues && productsFormValues.length
                  ? productsFormValues.map((product) => {
                      return (
                        <TableRow key={`cart-item-${product}`}>
                          <TableCell width="35%">
                            <ReferenceField
                              record={{ id: product }}
                              source="id"
                              reference="catalog/products"
                            >
                              <ProductRefField />
                            </ReferenceField>
                          </TableCell>
                          {qtyConditionsFormValues &&
                            qtyConditionsFormValues.map((_, idx) => (
                              <TableCell key={`price-cond-${idx}`}>
                                <NumberInput label="Цена" source={`conditions.${product}.${idx}`} />
                              </TableCell>
                            ))}
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <FormToolbar />
      </Card>
    </Box>
  );
};
