import React from 'react';
import {
  DateInput,
  SearchInput,
  SelectInput,
  BooleanInput,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin';
import { filterToQuery } from '../home-page/offer/OfferCreate';
import { CustomList } from '../../common/CustomList';
import { PaymentsTable } from './PaymentsTable';

const TYPE_CASH = 'cash';
const TYPE_LIQPAY = 'liqpay';
const PAYMENT_TYPES = [TYPE_CASH, TYPE_LIQPAY];

const STATUS_PENDING = 'pending';
const STATUS_PAYED = 'payed';
const STATUS_PROCESSED = 'processed';
const STATUS_CANCELED = 'canceled';
const STATUS_FAILED = 'failed';
const STATUS_REFUND = 'refund';
const STATUSES = [
  STATUS_PENDING,
  STATUS_PAYED,
  STATUS_PROCESSED,
  STATUS_CANCELED,
  STATUS_FAILED,
  STATUS_REFUND,
];

const paymentsFilter = [
  <SearchInput source="q" alwaysOn placeholder={'Поиск'} />,
  <ReferenceInput
    label="Клиент"
    reference="clients"
    source={'client'}
    filterToQuery={filterToQuery}
  >
    <AutocompleteInput optionText="username" />
  </ReferenceInput>,
  <SearchInput source="code" placeholder={'resources.sales/payments.fields.code'} />,
  <SelectInput
    source="status"
    placeholder={'resources.sales/payments.fields.status'}
    choices={STATUSES.map(status => ({ id: status, name: status }))}
  />,
  <SelectInput
    source="type"
    placeholder={'resources.sales/payments.fields.type'}
    choices={PAYMENT_TYPES.map(status => ({ id: status, name: status }))}
  />,
  <BooleanInput source="isSynced" placeholder={'resources.sales/payments.fields.isSynced'} />,
  <DateInput source="createdAt" label={'Дата'} />,
];

const PaymentsList = props => {
  return (
    <CustomList filters={paymentsFilter} {...props}>
      <PaymentsTable />
    </CustomList>
  );
};

export default PaymentsList;
