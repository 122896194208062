import React from 'react';
import { Form, Show, useRecordContext, useTranslate } from 'react-admin';
import { PaymentView } from './components/PaymentView';

const PaymentTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? (
    <span>
      {translate('resources.sales/payments.nameOne')}
      &nbsp;{record.paymentId}
    </span>
  ) : null;
};

const PaymentsView = () => {
  return (
    <Show title={<PaymentTitle />} component="div">
      <Form>
        <PaymentView />
      </Form>
    </Show>
  );
};

export default PaymentsView;
