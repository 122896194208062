import React from 'react';
import {
  TextInput,
  SimpleForm,
  required,
  BooleanInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
  useRefresh,
  useRedirect,
  FormDataConsumer,
} from 'react-admin';
import FormToolbar from '../../../common/FormToolbar';
import AttributesOrder from '../../../common/AttributesOrder';
import { CustomEdit } from '../../../common/CustomEdit';

function transform(data) {
  // * exclude products, children to prevent unpredictable behavior
  const { products, children, ...rest } = data;
  return rest;
}

const CategoryEdit = props => {
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    redirect(props.basePath);
    refresh();
  };

  const canUseAutoHide = formData => !formData.parent;
  const isAutoHidePeriodDisabled = formData => !formData.autoHideProducts;

  return (
    <CustomEdit mutationOptions={{ onSuccess }} transform={transform} {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />}>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="alias" />
        <ReferenceInput source={'productType'} reference="system/product-types" {...props}>
          <SelectInput optionText="name" />
          <span>Auto-update on catalog category change</span>
        </ReferenceInput>
        <BooleanInput source="isMainMenu" />
        <FormDataConsumer>
          {({ formData }) =>
            canUseAutoHide(formData) ? (
              <>
                <BooleanInput source="autoHideProducts" />
                <NumberInput
                  source="autoHideProductsPeriod"
                  min={0}
                  max={90}
                  disabled={isAutoHidePeriodDisabled(formData)}
                />
              </>
            ) : null
          }
        </FormDataConsumer>
        <BooleanInput source="hidden" />
        <BooleanInput source="firstLevel" />
        <AttributesOrder source="attributes" updateRoute="catalog/categories" />
      </SimpleForm>
    </CustomEdit>
  );
};

export default CategoryEdit;
