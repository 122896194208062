import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomEdit } from '../../../common/CustomEdit';
import { transformWarehouse } from './transformWarehouse';
import WarehouseForm from './WarehouseForm';

const WarehouseTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.system/warehouses.title')} {(record && record.displayName) || ''}
    </span>
  );
};

const WarehouseEdit = props => (
  <CustomEdit component="div" title={<WarehouseTitle />} transform={transformWarehouse} {...props}>
    <Form>
      <WarehouseForm />
    </Form>
  </CustomEdit>
);

export default WarehouseEdit;
