import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';
import grey from '@mui/material/colors/grey';
import brown from '@mui/material/colors/brown';
import {
  UserRegistrationStatusOptions,
  USER_REG_STATUS_DOC_REQUIRED,
  USER_REG_STATUS_APPROVED,
  USER_REG_STATUS_NO_REG,
  USER_REG_STATUS_PENDING,
  USER_REG_STATUS_DECLINED,
} from '../models/User';
import { useRecordContext } from 'react-admin';

const useStyles = makeStyles({
  container: {
    whiteSpace: 'nowrap',
  },
  item: {
    display: 'inline-block',
    marginRight: '5px',
    width: 10,
    height: 10,
    borderRadius: '50%',
  },
  bgBrown: {
    backgroundColor: brown[500],
  },
  bgRed: {
    backgroundColor: red[500],
  },
  bgGreen: {
    backgroundColor: green[500],
  },
  bgOrange: {
    backgroundColor: orange[500],
  },
  bgGrey: {
    backgroundColor: grey[500],
  },
});

const OrderStatusField = () => {
  const record = useRecordContext();
  const classes = useStyles();

  return (
    <span className={classes.container}>
      <span
        className={classnames(classes.item, {
          [classes.bgBrown]: record.registrationStatus === USER_REG_STATUS_DECLINED,
          [classes.bgRed]: record.registrationStatus === USER_REG_STATUS_DOC_REQUIRED,
          [classes.bgGreen]: record.registrationStatus === USER_REG_STATUS_APPROVED,
          [classes.bgGrey]: record.registrationStatus === USER_REG_STATUS_NO_REG,
          [classes.bgOrange]: record.registrationStatus === USER_REG_STATUS_PENDING,
        })}
      />
      {UserRegistrationStatusOptions[record.registrationStatus]}
    </span>
  );
};

OrderStatusField.defaultProps = {
  source: 'registrationStatus',
  label: 'resources.users.fields.registrationStatus',
};

export default OrderStatusField;
