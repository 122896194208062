import React from 'react';
import { TextField, ReferenceField } from 'react-admin';
import { RedirectField } from '../../fields/RedirectField';
import { NewTabLinkDataGrid } from '../../common/NewTabLinkDataGrid';
import LocalDateField from '../../fields/LocalDateField';

export const OutletsTable = props => (
  <NewTabLinkDataGrid rowClick="edit" {...props}>
    <TextField source="code" />
    <RedirectField
      source="client.businessName"
      linkIdSource="client.id"
      linkUrl="clients"
      label="Бизнес имя"
    />
    <TextField source="name" />
    <TextField source="region" />
    <TextField source="city" />
    <TextField source="street" />
    <TextField source="building" />
    <TextField source="latitude" />
    <TextField source="longitude" />
    <ReferenceField reference="sales/shipping/locations" source="location">
      <TextField source="name" />
    </ReferenceField>
    <LocalDateField source="createdAt" showTime />
    <LocalDateField source="updatedAt" showTime />
  </NewTabLinkDataGrid>
);
