import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import { getFlatDataFromTree } from '@nosferatu500/react-sortable-tree';
import { CategoryTree } from './CategoryTree';

const CategoryTreeWrapper = (props) => {
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const navigate = useNavigate();
  const { type } = useParams();

  const removeNode = (node) => {
    const { title, id } = node;
    if (window.confirm(`Ви дійсно бажаєте видалити категорію "${title}" та всі її підкатегорії?`)) {
      dataProvider
        .delete(
          `catalog/categories`,
          { id, previousData: node },
          {
            onSuccess: {
              redirect: 'categories',
            },
            onFailure: {
              notification: { body: 'Error: tree not updated', level: 'warning' },
            },
          }
        )
        .then(() => {
          refresh();
        });
    }
  };

  const saveData = (data) => {
    const flatData = getFlatDataFromTree({
      getNodeKey: (item) => item.id,
      treeData: data,
      ignoreCollapsed: false,
    }).map((nodeInfo) => {
      const item = nodeInfo.node;
      return {
        id: item.id,
        sortOrder: item.sortOrder,
        parent: item.parentId,
        name: item.title,
      };
    });

    return dataProvider
      .update('catalog/productType', { id: type, data: flatData })
      .then(() => {
        notify('Дерево успішно оновлено', { type: 'success' });
        refresh();
      })
      .catch(() => notify('Помилка оновлення дерева категорії', { type: 'warning' }));
  };

  const editNode = (id) => {
    navigate(`/catalog/productType/${type}/${id}/edit`);
  };

  const createNode = () => {
    navigate(`/catalog/productType/${type}/create`);
  };

  const handleClose = () => {
    navigate(`/catalog/productType/${type}`);
  };

  return (
    <CategoryTree
      createNode={createNode}
      editNode={editNode}
      saveData={saveData}
      removeNode={removeNode}
      handleClose={handleClose}
    />
  );
};

export default CategoryTreeWrapper;
