import React from 'react';
import { Typography } from '@mui/material';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { CustomList } from '../../../common/CustomList';
import { RESULT_TYPES_NAMES } from './components/SearchPreferencesForm';
import LocalDateField from '../../../fields/LocalDateField';
import { useRecordContext } from 'react-admin';

const ResultsOrderField = () => {
  const record = useRecordContext();
  return record.resultsOrder ? (
    <Typography variant="body1">
      {Object.values(record.resultsOrder)
        .map(r => RESULT_TYPES_NAMES[r])
        .join(', ')}
    </Typography>
  ) : null;
};

export const SearchPreferencesList = props => {
  return (
    <CustomList {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <ResultsOrderField source="resultsOrder" />
        <LocalDateField source="updatedAt" showTime />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};
