import UserIcon from '@mui/icons-material/People';

import { ClientList } from './ClientList';
import ClientCreate from './ClientCreate';
import ClientEdit from './ClientEdit';

export default {
  list: ClientList,
  create: ClientCreate,
  edit: ClientEdit,
  icon: UserIcon,
};
