import React from 'react';
import { ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

const CategoriesReferenceInput = ({ productType, ...rest }) => (
  <ReferenceArrayInput
    allowEmpty
    reference="catalog/productType/all"
    filter={{ productType: productType }}
    sort={{ field: 'name', order: 'ASC' }}
    {...rest}
  >
    <AutocompleteArrayInput optionText="name" />
  </ReferenceArrayInput>
);

CategoriesReferenceInput.defaultProps = {
  source: 'categories',
  label: 'resources.sales/discounts/rules/progressive.fields.categories',
};

export default CategoriesReferenceInput;
