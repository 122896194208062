import React from 'react';
import { NumberField, useRecordContext } from 'react-admin';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { RedirectField } from '../../../fields/RedirectField';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';
import { useStyles } from '../styles';

const OrderItemsTable = () => {
  const record = useRecordContext();
  const classes = useStyles();

  const totalPriceAll =
    record.items && record.items.length ? record.items.reduce((acc, i) => acc + i.subTotal, 0) : 0;

  return (
    <Table className={classes.table} aria-label="simple table" size="small">
      <TableHead>
        <TableRow>
          <TableCell>Имя</TableCell>
          <TableCell>Код</TableCell>
          <TableCell align="right">Группа</TableCell>
          <TableCell align="right">Предзаказ</TableCell>
          <TableCell align="right">Цена за шт, грн</TableCell>
          <TableCell align="right">Количество</TableCell>
          <TableCell align="right">Скидка, %</TableCell>
          <TableCell align="right">Сумма, грн</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.items?.length
          ? record.items.map((row, index) => (
              <TableRow key={`cart-item-${row.id}`}>
                <TableCell component="th" scope="row">
                  <RedirectField
                    record={row.flatOffer?.product.fullName || {}}
                    linkIdSource={`items[${index}].flatOffer.product.id]`}
                    linkUrl="catalog/products"
                    fieldText={row.flatOffer?.product?.fullName}
                  />
                </TableCell>
                <TableCell align="left">{row?.flatOffer?.product?.code || ''}</TableCell>
                <TableCell align="right">{row.flatOffer?.product?.catalogCategory || ''}</TableCell>
                <TableCell align="right">
                  <BooleanColoredField
                    record={row.flatOffer.product}
                    source="hasPreOrder"
                    className={classes.field}
                  />
                </TableCell>
                <TableCell align="right">{row.price || ''}</TableCell>
                <TableCell align="right">{row.qty || ''}</TableCell>
                <TableCell align="right">{row.discount + row.discountAuto}</TableCell>
                <TableCell align="right">{row.subTotal}</TableCell>
              </TableRow>
            ))
          : null}
        <TableRow>
          <TableCell colSpan={5}>Всего, грн</TableCell>
          <TableCell align="right">{record.totalQty}</TableCell>
          <TableCell align="right">{record.totalDiscount} грн</TableCell>
          <TableCell align="right">{totalPriceAll}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Доставка, грн</TableCell>
          <TableCell align="right" colSpan={7}>
            <NumberField record={record} source="shippingAmount" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.boldCell}>Сумма, грн</TableCell>
          <TableCell align="right" colSpan={7}>
            <NumberField className={classes.boldCell} record={record} source="totalSum" />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default OrderItemsTable;
