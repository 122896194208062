import React from 'react';
import {
  TextInput,
  SimpleForm,
  ReferenceArrayInput,
  SelectInput,
  SelectArrayInput,
  BooleanInput,
  ReferenceInput,
} from 'react-admin';
import FormToolbar from '../../../common/FormToolbar';

const SupplierForm = props => {
  return (
    <SimpleForm style={{ width: '100%' }} {...props} toolbar={<FormToolbar />}>
      <TextInput source="code" />
      <TextInput source="displayName" />
      <TextInput source="name" />
      <TextInput source="region" />
      <BooleanInput source="isDefault" />
      <ReferenceArrayInput reference="system/price-types" source="allowedPriceTypes">
        <SelectArrayInput />
      </ReferenceArrayInput>
      <ReferenceInput reference="system/price-types" source="defaultPriceType">
        <SelectInput />
      </ReferenceInput>
      <ReferenceArrayInput reference="system/warehouses" source="allowedWarehouses">
        <SelectArrayInput />
      </ReferenceArrayInput>
      <ReferenceArrayInput reference="sales/shipping/locations/regions" source="regions">
        <SelectArrayInput />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};

export default SupplierForm;
