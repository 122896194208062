export const settingValueTypes = {
  text: 'text',
  multiText: 'multiline_text'
}

export const enabledSettings = ['maintenance', 'on-hold-flats', 'API_DEBUG', 'DB_DEBUG'];

export const booleanSettings = ['maintenance', 'on-hold-flats'];

export const settingsTabs = [{
    label: 'System',
    settings: ['API_DEBUG', 'DB_DEBUG', 'REQUEST_1C_ACCESS', 'LOG_SEARCH_QUERIES', 'FLAT_CONSOLE_LOG',
      'maintenance', 'on-hold-flats'
    ]
  }, {
    label: '1c integration',
    settings: ['ALLOW_1C_SYNC', 'SERVER_1C', 'USER_1C', 'PASS_1C', 'DB_1C', 'ORDER_PREFIX', 'CUSTOMER_PREFIX',
      'OUTLET_PREFIX', 'PAYMENT_PREFIX', 'LOCATION_PREFIX', 'AUTH_1C_USER', 'AUTH_1C_PASS',
      'VIRTUAL_CART_1C_CUSTOMER', 'VIRTUAL_CART_1C_OUTLET'
    ]
  },
  {
    label: 'Payment Systems',
    settings: ['LIQPAY_PUBLICK_KEY', 'LIQPAY_PRIVATE_KEY', 'LIQPAY_RETURN_URL', 'LIQPAY_RESULT_URL',
      'LIQPAY_CALLBACK_URL', 'LIQPAY_SANDBOX_MODE', 'SANDBOX_LIQPAY_PUBLICK_KEY', 'SANDBOX_LIQPAY_PRIVATE_KEY'
    ]
  },
  {
    label: 'Notifications',
    settings: ['VIBER_PRIVATE_TOKEN', 'TELEGRAM_BOT_TOKEN', 'TELEGRAM_GROUP_ID', 'ALPHA_SMS_SENDER_NAME',
      'ALPHA_SMS_PACKAGE_KEY', 'MAILGUN_DEBUG_BCC', 'MAILGUN_SENT_FROM', 'MAILGUN_HOST', 'MAILGUN_DOMAIN',
      'MAILGUN_PRIVATE_API_KEY'
    ]
  }
];