import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const CategoryReferenceField = (props) => (
  <ReferenceField
    source={props?.source || 'catalogCategory'}
    reference={props?.reference || 'catalog/productType/all'}
    sortBy={'catalogCategory.name'}
    link={false}
    {...props}
  >
    <TextField source="name" />
  </ReferenceField>
);

export default CategoryReferenceField;
