import React from 'react';
import { List, Datagrid, TextField, useRecordContext } from 'react-admin';
import { CustomPagination } from '../../../common/CustomPagination';
import { settingValueTypes } from './constants/settingValueTypes';

const ExpandSetting = () => {
  const record = useRecordContext();

  if (record.type === settingValueTypes.text) {
    return <div>{record.value}</div>;
  }

  if (record.type === settingValueTypes.multiText) {
    return <div dangerouslySetInnerHTML={{ __html: record.value }} />;
  }

  return <div>{record.value}</div>;
};

export const SettingsList = props => {
  function editRedirect(id, basePath, record) {
    return `${basePath}/${record.name}`;
  }

  return (
    <List pagination={<CustomPagination />} title="Settings" {...props}>
      <Datagrid rowClick={editRedirect} expand={<ExpandSetting />}>
        <TextField source="label" />
        <TextField source="name" />
        <TextField source="value" />
      </Datagrid>
    </List>
  );
};
