import CashierList from './CashierList';
import CashierCreate from './CashierCreate';
import CashierEdit from './CashierEdit';
import Icon from '@mui/icons-material/AssignmentInd';

export default {
  list: CashierList,
  create: CashierCreate,
  edit: CashierEdit,
  icon: Icon,
};
