import React from 'react';
import {
  SimpleForm,
  TextInput,
  SelectArrayInput,
  BooleanInput,
  useRecordContext,
} from 'react-admin';
import { useTranslate } from 'ra-core';
import { CustomEdit } from '../../../../common/CustomEdit';

const OnlyOptions = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <SelectArrayInput
      source="firstLevel"
      choices={(record.existValues || []).map(id => ({ id, name: id }))}
      style={{ width: 255 }}
      label={translate('resources.system/attributes.fields.firstLevel')}
    />
  );
};

const AttributesEdit = props => {
  return (
    <CustomEdit {...props}>
      <SimpleForm>
        <TextInput source="alias" />
        <TextInput source="name" />
        <TextInput source="code" />
        <BooleanInput source="primary" />
        <OnlyOptions />
      </SimpleForm>
    </CustomEdit>
  );
};

export default AttributesEdit;
