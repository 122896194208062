import React from 'react';
import { Create } from 'react-admin';
import SupplierForm from './SupplierForm';

const SupplierCreate = props => {
  return (
    <Create redirect="list" {...props}>
      <SupplierForm />
    </Create>
  );
};

export default SupplierCreate;
