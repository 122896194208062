import React from 'react';
import { Labeled, useRecordContext } from 'react-admin';
import { Card, CardContent, Grid, Typography, Box } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import FormToolbar from '../../../common/FormToolbar';
import OrderItemsTable from './OrderItems';
import { RedirectField } from '../../../fields/RedirectField';
import { useStyles } from '../styles';
import { PageTitle } from '../../../common/PageTitle';

export const ExpiredCartEditForm = formProps => {
  const classes = useStyles();
  const record = useRecordContext();

  return (
    <Box maxWidth="70em">
      <Card>
        <PageTitle record={record} titleText={`Заказ ${record.id}`} />
        <CardContent className={classes.cardContent}>
          <Grid container>
            <Labeled label="Клиент" classes={{ value: classes.clientBox }}>
              <div>
                <RedirectField
                  record={record.client}
                  source="client.name"
                  linkIdSource="client"
                  linkUrl="clients"
                />
                &nbsp;
                <PhoneIcon />
                &nbsp;
                <Typography component="span" variant="body2">
                  {record?.client?.phone}
                </Typography>
              </div>
            </Labeled>
          </Grid>
          <Typography variant="h6" gutterBottom>
            Товары
          </Typography>
          <Box>
            <OrderItemsTable record={record} />
          </Box>
        </CardContent>
        <FormToolbar {...formProps} withoutSave />
      </Card>
    </Box>
  );
};
