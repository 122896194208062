export function transformPreorderRules({
  active,
  dayMargin,
  manufacturer,
  days,
  ...rest
}) {
  return {
    active: active || false,
    manufacturer: manufacturer || '',
    dayMargin: dayMargin || 1,
    days: {
      monday: days.monday || false,
      tuesday: days.tuesday || false,
      wednesday: days.wednesday || false,
      thursday: days.thursday || false,
      friday: days.friday || false,
      saturday: days.saturday || false,
      sunday: days.sunday || false
    },
    ...rest
  };
}