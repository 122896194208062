import React, { useState } from 'react';
import { API_URI, CLIENT_URL } from '../../../consts';
import { ShowButton, useRecordContext } from 'react-admin';

export const LoginAsClient = () => {
  const record = useRecordContext();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    fetch(`${API_URI}/clients/auth/${record.id}`, {
      method: 'GET',
      headers: { Authorization: localStorage.getItem('token') },
    })
      .then(r => r.json())
      .then(t => {
        setToken(t);
      })
      .catch(e => {
        console.error(e);
        setToken('');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {!token && !loading ? (
        <ShowButton onClick={handleClick} label="Отримати посилання для логіна на клієнт" />
      ) : null}
      {token ? (
        <a
          href={`${CLIENT_URL}/login?admin_token=${token}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Увійти під клієнтом
        </a>
      ) : null}
      {loading ? <span>...loading</span> : null}
    </div>
  );
};
