import React from 'react';
import { Create } from 'react-admin';
import MerchantForm from './MerchantForm';

const MerchantCreate = props => (
  <Create redirect="list" {...props}>
    <MerchantForm />
  </Create>
);

export default MerchantCreate;
