import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import FormToolbar from '../../common/FormToolbar';

const FeatureFlagCreate = ({ classes, ...props }) => (
  <Create redirect="list" {...props}>
    <SimpleForm toolbar={<FormToolbar />}>
      <TextInput source="code" autoFocus />
      <TextInput source="name" />
      <TextInput multiline source="description" />
    </SimpleForm>
  </Create>
);

export default FeatureFlagCreate;
