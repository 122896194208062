import React from 'react';
import {
  Create,
  TextInput,
  BooleanInput,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  required,
  useRedirect,
} from 'react-admin';
import FormToolbar from '../../../common/FormToolbar';

const CategoryCreate = (props) => {
  const redirect = useRedirect();
  const formDefaultValues = {
    productType: props.productType,
  };

  const onSaved = () => {
    redirect(`/catalog/productType/${props.productType}`);
    props.refetchTree();
  };

  return (
    <Create {...props}>
      <SimpleForm toolbar={<FormToolbar onSaved={onSaved} />} defaultValues={formDefaultValues}>
        <TextInput source="name" validate={required()} />
        <TextInput source="alias" />
        <ReferenceInput source={'productType'} reference="system/product-types" {...props}>
          <SelectInput optionText="name" readOnly={Boolean(props.productType)} />
        </ReferenceInput>
        <BooleanInput source={'isMainMenu'} />
        <BooleanInput source={'hidden'} />
      </SimpleForm>
    </Create>
  );
};

export default CategoryCreate;
