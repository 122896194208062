import React from 'react';
import {
  CreateButton,
  ExportButton,
  FilterButton,
  List,
  SelectColumnsButton,
  TopToolbar,
} from 'react-admin';
import { CustomPagination } from './CustomPagination';
import classes from './styles.module.scss';

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <SelectColumnsButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const CustomList = (props) => (
  <List
    pagination={<CustomPagination />}
    perPage={25}
    sort={{ field: 'createdAt', order: 'DESC' }}
    className={classes.root}
    actions={<ListActions />}
    filters={props.filters || []}
    {...props}
  >
    {props.children}
  </List>
);
