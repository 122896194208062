import React from 'react';
import LocationsForm from './LocationsForm';
import { CustomEdit } from '../../common/CustomEdit';

const LocationsEdit = props => (
  <CustomEdit {...props}>
    <LocationsForm readonly={true} />
  </CustomEdit>
);

export default LocationsEdit;
