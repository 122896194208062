import React from 'react';
import {
  SelectInput,
  TextField,
  TextInput,
  ReferenceInput,
  Labeled,
  DateInput,
  FormDataConsumer,
  useRecordContext,
} from 'react-admin';
import { Card, CardContent, Grid, Typography, Box } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { getOrderStatusOptions } from '../../models/Order';
import FormToolbar from '../../common/FormToolbar';
import OrderItemsTable from './components/OrderItems';
import { RedirectField } from '../../fields/RedirectField';
import { PageTitle } from '../../common/PageTitle';
import OutletAddressField from '../../fields/OutletAddressField';

export const OrderEditForm = formProps => {
  const record = useRecordContext();

  return (
    <Box maxWidth="70em">
      <Card>
        <PageTitle record={record} titleText={`Заказ #${record.orderId}`} />
        <CardContent sx={{ paddingTop: '0' }}>
          <Grid container>
            <Grid item sm={12} md={4}>
              <Labeled source="code" resource="sales/orders">
                <TextField source="code" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={4}>
              <Labeled label="Клиент" sx={{ display: 'block' }}>
                <>
                  <RedirectField
                    record={record?.client.name}
                    source="client.name"
                    linkIdSource="client.id"
                    linkUrl="clients"
                  />
                  &nbsp;
                  <PhoneIcon />
                  &nbsp;
                  <Typography component="span" variant="body2">
                    {record?.client?.phone}
                  </Typography>
                </>
              </Labeled>
            </Grid>
            <Grid item sm={12} md={4}>
              <Labeled source="address" resource="sales/orders">
                <OutletAddressField record={record} />
              </Labeled>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={12} md={4}>
              <Labeled source="docType" resource="sales/orders" sx={{ marginRight: '100px' }}>
                <TextField source="docType" record={record} />
              </Labeled>
              <Labeled source="orderType" resource="sales/orders">
                <TextField source="orderType" record={record} />
              </Labeled>
            </Grid>
            <Grid item sm={12} md={4}>
              <Labeled label="Склад">
                <>
                  <RedirectField
                    record={record?.warehouse}
                    source="displayName"
                    linkIdSource="id"
                    linkUrl="system/warehouses"
                  />
                  &nbsp;&nbsp;
                  <TextField source="code" record={record?.warehouse} />
                </>
              </Labeled>
            </Grid>
            <Grid item sm={12} md={4}>
              <Labeled source="notes" resource="sales/orders">
                <TextField emptyText="-" source="notes" record={record} />
              </Labeled>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 5, '& > div': { mr: 4, mt: 0 } }}>
            <ReferenceInput
              resource="sales/orders"
              source="deliveryMethod"
              reference="sales/shipping/methods"
              record={record}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <DateInput resource="sales/orders" source="deliveryDate" record={record} />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                const source = formData?.selfPickUpTime
                  ? 'selfPickUpTime.name'
                  : 'deliveryTime.name';
                return <TextInput resource="sales/orders" source={source} {...rest} />;
              }}
            </FormDataConsumer>
            <SelectInput
              resource="sales/orders"
              source="orderStatus"
              choices={getOrderStatusOptions()}
              record={record}
            />
          </Grid>
          <Typography variant="h6" gutterBottom>
            Товары
          </Typography>
          <Box>
            <OrderItemsTable record={record} />
          </Box>
        </CardContent>
        <FormToolbar {...formProps} withExport />
      </Card>
    </Box>
  );
};
