import React from 'react';
import { SearchInput } from 'react-admin';
import { CustomList } from '../../../common/CustomList';
import { ConditionDiscountsTable } from './components/ConditionDiscountsTable';

const conditionDiscountsFilters = [<SearchInput source="q" alwaysOn />];

export const ConditionDiscountsList = props => (
  <CustomList filters={conditionDiscountsFilters} {...props}>
    <ConditionDiscountsTable />
  </CustomList>
);
