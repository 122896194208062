import React from 'react';
import { Create } from 'react-admin';
import CashRegisterForm from './CashRegisterForm';

const CashRegisterCreate = props => (
  <Create redirect="list" {...props}>
    <CashRegisterForm />
  </Create>
);

export default CashRegisterCreate;
