import React from 'react';
import { TextInput, TextField } from 'react-admin';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const seoSettingsListFilter = [<TextInput source="q" alwaysOn label="Поиск" />];

export const SeoSettingsList = props => {
  return (
    <CustomList filters={seoSettingsListFilter} {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="name" />
        <TextField source="url" />
        <BooleanColoredField source="active" />
        <TextField source="metaTitle" />
        <TextField source="metaDesc" />
        <TextField source="metaTagsH1" />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};
