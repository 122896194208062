import React from 'react';
import { TextField, SearchInput } from 'react-admin';
import { NewTabLinkDataGrid } from '../../common/NewTabLinkDataGrid';
import { CustomList } from '../../common/CustomList';
import { BooleanColoredField } from '../../fields/BooleanColoredField';

const quickFilter = [<SearchInput source="q" alwaysOn />];

export const List = props => (
  <CustomList filters={quickFilter} {...props}>
    <NewTabLinkDataGrid rowClick="edit">
      <TextField source="name" />
      <BooleanColoredField source="active" />
      <TextField source="alias" />
      <TextField source="origin" />
    </NewTabLinkDataGrid>
  </CustomList>
);
