export const USER_REG_STATUS_DECLINED = -1;
export const USER_REG_STATUS_NO_REG = 0;
export const USER_REG_STATUS_DOC_REQUIRED = 1;
export const USER_REG_STATUS_PENDING = 2;
export const USER_REG_STATUS_APPROVED = 3;

export const UserRegistrationStatusOptions = {
  [USER_REG_STATUS_DECLINED]: "Отклонен",
  [USER_REG_STATUS_NO_REG]: "Без регистрации",
  [USER_REG_STATUS_DOC_REQUIRED]: "Нужны документы",
  [USER_REG_STATUS_PENDING]: "Ожидает проверки",
  [USER_REG_STATUS_APPROVED]: "Подтвержден"
}

export const getUserRegistrationStatusOptions = (status) => {
  const options = [];
  for (const [key, value] of Object.entries(UserRegistrationStatusOptions)) {
    options.push({
      id: key,
      name: value,
      // TODO uncomment when we have permission for admins
      // disabled: Number(key) === USER_REG_STATUS_NO_REG && status !== USER_REG_STATUS_NO_REG
      disabled: Number(key) === USER_REG_STATUS_NO_REG
    });
  }
  return options;
}

export const registrationStatusOptions = Object.entries(UserRegistrationStatusOptions).map((i) => ({
  id: i[0],
  name: i[1]
}));
