import React from 'react';
import { ReferenceArrayInput, SelectArrayInput, BooleanInput, useTranslate } from 'react-admin';

const DeliverySchedule = () => {
  const translate = useTranslate();

  return ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'].map(day => (
      <div style={{display: 'flex', width: '100%', alignItems: 'center'}} key={day}>
        <BooleanInput source={`delivery.${day}.hasDelivery`} label={''} helperText={false} />

        <div style={{marginRight: 30, minWidth: 120}}>
          {translate(`resources.sales/shipping/locations.days.${day}`)}
        </div>
          <ReferenceArrayInput
            source={`delivery.${day}.deliveryTimes`}
            reference="sales/shipping/delivery-times"
            style={{flexGrow: 1, minHeight: 65}}
          >
            <SelectArrayInput style={{maxWidth: 400, width: '100%'}} label={'Периоды доставки'}/>
          </ReferenceArrayInput>

      </div>
  ));
};

export default DeliverySchedule;