import StaticPagesForm, { transform } from './StaticPagesForm';
import { CustomCreate } from '../../../common/CustomCreate';

const StaticPagesCreate = (props) => {
  return (
    <CustomCreate {...props} transform={transform}>
      <StaticPagesForm />
    </CustomCreate>
  );
};

export default StaticPagesCreate;