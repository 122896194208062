import React from 'react';
import {
  TextInput,
  SimpleForm,
  BooleanInput,
} from 'react-admin';
import FormToolbar from '../../../common/FormToolbar';

export function transform(data) {
  data.sortOrder = parseInt(data?.sortOrder || 0)
  return data;
}

const ProductTypeForm = props => {
  return (
    <SimpleForm style={{ width: '100%' }} {...props} redirect="list" toolbar={<FormToolbar />}>
      <TextInput source="code" />
      <TextInput source="name" />
      <TextInput source="alias" />
      <TextInput source="sortOrder" placeholder='-1 - сховати, 0+ - порядок'/>
      <hr />
      <p>[TODO: Attributes Sort Order]</p>
      <BooleanInput source="deleted" />
    </SimpleForm>
  );
};

export default ProductTypeForm;
