import {
  USER_REG_STATUS_APPROVED,
  USER_REG_STATUS_DECLINED,
  USER_REG_STATUS_NO_REG,
} from "../components/models/User";

export function decodeHtmlFields(fields, record) {
  const decoder = document.createElement("textarea");
  const decodedFields = {};

  fields.forEach((field) => {
    if (record[field]) {
      decoder.innerHTML = record[field];
      decodedFields[field] = decoder.value;
    }
  });

  return { ...record, ...decodedFields };
}

export const isNewCodeValid = (value, values) => {
  const { profile } = values;
  const { isSynced } = profile || {};
  switch (true) {
    case !value:
      return `Обов'язкове поле`;
    case !isSynced:
      return "Клієнт ще не пройшов синхронізацію. Зміна кода заборонена";
    // @todo add existCode Dynamic validation by codeIsNotInUse
    default:
      return null;
  }
};

export const requiredDeclineReason = (value, values) => {
  const status = Number(values.registrationStatus);
  const isValid =
    (value && status === USER_REG_STATUS_DECLINED) ||
    !status === USER_REG_STATUS_DECLINED;

  return isValid ? null : "Обязательное поле";
};

export const required = (value, values) => {
  const status = Number(values?.registrationStatus);

  const isApproved = [
    USER_REG_STATUS_NO_REG,
    USER_REG_STATUS_APPROVED,
  ].includes(status);
  const isValid = (value && isApproved) || !isApproved;

  return isValid ? null : "Обязательное поле";
};

export const parse = (values) =>
  values ? values.reduce((acc, v) => ({ ...acc, [v]: true }), {}) : [];

export const format = (val) =>
  val
    ? Object.keys(val).reduce((acc, v) => (val[v] ? [...acc, v] : acc), [])
    : [];
