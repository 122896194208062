export const catalogProductTypeShear = "shear-flowers";
export const catalogProductTypeAccessories = "accessories";
export const catalogProductTypePotted = "potted-flowers";
export const catalogProductTypeStabilized = "preserved-flowers";

export const catalogProductTypes = {
  [catalogProductTypeShear]: "Зріз",
  [catalogProductTypeAccessories]: "Фурнітура",
  [catalogProductTypePotted]: "Вазони",
  [catalogProductTypeStabilized]: "Стабілізовані"
};

export const getCatalogProductTypeOptions = () => {
  const options = [];
  for (const [key, value] of Object.entries(catalogProductTypes)) {
    options.push({id: key, name: value});
  }
  return options;
}
