import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomEdit } from '../../../common/CustomEdit';
import { SearchKeywordsForm } from './components/SearchKeywordsForm';
import { transformKeywords } from './transformKeywords';

const SearchKeywordsTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? <span>{translate('resources.system/seo/search-keywords.title')}</span> : null;
};

export const SearchKeywordsEdit = props => (
  <CustomEdit
    title={<SearchKeywordsTitle />}
    component="div"
    transform={transformKeywords}
    redirect="list"
    {...props}
  >
    <Form>
      <SearchKeywordsForm />
    </Form>
  </CustomEdit>
);
