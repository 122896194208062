import ProductTypeList from './ProductTypeList';
import ProductTypeCreate from './ProductTypeCreate';
import ProductTypeEdit from './ProductTypeEdit';
import Icon from '@mui/icons-material/House';

export default {
  list: ProductTypeList,
  create: ProductTypeCreate,
  edit: ProductTypeEdit,
  icon: Icon,
};
