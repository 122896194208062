import React from 'react';
import {
  TextField,
  TextInput,
  Labeled,
  NumberInput,
  BooleanInput,
  useRecordContext,
} from 'react-admin';
import { Card, CardContent, Grid, Button, Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FormToolbar from '../../../common/FormToolbar';
import { PageTitle } from '../../../common/PageTitle';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const tinyFieldStyle = {
  '& > p': {
    display: 'none',
  },
};

const tickRateHint = () => (
  <code>
    * * * * * *<br />
    | | | | | |<br />
    | | | | | day of week
    <br />
    | | | | month
    <br />
    | | | day of month
    <br />
    | | hour
    <br />
    | minute
    <br />
    second ( optional )<br />
  </code>
);

export const CronJobEditForm = ({ handleJobAction, pendingActions, ...formProps }) => {
  const record = useRecordContext();

  return (
    <Box maxWidth="70em">
      <Card>
        <PageTitle record={record} titleText={`Крон ${record.name}`} />
        <CardContent>
          <Grid container>
            <Grid item sm={12} md={3}>
              <BooleanInput
                source="options.ignoreMaintenance"
                resource="system/cron-jobs"
                record={record}
                disabled={record.isSystem}
                sx={tinyFieldStyle}
              />
              <BooleanInput
                source="options.silent"
                resource="system/cron-jobs"
                record={record}
                disabled={record.isSystem}
                sx={tinyFieldStyle}
              />
              <BooleanInput
                source="options.startNow"
                resource="system/cron-jobs"
                record={record}
                disabled={record.isSystem}
                sx={tinyFieldStyle}
              />
              <BooleanInput
                source="options.runOnInit"
                resource="system/cron-jobs"
                record={record}
                disabled={record.isSystem}
                sx={tinyFieldStyle}
              />
            </Grid>
            <Grid item sm={12} md={9}>
              <Grid container>
                <Grid item sm={12} md={4}>
                  <Tooltip
                    title={tickRateHint()}
                    placement="top"
                    classes={{ tooltip: { fontSize: 14 } }}
                  >
                    <span>
                      <TextInput
                        disabled={record.isSystem}
                        source="tickRate"
                        resource="system/cron-jobs"
                        record={record}
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Labeled source="status" resource="system/cron-jobs">
                    <TextField source="status" record={record} />
                  </Labeled>
                </Grid>
                <Grid item sm={12} md={4}>
                  <Labeled source="disabled" resource="system/cron-jobs">
                    <BooleanColoredField
                      source="disabled"
                      record={record}
                      disabled={record.isSystem}
                      sx={tinyFieldStyle}
                      reverse
                    />
                  </Labeled>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={12} md={4}>
                  <NumberInput source="timeout" resource="system/cron-jobs" record={record} />
                </Grid>
                <Grid item sm={12} md={8}>
                  <TextInput
                    source="description"
                    resource="system/cron-jobs"
                    record={record}
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              '& > *': {
                marginRight: 40,
              },
            }}
          >
            <Button
              disabled={record.isSystem || pendingActions}
              onClick={() => handleJobAction('start')}
            >
              <KeyboardArrowRightIcon />
              start
            </Button>
            <Button
              disabled={record.isSystem || pendingActions}
              onClick={() => handleJobAction('stop')}
            >
              <StopIcon />
              stop
            </Button>
            <Button disabled={pendingActions} onClick={() => handleJobAction('execute')}>
              <PlayArrowIcon />
              execute
            </Button>
            {/* <Button disabled={pendingActions} onClick={() => handleJobAction('terminate')}>
              <CloseIcon />
              terminate
            </Button> */}
          </Box>
        </CardContent>
        <FormToolbar withoutDelete={record.isSystem} {...formProps} />
      </Card>
    </Box>
  );
};
