import React from 'react';
import { TextField, SearchInput, ReferenceField, NumberInput } from 'react-admin';
import { ColorField } from 'react-admin-color-picker';
import { Chip } from '@mui/material';
import ImagePreview from '../../../common/ImagePreview';
import CategoryReferenceInput from '../../catalog/products/CategoryReferenceInput';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const offersFilter = [
  <SearchInput source="q" alwaysOn />,
  <CategoryReferenceInput source="catalogCategory" />,
  <Chip source="enabled" defaultValue={true} />,
  <NumberInput source="order" />,
];

export const FeaturedProductsSectionList = props => (
  <CustomList filters={offersFilter} {...props}>
    <NewTabLinkDataGrid rowClick="edit">
      <ReferenceField source={'productType'} reference={'system/product-types'} link={false}>
        <TextField source={'name'} />
      </ReferenceField>
      <ReferenceField source={'catalogCategory'} reference={'catalog/productType/all'} link={false}>
        <TextField source={'name'} />
      </ReferenceField>
      <ImagePreview
        urlSource="static/featured-products-images"
        label="resources.home/featured-products-sections.fields.image"
      />
      <ColorField source="color" />
      <TextField source="order" />
      <BooleanColoredField source="enabled" />
    </NewTabLinkDataGrid>
  </CustomList>
);
