import { CustomEdit } from '../../../common/CustomEdit';
import StaticPagesForm, { transform } from './StaticPagesForm';

const StaticPagesEdit = (props) => {
  return (
    <CustomEdit {...props} transform={transform}>
      <StaticPagesForm />
    </CustomEdit>
  );
};

export default StaticPagesEdit;