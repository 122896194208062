import React, { useEffect, useState } from 'react';
import {
  BooleanInput,
  Button,
  email,
  FileField,
  FileInput,
  FormDataConsumer,
  FormTab,
  Labeled,
  NumberField,
  NumberInput,
  PasswordInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import FormToolbar from "../../common/FormToolbar";
import { Box, Grid } from "@mui/material";
import { LoginAsClient } from "./LoginAsClient";
import { ClientStatusSelect } from "../../fields/ClientStatusSelect";
import { CustomList } from "../../common/CustomList";
import { OrdersTable } from "../orders/components/OrderTable";
import { TransactionsTable } from "../transactions/TransactionsTable";
import { PaymentsTable } from "../payments/PaymentsTable";
import { OutletsTable } from "../outlets/OutletsTable";
import {
  format,
  isNewCodeValid,
  parse,
  required,
  requiredDeclineReason,
} from "../../../utils/transforms";
import { useStyles } from "./styles";
import { getFileType } from "../../../utils/imageTransform";
import { API_SERVER } from "../../../consts";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from 'react-router-dom';

const ScansList = ({ className }) => {
  const record = useRecordContext();
  const [currentFiles, setCurrentFiles] = useState(record.profile.files);
  const isFilesExists =
    record &&
    record.profile &&
    record.profile.files &&
    record.profile.files.length > 0;

  useEffect(() => {
    if (typeof record.profile.files[0] !== "string") {
      setCurrentFiles(record.profile.files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.profile.files[0]]);

  if (!isFilesExists) {
    return null;
  }

  const handleRemoveFile = (idx) => {
    const files = record.profile.files.filter((_, i) => i !== idx);
    record.profile.files = files;
    record.oldFiles = files;
    setCurrentFiles(files);
  };

  if (currentFiles && currentFiles.length) {
    return (
      <React.Fragment>
        {currentFiles.map((file, idx) => {
          const fileId = file._id || file;
          const fileName = file.fileName || file;
          const fileType = getFileType(file);
          const url = `${API_SERVER}/static/client-uploads/${fileId}${fileType}`;
          return (
            <p key={fileId} className={className}>
              <IconButton
                aria-label="delete"
                onClick={() => handleRemoveFile(idx)}
              >
                <DeleteIcon />
              </IconButton>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {`${fileName}`}
              </a>
              {["image/jpeg", "image/png"].includes(file.mimetype) ? (
                <img
                  src={url}
                  alt={fileId}
                  style={{
                    border: "1px solid gray",
                    margin: 10,
                    maxHeight: 150,
                    maxWidth: 150,
                  }}
                />
              ) : null}
            </p>
          );
        })}
      </React.Fragment>
    );
  }
  return null;
};


const ClientEditForm = (props) => {
  const record = useRecordContext();
  const location = useLocation();


  const [replaceCode, setReplaceCodeState] = useState(false);

  const activeTab = location.pathname.split("/").pop();
  const classes = useStyles();

  return (
    <SimpleForm {...props} toolbar={false}>
      <TabbedForm toolbar={<FormToolbar withoutDelete withExport />}>
        <FormTab
          label="resources.users.tabs.identity"
          contentClassName={classes.tabContainer}
        >
          <Box display={{ xs: "block", sm: "flex" }} fullWidth>
            <Box flex={1} mr={2}>
              <TextInput
                source="profile.firstName"
                resource="clients"
                fullWidth
              />
            </Box>
            <Box flex={1}>
              <TextInput
                source="profile.lastName"
                resource="clients"
                fullWidth
              />
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex" }} fullWidth>
            <Box flex={1} mr={2}>
              <TextInput source="username" resource="clients" fullWidth />
            </Box>
            <Box flex={1}>
              <TextInput source="phone" resource="clients" fullWidth />
            </Box>
          </Box>
          <TextInput
            type="email"
            source="email"
            resource="clients"
            validate={[email()]}
            fullWidth
          />
          <TextInput
            source="profile.businessName"
            resource="clients"
            fullWidth
          />
          <TextField source="profile.customerId" />

          {replaceCode ? (
            <TextInput
              type="text"
              label="Код клієнта"
              source="profile.code"
              validate={isNewCodeValid}
            />
          ) : (
            <FormDataConsumer>
              {({ formData }) => {
                const { profile } = formData;
                const { code, isSynced } = profile || {};
                if (code) {
                  return (
                    <div>
                      Код клієнта: {code}
                      {isSynced && (
                        <Button
                          label={"Замінити код"}
                          onClick={() =>
                            setReplaceCodeState(true)
                          }
                        />
                      )}
                    </div>
                  );
                }
                return "";
              }}
            </FormDataConsumer>
          )}

          <FormDataConsumer>
            {({ formData }) => {
              const { profile } = formData;
              const { code } = profile || {};
              if (code) {
                formData._dynamic = "bang";
              }
            }}
          </FormDataConsumer>

          <TextInput source="profile.EDRPOU" label="ЕДРПОУ" />
          <LoginAsClient />
        </FormTab>

        <FormTab label="resources.users.tabs.documents">
          <ScansList className={classes.files_field} />
          <FileInput source="files" label="Files" multiple={true}>
            <FileField
              source="_id"
              title="fileName"
              target="_blank"
              classes={{ root: classes.files_field }}
            />
          </FileInput>
        </FormTab>

        <FormTab label="resources.users.tabs.system" path="system">
          <Grid container spacing={5} fullWidth>
            <Grid item sm={12} md={3}>
              <Labeled label="resources.users.tabs.region" fullWidth>
                <ReferenceField
                  reference="system/suppliers"
                  source="profile.supplier"
                >
                  <TextField source="name" />
                </ReferenceField>
              </Labeled>
              <ReferenceInput
                reference="system/warehouses"
                source="profile.warehouse"
                filter={{ isSystem: true }}
              >
                <SelectInput
                  optionText="name"
                  validate={required}
                  label="resources.users.fields.profile.warehouse"
                  fullWidth
                />
              </ReferenceInput>
              <ClientStatusSelect
                label="resources.users.fields.profile.registrationStatus"
                formClassName={classes.flexStyles}
              />
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData && Number(formData.registrationStatus) === -1 ? (
                    <TextInput
                      label="resources.users.fields.profile.declineReason"
                      source="declineReason"
                      resource="clients"
                      validate={requiredDeclineReason}
                      fullWidth
                      {...rest}
                    />
                  ) : null
                }
              </FormDataConsumer>
              <SelectInput
                source="role"
                label="Роль"
                choices={[{ id: "user", name: "Client" }]}
                fullWidth
              />
              <BooleanInput
                source="allowMultipleSessions"
                label="Дозволити авторизацію з кількох девайсів"
              />
              <PasswordInput source="password" fullWidth />
            </Grid>
            <Grid item sm={12} md={1}></Grid>
            <Grid item sm={12} md={3}>
              <Labeled label="Баланс 1с" fullWidth>
                <NumberField source="profile.balance_1c" />
              </Labeled>
              <Labeled label="Загальний баланс" fullWidth>
                <NumberField source="profile.balance" />
              </Labeled>
              <NumberInput
                source="profile.balanceAdjustment"
                label="Початковий баланс (загальний)"
                fullWidth
              />
              <Labeled label="Фактичний баланс" fullWidth>
                <NumberField source="profile.actualBalance" />
              </Labeled>
              <NumberInput
                source="profile.balanceAdjustmentCompleted"
                label="Початковий баланс (фактичний)"
                fullWidth
              />
              <NumberInput
                source="profile.credit"
                label="Кредитний ліміт"
                fullWidth
              />
              <SelectArrayInput
                label="resources.client/payment-types.fields.paymentType"
                choices={[
                  { id: "cash", name: "Готівка" },
                  { id: "liqpay", name: "Liqpay" },
                ]}
                source="preferences.paymentMethods"
                format={format}
                parse={parse}
                fullWidth
              />
              <ReferenceInput
                source="profile.merchant"
                reference="sales/business/merchants"
                isRequired={true}
              >
                <SelectInput
                  label="Merchant"
                  optionText={(value) =>
                    `${value.name}${value.isActive ? "" : "(НЕАКТИВНО)"}`
                  }
                  fullWidth
                />
              </ReferenceInput>
            </Grid>
          </Grid>
        </FormTab>

        <FormTab label="resources.users.tabs.orders" path="orders">
          {activeTab === "orders" ? (
            <CustomList
              basePath="/sales/orders"
              resource="sales/orders"
              filter={{ client: record.id }}
              classes={{
                content: classes.tabTableContent,
                root: classes.tabTableRoot,
              }}
              fullWidth
            >
              <OrdersTable />
            </CustomList>
          ) : null}
        </FormTab>

        <FormTab label="resources.users.tabs.ordersRefund" path="orders-refund">
          {activeTab === "orders-refund" ? (
            <CustomList
              basePath="/sales/orders-refund"
              resource="sales/orders-refund"
              filter={{ client: record.id }}
              classes={{
                content: classes.tabTableContent,
                root: classes.tabTableRoot,
              }}
              fullWidth
            >
              <OrdersTable />
            </CustomList>
          ) : null}
        </FormTab>

        <FormTab label="resources.users.tabs.transactions" path="transactions">
          {activeTab === "transactions" ? (
            <CustomList
              basePath="/sales/transactions"
              resource="sales/transactions"
              filter={{ client: record.id, loadBalance: true }}
              sort={{ field: "operation.date", order: "DESC" }}
              classes={{
                content: classes.tabTableContent,
                root: classes.tabTableRoot,
              }}
              fullWidth
              filters={[
                <BooleanInput
                  source="onlyCompleted"
                  label="Фактичний баланс"
                  alwaysOn
                />,
              ]}
            >
              <TransactionsTable />
            </CustomList>
          ) : null}
        </FormTab>

        <FormTab label="resources.users.tabs.payments" path="payments">
          {activeTab === "payments" ? (
            <CustomList
              basePath="/sales/payments"
              resource="sales/payments"
              filter={{ client: record.id }}
              classes={{
                content: classes.tabTableContent,
                root: classes.tabTableRoot,
              }}
              fullWidth
            >
              <PaymentsTable />
            </CustomList>
          ) : null}
        </FormTab>

        <FormTab
          label="resources.users.tabs.paymentsRefund"
          path="payments-refund"
        >
          {activeTab === "payments-refund" ? (
            <CustomList
              basePath="/sales/payments-refund"
              resource="sales/payments-refund"
              filter={{ client: record.id }}
              classes={{
                content: classes.tabTableContent,
                root: classes.tabTableRoot,
              }}
              fullWidth
            >
              <PaymentsTable />
            </CustomList>
          ) : null}
        </FormTab>

        <FormTab label="resources.users.tabs.outlets" path="outlets">
          {activeTab === "outlets" ? (
            <CustomList
              basePath="/client/outlets"
              resource="client/outlets"
              filter={{ client: record.id }}
              classes={{
                content: classes.tabTableContent,
                root: classes.tabTableRoot,
              }}
              fullWidth
            >
              <OutletsTable />
            </CustomList>
          ) : null}
        </FormTab>
      </TabbedForm>
    </SimpleForm>
  );
};

export default ClientEditForm;