import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  offerQty: {
    display: 'flex',
    alignItems: 'center',
  },

  tooltip: {
    marginLeft: '10px',
    borderRadius: '20px',
    overflow: 'hidden',
    background: '#ccc',
  },

  actionButton: {
    display: 'flex',
    padding: '10px',
    marginTop: '10px',
    marginLeft: '20px',
    '&:first-child': {
      // marginTop: '10px'
    },
  },
});
