import React from 'react';
import {
  TextField,
  ChipField,
  ReferenceField,
} from 'react-admin';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import LocalDateField from '../../../fields/LocalDateField';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const MerchantList = props => {
  return (
    <CustomList {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="code" />
        <TextField source="name" />
        <BooleanColoredField source="isActive" />
        <ChipField source="paymentSystem" />
        <ChipField source="merchantType" />
        <ReferenceField reference="sales/business/cash-registers" source="cashRegister">
          <ChipField source="name" />
        </ReferenceField>
        <LocalDateField source="shift.opened_at" showTime label={"Зміна відкрита"} />
        <LocalDateField source="createdAt" showTime />
        <LocalDateField source="updatedAt" showTime />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};

export default MerchantList;
