import React, { useState } from 'react';
import { TextInput, BooleanInput, SimpleForm } from 'react-admin';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import {
  enabledSettings,
  booleanSettings,
  settingsTabs,
} from '../settings/constants/settingValueTypes';
import FormToolbar from '../../../common/FormToolbar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      {...other}
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      style={{ width: '500px' }}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export const SystemConfigsView = ({ data, onSubmit, updateLoading }) => {
  const [value, setValue] = useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const onSave = formData => {
    onSubmit(formData);
  };

  return (
    <>
      <Typography variant="h5">System configs</Typography>
      <Paper>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="system-config-tabs"
        >
          {settingsTabs.map(i => (
            <Tab label={i.label} key={i.label} />
          ))}
        </Tabs>
        <SimpleForm
          toolbar={<FormToolbar withoutDelete disableSaving={updateLoading} />}
          onSubmit={onSave}
          defaultValues={data}
        >
          {settingsTabs.map((tab, idx) => (
            <TabPanel value={value} key={idx} index={idx}>
              {tab.settings.map(key =>
                booleanSettings.includes(key) ? (
                  <BooleanInput source={key} key={key} label={key} fullWidth />
                ) : (
                  <TextInput
                    source={key}
                    key={key}
                    label={key}
                    disabled={!enabledSettings.includes(key)}
                    fullWidth
                  />
                )
              )}
            </TabPanel>
          ))}
        </SimpleForm>
      </Paper>
    </>
  );
};
