import { ImageField, ImageInput as RaImageInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import classes from './styles.module.scss';
const CustomImageInput = ({ source, accept, fieldSource, maxSize, ...rest }) => {
  const format = (data) => {
    if (!data) return null;
    if (Array.isArray(data)) {
      return data.map((i) => ({
        ...i,
        src: i.src || i.url,
      }));
    }
    return {
      ...data,
      src: data.src || data.url,
    };
  };
  const { setError, formState } = useFormContext();

  const handleImageChange = (file) => {
    if (file.size > maxSize) {
      setError(source || 'image', {
        message: `Фото дуже велике за розміром, максимальний розмір ${maxSize / 1024}кб`,
      });
    } else {
      setError(source || 'image', {});
    }
  };
  return (
    <>
      <RaImageInput
        source={source || 'image'}
        maxSize={maxSize}
        format={format}
        className={classes.text}
        helperText={
          formState.errors && formState.errors[source || 'image']
            ? formState.errors[source || 'image']?.message
            : ''
        }
        options={{ validator: handleImageChange }}
        accept={accept || 'image/*'}
        label="fields.photo"
        {...rest}
      >
        <ImageField source={fieldSource || 'src'} title="Card image" />
      </RaImageInput>
    </>
  );
};

export { CustomImageInput };
