import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomEdit } from '../../common/CustomEdit';
import { OrderEditForm } from './OrderEditForm';

const OrderTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? (
    <span>
      {translate('resources.sales/orders.nameOne')}
      &nbsp;{record.orderId}
    </span>
  ) : null;
};

function transform({
  deliveryMethod,
  deliveryDate,
  deliveryTime,
  packageReturn,
  totalSum,
  orderStatus,
  client,
  ...rest
}) {
  return {
    deliveryMethod,
    deliveryDate,
    deliveryTime: deliveryTime ? deliveryTime.id : null,
    packageReturn,
    totalSum,
    orderStatus,
    client: client.id,
  };
}

const OrderEdit = props => (
  <CustomEdit title={<OrderTitle />} component="div" transform={transform} {...props}>
    <Form>
      <OrderEditForm />
    </Form>
  </CustomEdit>
);

export default OrderEdit;
