import React from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FalseIcon from '@mui/icons-material/Clear';
import TrueIcon from '@mui/icons-material/Done';
import { ClientDataView } from '../../common/ClientDataView';
import { RedirectField } from '../../fields/RedirectField';
import { useRecordContext } from 'react-admin';

const useStyles = makeStyles({
  table: {
    width: 'auto',
  },
  orderInfo: {
    display: 'flex',
    '& > div': {
      width: 'auto',
    },
    '& > div:first-child': {
      marginRight: 40,
    },
  },
});

const OrderPanel = () => {
  const record = useRecordContext();
  const classes = useStyles();
  const { items, client } = record;
  return (
    <div className={classes.orderInfo}>
      <ClientDataView data={client} />
      {items && items.length ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Код</TableCell>
                <TableCell>Товар</TableCell>
                <TableCell align="right">Группа</TableCell>
                <TableCell align="right">Предзаказ</TableCell>
                <TableCell align="right">Количество</TableCell>
                <TableCell align="right">Цена за шт</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(item => (
                <TableRow key={`cart-item-${item.id}`}>
                  <TableCell align="left">
                    <RedirectField
                      record={(item.flatOffer && item.flatOffer.product) || {}}
                      source="code"
                      linkIdSource="id"
                      linkUrl="catalog/products"
                      label="Код"
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {(item.flatOffer &&
                      item.flatOffer.product &&
                      item.flatOffer.product.fullName) ||
                      ''}
                  </TableCell>
                  <TableCell align="right">
                    {(item.flatOffer &&
                      item.flatOffer.product &&
                      item.flatOffer.product.catalogCategory) ||
                      ''}
                  </TableCell>
                  <TableCell align="right">
                    {item.flatOffer &&
                    item.flatOffer.product &&
                    item.flatOffer.product.hasPreOrder ? (
                      <TrueIcon data-testid="true" />
                    ) : (
                      <FalseIcon data-testid="false" />
                    )}
                  </TableCell>
                  <TableCell align="right">{item.qty || ''}</TableCell>
                  <TableCell align="right">{item.price || ''}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </div>
  );
};

export default OrderPanel;
