import FeatureFlagIcon from '@mui/icons-material/Settings';

import FeatureFlagList from './FeatureFlagList';
import FeatureFlagCreate from './FeatureFlagCreate';
import FeatureFlagEdit from './FeatureFlagEdit';

export default {
  icon: FeatureFlagIcon,
  list: FeatureFlagList,
  create: FeatureFlagCreate,
  edit: FeatureFlagEdit,
};
