import React from 'react';
import { CustomEdit } from '../../../common/CustomEdit';
import SupplierForm from './SupplierForm';

const SupplierEdit = props => (
  <CustomEdit redirect="list" {...props}>
    <SupplierForm />
  </CustomEdit>
);

export default SupplierEdit;
