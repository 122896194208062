import React from 'react';
import { Loading, Error, useGetList } from 'react-admin';
import { useParams } from 'react-router-dom';
import { getTreeFromFlatData } from '@nosferatu500/react-sortable-tree';
import classnames from 'classnames';
import { Drawer } from '@mui/material';
import { withStyles } from '@mui/styles';
import CategoryCreate from './CategoryCreate';
import CategoryEdit from './CategoryEdit';
import TreeGrid from '../../../common/TreeGrid';

const styles = {
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: 'all 225ms ease-in-out',
    marginRight: 0,
    '& .rst__rowToolbar': {
      alignItems: 'center',
    },
  },
  listWithDrawer: {
    marginRight: 400,
  },
};

const prepareData = (data) =>
  getTreeFromFlatData({
    rootKey: null,
    getKey: (item) => item.id,
    getParentKey: (item) => item.parentId,
    flatData: data.map((item) => {
      return {
        id: item.id,
        parentId: (item.parent && item.parent.id) || null,
        title: item.name,
        subtitle: item.alias,
        expanded: !!item.expanded,
      };
    }),
  });

const CategoryTreeComponent = ({
  classes,
  createNode,
  editNode,
  saveData,
  removeNode,
  handleClose,
}) => {
  const params = useParams();
  const { type, ...rest } = params;
  const routeParts = rest['*'].split('/');
  const id = routeParts[0];

  const paramsId = params && params['*'];
  const idEdit = Boolean(paramsId && typeof paramsId === 'string' && paramsId !== 'create');
  const isCreate = Boolean(paramsId && typeof paramsId === 'string' && paramsId === 'create');

  const { data, isLoading, error, refetch } = useGetList(`catalog/productType/${type}`, {
    pagination: { page: 1, perPage: 10000 },
    sort: {},
  });

  if (isLoading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  const treeData = prepareData(data || []);

  /*
  // @todo investigate why not working in this form.
  const drawerContent =
    matchId === 'create' ? (
      <CategoryCreate
        basePath={`/catalog/productType/${type}/create`}
        resource="catalog/categories"
        className={classes.drawerContent}
      />
    ) : id ? (
      <CategoryEdit
        basePath={`/catalog/productType/${type}/edit`}
        resource="catalog/categories"
        className={classes.drawerContent}
        id={id}
      />
    ) : null;
  */

  const drawerContent = isCreate ? (
    <CategoryCreate
      basePath="/catalog/productType/all"
      resource="catalog/categories"
      productType={type}
      refetchTree={refetch}
    />
  ) : idEdit ? (
    <CategoryEdit basePath="/catalog/productType/all" resource="catalog/categories" id={id} />
  ) : null;

  return (
    <TreeGrid
      type={type}
      className={classnames(classes.list, {
        [classes.listWithDrawer]: Boolean(id),
      })}
      data={treeData}
      onCreate={createNode}
      onEdit={editNode}
      onSave={saveData}
      onDelete={removeNode}
    >
      <Drawer
        open={idEdit || isCreate}
        anchor="right"
        variant={'temporary'}
        onClose={handleClose}
        children={classes.drawerPaper}
      >
        {drawerContent}
      </Drawer>
    </TreeGrid>
  );
};
export const CategoryTree = withStyles(styles)(CategoryTreeComponent);
