import React, { useCallback, useState } from 'react';
import {
  Confirm,
  DeleteWithConfirmButton,
  SaveButton,
  Toolbar,
  useDataProvider,
  useNotify,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { useFormState } from 'react-hook-form';
import { withStyles } from '@mui/styles';
import { BackButton } from './BackButton';
import { useNavigate } from 'react-router-dom';
import { ResetButton } from './ResetButton';
import { ExportSyncButton } from './ExportSyncButton';

const sanitizeProps = ({
  dirtyFields,
  dirtySinceLastSubmit,
  dirtyFieldsSinceLastSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  defaultValues,
  modifiedSinceLastSubmit,
  submitError,
  submitErrors,
  submitFailed,
  submitSucceeded,
  submitting,
  valid,
  save,
  ...rest
}) => rest;

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *': {
      marginRight: 10,
    },

    '& :first-child': {
      marginRight: 'auto',
    },

    // width: 400,
  },
};

const FormToolbar = withStyles(toolbarStyles)(
  ({
    withoutDelete,
    withoutSave,
    withoutReset,
    withoutBack,
    withExport,
    disableSaving,
    onSaved,
    ...props
  }) => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const navigate = useNavigate();
    const { isDirty, isValid, isSubmitting } = useFormState();
    const disableSaveBtn = !isValid || !isDirty || isSubmitting || disableSaving;
    const defaultConfirmationState = { open: false, title: '', content: '', callback: undefined };
    const [confirmationState, setConfirmationState] = useState(defaultConfirmationState);

    //region @todo move to export button
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [pendingExport, setPendingExport] = useState(false);

    const onSuccess = () => {
      if (onSaved && typeof onSaved === 'function') {
        onSaved();
      } else {
        notify('Збережено!');
        navigate(-1);
      }
    };

    const confirmAction = (title, content, callback) => {
      setConfirmationState({
        open: true,
        title,
        content,
        callback,
      });
    };

    const handleExportAction = useCallback(() => {
      if (pendingExport) {
        notify('Експорт ще виконується, зачекайте', { type: 'info' });
      } else {
        const doExport = () => {
          setPendingExport(true);
          dataProvider
            .create(`${resource}/export/${record.id}`, {})
            .then(() => notify('Експорт успішний', { type: 'success' }))
            .catch(() => notify('Помилка експорту', { type: 'warning' }))
            .finally(() => setPendingExport(false));
        };

        const { isSynced = false } = record || {};
        if (isSynced) {
          confirmAction('', 'Документ вже було експортовано, повторити?', () => doExport());
        } else {
          doExport();
        }
      }
      return false;
    }, [record, resource, pendingExport, setPendingExport]);

    const { open, title, content, callback } = confirmationState;
    const setOpen = (open) => setConfirmationState({ ...confirmationState, open });

    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
      setOpen(false);
      if ('function' === typeof callback) {
        callback();
      }
    };
    //endregion

    return (
      <Toolbar {...sanitizeProps(props)}>
        <BackButton />
        {!withoutReset && isDirty && <ResetButton />}
        {!withoutDelete && (
          <DeleteWithConfirmButton label="ra.action.delete" redirect={() => navigate(-1)} />
        )}
        {withExport && (
          <ExportSyncButton
            label="ra.action.export"
            variant="text"
            redirect={null}
            onClick={handleExportAction}
            exporting={pendingExport}
          />
        )}
        {!withoutSave && (
          <SaveButton
            label="ra.action.apply"
            variant="text"
            type="button"
            mutationOptions={{ onSuccess: () => notify('Збережено!', { type: 'success' }) }}
            disabled={disableSaveBtn}
          />
        )}
        {!withoutSave && (
          <SaveButton
            label="ra.action.save"
            variant="text"
            type="button"
            mutationOptions={{ onSuccess }}
            disabled={disableSaveBtn}
          />
        )}

        <Confirm
          isOpen={open}
          title={title}
          content={content}
          onConfirm={handleConfirm}
          onClose={handleDialogClose}
        />
      </Toolbar>
    );
  }
);

export default FormToolbar;
