import React from 'react';
import { TextField } from 'react-admin';
import { NewTabLinkDataGrid } from '../../../../common/NewTabLinkDataGrid';
import LocalDateField from '../../../../fields/LocalDateField';

export const ClientDiscountTable = props => {
  return (
    <NewTabLinkDataGrid rowClick="edit" {...props}>
      <TextField source="name" />
      <LocalDateField source="createdAt" showTime />
      <LocalDateField source="updatedAt" showTime />
    </NewTabLinkDataGrid>
  );
};
