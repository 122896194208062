import React from 'react';
import { TextField, SearchInput, NumberField } from 'react-admin';
import { ColorField } from 'react-admin-color-picker';
import { Chip } from '@mui/material';
import ImagePreview from '../../../common/ImagePreview';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import LocalDateField from '../../../fields/LocalDateField';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const sliderFilter = [
  <SearchInput source="q" alwaysOn />,
  <Chip source="enabled" defaultValue={true} />,
];

export const SliderList = (props) => (
  <CustomList filters={sliderFilter} {...props}>
    <NewTabLinkDataGrid rowClick="edit">
      <TextField source="header" label="resources.homePage.sliders.fields.header" />
      <TextField source="description" label="resources.homePage.sliders.fields.description" />
      <NumberField source="order" label="resources.homePage.sliders.fields.order" />
      <BooleanColoredField source="enabled" label="resources.homePage.sliders.fields.enabled" />
      <ColorField label="resources.homePage.sliders.fields.color" source="color" />
      <ImagePreview
        urlSource="static/slider-images"
        source="image"
        label="resources.homePage.sliders.fields.image"
      />
      <ImagePreview
        urlSource="static/slider-images"
        source="mobileImage"
        label="resources.homePage.sliders.fields.mobileImage"
      />
      <LocalDateField label="resources.commonFields.createdAt" source="createdAt" showTime />
      <LocalDateField label="resources.commonFields.updatedAt" source="updatedAt" showTime />
    </NewTabLinkDataGrid>
  </CustomList>
);
