import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomCreate } from '../../common/CustomCreate';
import { DeliveryTimeForm } from './components/DeliveryTimeForm';
import { transformDeliveryTimes } from './transformDeliveryTimes';

const DeliveryTimeTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? <span>{translate('resources.sales/shipping/delivery-times.name')}</span> : null;
};

export const DeliveryTimesCreate = props => (
  <CustomCreate
    title={<DeliveryTimeTitle />}
    component="div"
    transform={transformDeliveryTimes}
    {...props}
  >
    <Form>
      <DeliveryTimeForm />
    </Form>
  </CustomCreate>
);
