export const onSaveSpecialOffers = ({
  isActive,
  name,
  startDate,
  endDate,
  supplier,
  warehouse,
  conditions,
  qtyConditions,
  categories,
  productType,
}) => ({
  isActive,
  name,
  startDate,
  endDate,
  supplier,
  warehouse,
  categories,
  productType,
  type: 'time',
  productsPricing: Object.keys(conditions).map(productId => ({
    product: productId,
    qtyPriceConditions: qtyConditions.reduce(
      (acc, qc, idx) => (!qc ? acc : { ...acc, [qc]: conditions[productId][idx] }),
      {}
    ),
  })),
});
