import React from 'react';
import OutletForm, { transform } from './OutletForm';
import { CustomEdit } from '../../common/CustomEdit';

const OutletsEdit = props => (
  <CustomEdit {...props} transform={transform}>
    <OutletForm />
  </CustomEdit>
);

export default OutletsEdit;
