import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNotify, Confirm, useUpdate } from 'react-admin';
import { BackButton } from '../../../common/BackButton';
import ProductsToCategoriesDataGrid from './ProductsToCategoriesDataGrid';

const styles = theme => ({
  wrapper: {
    display: 'flex',
  },
  buttonWrapper: {
    marginTop: 240,
    display: 'flex',
    justifyContent: 'center',
    width: 100,
    height: 100,
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    width: '45%',
  },
  backBtn: {
    width: 100,
  },
  categoryAutocomplete: {},
});

const ProductsToCategories = ({ classes }) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [refetchProducts, setRefetchProducts] = useState(false);
  const [direction, setDirection] = useState('');
  const [leftCategoryProducts, setLeftCategoryProducts] = useState([]);
  const [rightCategoryProducts, setRightCategoryProducts] = useState([]);
  const [leftSelectedCategory, setLeftSelectedCategory] = useState(null);
  const [rightSelectedCategory, setRightSelectedCategory] = useState(null);
  const [selectedLeftCategoryProductsIds, setSelectedLeftCategoryProductsIds] = useState([]);
  const [selectedRightCategoryProductsIds, setSelectedRightCategoryProductsIds] = useState([]);
  const [update, { isLoading: updateLoading }] = useUpdate();

  const handleDialogClose = () => setOpen(false);
  const handleDialogOpen = side => {
    if (side === 'toLeft') {
      if (!selectedRightCategoryProductsIds.length) {
        notify('Потрібно вибрати хоча б один продукт у правій таблиці', { type: 'info' });
        return;
      }
      if (!leftSelectedCategory) {
        notify('Потрібно вибрати категорію в лівій таблиці', { type: 'info' });
        return;
      }
    } else if (side === 'toRight') {
      if (!selectedLeftCategoryProductsIds.length) {
        notify('Потрібно вибрати хоча б один продукт у лівій таблиці', { type: 'info' });
        return;
      }
      if (!rightSelectedCategory) {
        notify('Потрібно вибрати категорію в правій таблиці', { type: 'info' });
        return;
      }
    }
    setDirection(side);
    setOpen(true);
  };

  const handleConfirm = () => {
    setOpen(false);
    if (direction === 'toLeft') {
      moveToLeftCategory();
    } else if (direction === 'toRight') {
      moveToRightCategory();
    }
  };

  const updateCategoryProducts = data => {
    update(
      {
        type: 'update',
        resource: `catalog/category/products-to-categories`,
        payload: {
          id: 'update',
          data,
        },
      },
      {
        onSuccess: () => {
          notify('Продукти переміщено');
          setSelectedLeftCategoryProductsIds([]);
          setSelectedRightCategoryProductsIds([]);
          setRefetchProducts(true);
        },
        onFailure: error => {
          console.log(error);
          notify('Помилка: продукти не оновлено', { type: 'warning' });
        },
      }
    );
  };

  const moveToRightCategory = () => {
    // at first remove products from left category
    const leftProducts = leftCategoryProducts.filter(
      cp => !selectedLeftCategoryProductsIds.includes(cp.id)
    );
    const rightProducts = rightCategoryProducts.concat(
      leftCategoryProducts.filter(cp => selectedLeftCategoryProductsIds.includes(cp.id))
    );

    updateCategoryProducts([
      {
        products: leftProducts,
        productType: leftSelectedCategory.productType,
        categoryId: leftSelectedCategory.categoryId,
      },
      {
        products: rightProducts,
        productType: rightSelectedCategory.productType,
        categoryId: rightSelectedCategory.categoryId,
      },
    ]);
  };

  const moveToLeftCategory = () => {
    // at first remove products from right category
    const rightProducts = rightCategoryProducts.filter(
      cp => !selectedRightCategoryProductsIds.includes(cp.id)
    );
    const leftProducts = leftCategoryProducts.concat(
      rightCategoryProducts.filter(cp => selectedRightCategoryProductsIds.includes(cp.id))
    );

    updateCategoryProducts([
      {
        products: rightProducts,
        productType: rightSelectedCategory.productType,
        categoryId: rightSelectedCategory.categoryId,
      },
      {
        products: leftProducts,
        productType: leftSelectedCategory.productType,
        categoryId: leftSelectedCategory.categoryId,
      },
    ]);
  };

  const confirmBodyText = `Перемістити ${
    direction === 'toLeft'
      ? selectedRightCategoryProductsIds.length
      : selectedLeftCategoryProductsIds.length
  } елемент(ів)?`;

  return (
    <div>
      <BackButton />
      <div className={classes.wrapper}>
        <ProductsToCategoriesDataGrid
          data={leftCategoryProducts}
          setData={setLeftCategoryProducts}
          setProductsCategory={setLeftSelectedCategory}
          selectedProductsIds={selectedLeftCategoryProductsIds}
          setSelectedProductsIds={setSelectedLeftCategoryProductsIds}
          updateLoading={updateLoading}
          setRefetchProducts={setRefetchProducts}
          refetchProducts={refetchProducts}
        />
        <div className={classes.buttonWrapper}>
          <IconButton style={{ position: 'fixed' }} onClick={() => handleDialogOpen('toLeft')}>
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            style={{ position: 'fixed', marginTop: 80 }}
            onClick={() => handleDialogOpen('toRight')}
          >
            <ArrowForwardIcon />
          </IconButton>
        </div>
        <ProductsToCategoriesDataGrid
          data={rightCategoryProducts}
          setData={setRightCategoryProducts}
          setProductsCategory={setRightSelectedCategory}
          selectedProductsIds={selectedRightCategoryProductsIds}
          setSelectedProductsIds={setSelectedRightCategoryProductsIds}
          updateLoading={updateLoading}
          setRefetchProducts={setRefetchProducts}
          refetchProducts={refetchProducts}
        />
      </div>
      <Confirm
        isOpen={open}
        title="Перемістити продукти?"
        content={confirmBodyText}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </div>
  );
};

export default withStyles(styles)(ProductsToCategories);
