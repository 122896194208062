import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomCreate } from '../../../common/CustomCreate';
import { OrderTypesForm } from './components/OrderTypesForm';

const OrderTypesTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? <span>{translate('resources.system/order-types.title')}</span> : null;
};

export const OrderTypesCreate = props => (
  <CustomCreate title={<OrderTypesTitle />} component="div" {...props}>
    <Form>
      <OrderTypesForm />
    </Form>
  </CustomCreate>
);
