import React from 'react';
import { Create, Form } from 'react-admin';
import { onSaveConditionsDiscount } from '../helpers';
import { ConditionDiscountsForm } from './components/ConditionDiscountsForm';

export const ConditionDiscountsCreate = ({ classes, ...props }) => (
  <Create mutationMode="optimistic" {...props} transform={onSaveConditionsDiscount}>
    <Form>
      <ConditionDiscountsForm />
    </Form>
  </Create>
);
