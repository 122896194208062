import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useTranslate, useLocale, useSetLocale, Title } from 'react-admin';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
});

const Configuration = () => {
  const translate = useTranslate();
  const locale = useLocale();
  const setLocale = useSetLocale();
  const classes = useStyles();
  //const theme = useSelector(state => state.theme);
  //const dispatch = useDispatch();
  return (
    <Card>
      <Title title={translate('pos.configuration')} />
      {/*<CardContent>
            <div className={classes.label}>{translate('pos.theme.name')}</div>
            <Button
                variant="raised"
                className={classes.button}
                color={theme === 'light' ? 'primary' : 'default'}
                onClick={() => dispatch(changeTheme('light'))}
            >
                {translate('pos.theme.light')}
            </Button>
            <Button
                variant="raised"
                className={classes.button}
                color={theme === 'dark' ? 'primary' : 'default'}
                onClick={() => dispatch(changeTheme('dark'))}
            >
                {translate('pos.theme.dark')}
            </Button>
        </CardContent>*/}
      <CardContent>
        <div className={classes.label}>{translate('pos.language')}</div>
        <Button
          variant="contained"
          className={classes.button}
          color={locale === 'en' ? 'primary' : 'default'}
          onClick={() => setLocale('en')}
        >
          en
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={locale === 'ua' ? 'primary' : 'default'}
          onClick={() => setLocale('ua')}
        >
          ua
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={locale === 'ru' ? 'primary' : 'default'}
          onClick={() => setLocale('ru')}
        >
          ru
        </Button>
      </CardContent>
    </Card>
  );
};

export default Configuration;

/*const mapStateToProps = state => ({
    theme: state.theme,
});

const enhance = compose(
    connect(
        mapStateToProps,
        {
            changeLocale,
            changeTheme,
        }
    ),
    translate,
    withStyles(styles)
);

export default enhance(Configuration);*/
