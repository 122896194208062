import React, { useState, useEffect, useCallback } from 'react';
import { ListContextProvider, useListContext } from 'react-admin';
import { Divider, Tabs, Tab } from '@mui/material';
import {
  STATUS_CANCELED,
  STATUS_DONE,
  STATUS_RECEIVED,
  STATUS_SHIPPED,
  STATUS_PACKED,
  STATUS_RESERVED,
  STATUS_PENDING,
  STATUS_PREORDER_APPROVED,
  STATUS_PREORDER_PENDING,
  STATUS_CHECKOUT_PREVIEW,
} from '../../../models/Order';
import { OrdersTable } from './OrderTable';
import { useStyles } from '../styles';

export const OrdersTableContainer = () => {
  const classes = useStyles();
  const listContext = useListContext();
  const { selectedIds, filterValues, setFilters, displayedFilters } = listContext;
  const [activeTab, setActiveTab] = useState('0');
  const [ordered, setOrdered] = useState([]);
  const [delivered, setDelivered] = useState([]);
  const [cancelled, setCancelled] = useState([]);

  useEffect(() => {
    handleChange(null, activeTab);
  }, []);

  useEffect(() => {
    if (selectedIds) {
      switch (activeTab) {
        case '2':
          setCancelled(selectedIds);
          break;
        case '1':
          setDelivered(selectedIds);
          break;
        case '0':
          setOrdered(selectedIds);
          break;
        default:
          break;
      }
    }
  }, [selectedIds, activeTab]);

  const handleChange = useCallback(
    (event, value) => {
      let statuses = 0;
      switch (value) {
        case '2':
          statuses = STATUS_CANCELED;
          break;
        case '1':
          statuses = [STATUS_RECEIVED, STATUS_DONE];
          break;
        case '0':
          statuses = [
            STATUS_CHECKOUT_PREVIEW,
            STATUS_PREORDER_PENDING,
            STATUS_PREORDER_APPROVED,
            STATUS_PENDING,
            STATUS_RESERVED,
            STATUS_PACKED,
            STATUS_SHIPPED,
          ];
          break;
        default:
          break;
      }
      setFilters && setFilters({ ...filterValues, orderStatus: statuses }, displayedFilters, true);
      setActiveTab(value);
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <>
      <Tabs
        variant="fullWidth"
        centered
        value={activeTab}
        indicatorColor="primary"
        onChange={handleChange}
      >
        <Tab className={classes.tab} label="В работе" value="0" />
        <Tab className={classes.tab} label="Выполнены" value="1" />
        <Tab className={classes.tab} label="Отменены" value="2" />
      </Tabs>
      <Divider />
      <div>
        {activeTab === '0' && (
          <ListContextProvider value={{ ...listContext, selectedIds: ordered }}>
            <OrdersTable />
          </ListContextProvider>
        )}
        {activeTab === '1' && (
          <ListContextProvider value={{ ...listContext, selectedIds: delivered }}>
            <OrdersTable />
          </ListContextProvider>
        )}
        {activeTab === '2' && (
          <ListContextProvider value={{ ...listContext, selectedIds: cancelled }}>
            <OrdersTable />
          </ListContextProvider>
        )}
      </div>
    </>
  );
};
