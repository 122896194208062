import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import MTextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Loading,
  useDataProvider,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
  ArrayField,
  SingleFieldList,
  ChipField,
  NumberField,
  Button,
  DatagridConfigurable,
} from 'react-admin';
import CategoryReferenceInput from '../../home-page/featured-products-sections/CategoryReferenceInput';
import ThumbnailField from '../products/ThumbnailField';
import ProductPanel from '../products/ProductView';
import ShowButton from '../products/bulk-actions/show';
import HideButton from '../products/bulk-actions/hide';
import { RedirectField } from '../../../fields/RedirectField';
import LocalDateField from '../../../fields/LocalDateField';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';
import { sortFunc } from './tools';

const styles = {
  searchForm: {
    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
      margin: 0,
      '& > div': {
        marginRight: 20,
      },
      '& > div:last-child': {
        width: '100%',
      },
    },
  },
  pagination: {
    backgroundColor: '#fff',
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: 20,
  },
}));

const ProductBulkActionButtons = (props) => (
  <React.Fragment>
    <ShowButton {...props} />
    <HideButton {...props} />
  </React.Fragment>
);

function TablePaginationActions(props) {
  const classes = useStyles();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

const ProductsToCategoriesDataGrid = ({
  data,
  setData,
  setProductsCategory,
  selectedProductsIds,
  setSelectedProductsIds,
  updateLoading,
  classes,
  refetchProducts,
  setRefetchProducts,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [formData, setFormData] = useState(null);
  const [filterQuery, setFilterQuery] = useState('');
  const [currentSort, setCurrentSort] = useState({ field: 'name', order: 'ASC' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const dataProvider = useDataProvider();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterFn = (lookup) => (product) =>
    product.code.indexOf(lookup) >= 0 || product.name.toLowerCase().indexOf(lookup) >= 0;

  const setSort = ({ field, order }) => {
    setCurrentSort({ field, order });
  };

  const sortF = sortFunc(currentSort.field, currentSort.order);
  const dataFiltered = (filterQuery.length < 2 ? data : data.filter(filterFn(filterQuery))).sort(
    sortF
  );

  // horrible fix for admin ReferenceInput resource
  useEffect(() => {
    setTimeout(() => {
      setShowForm(true);
    }, 0);
  }, []);

  useEffect(() => {
    if (formData && refetchProducts) {
      fetchCategoryProducts();
      setRefetchProducts(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchProducts]);

  function fetchCategoryProducts({ productType, catalogCategory } = formData) {
    setError();
    setLoading(true);
    setProductsCategory(null);
    dataProvider
      .getOne(`catalog/v2/category/${productType}/products`, { id: catalogCategory || 'unplaced' })
      .then(({ data }) => {
        setData(data);
        setProductsCategory({
          productType,
          categoryId: catalogCategory,
        });
      })
      .catch((error) => {
        setError(error);
        setProductsCategory(null);
      })
      .finally(() => {
        setPage(0);
        setLoading(false);
        setSelectedProductsIds([]);
      });
  }

  const getCategoryProducts = (formValues) => {
    setFormData(formValues);
    fetchCategoryProducts(formValues);
  };

  const toggleCategorySelected = (recordId) => {
    if (selectedProductsIds.includes(recordId)) {
      setSelectedProductsIds((ids) => ids.filter((id) => id !== recordId));
    } else {
      setSelectedProductsIds((prods) => [...prods, recordId]);
    }
  };

  // if (error) return <p>ERROR</p>;

  const tableData =
    rowsPerPage > 0
      ? dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : dataFiltered;
  const tableDataIds = (
    rowsPerPage > 0
      ? dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : dataFiltered
  ).map(({ id }) => id);

  return (
    <div>
      {showForm && (
        <SimpleForm toolbar={false} className={classes.searchForm}>
          <ReferenceInput
            reference="system/product-types"
            source={'productType'}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name" label="1с тип продукта" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData &&
              formData.productType && (
                <CategoryReferenceInput
                  label="Категория"
                  productType={formData.productType}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <div>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <Button
                  label="ra.action.search"
                  onClick={() => getCategoryProducts(formData)}
                  disabled={loading}
                />
              )}
            </FormDataConsumer>
          </div>
        </SimpleForm>
      )}
      {loading || updateLoading ? <Loading /> : null}
      {error && !(loading || updateLoading) ? <p>ERROR</p> : null}
      {!error && !(loading || updateLoading) ? (
        <div>
          <Grid container justify={'space-between'} spacing={6}>
            <Grid item xs>
              <MTextField
                onChange={(e) => setFilterQuery(e.currentTarget.value.toLowerCase())}
                value={filterQuery}
                label="Поиск по имени"
              />
            </Grid>
          </Grid>
          {selectedProductsIds && selectedProductsIds.length ? (
            <ProductBulkActionButtons
              selectedIds={selectedProductsIds}
              onSuccessCallBack={fetchCategoryProducts}
            />
          ) : null}
          <DatagridConfigurable
            data={tableData}
            ids={tableDataIds}
            sort={currentSort}
            onToggleItem={toggleCategorySelected}
            selectedIds={selectedProductsIds}
            onSelect={setSelectedProductsIds}
            expand={<ProductPanel />}
            resource="catalog/products-to-categories/expand-panel"
            hasBulkActions
            setSort={setSort}
          >
            <ThumbnailField label="Фото" />
            <BooleanColoredField source="hidden" label="Скрыт" />
            <BooleanColoredField source="showOnMainPage" label="Для главной" />
            <ArrayField source="stock" label="Количество на складах">
              <SingleFieldList>
                <ChipField source="inStock" />
              </SingleFieldList>
            </ArrayField>
            <TextField source="name" label="Имя" />
            <RedirectField
              source="code"
              linkIdSource="id"
              linkUrl="catalog/products"
              label="Код товара"
            />
            <TextField source="fullName" label="Полное имя" />
            <TextField source="alias" />
            <NumberField source="inPackageCount" label="В упаковке" />
            <LocalDateField source="createdAt" label="Дата создания" showTime />
            <LocalDateField source="updatedAt" label="Дата редактирования" showTime />
          </DatagridConfigurable>
          <TablePagination
            component="div"
            className={classes.pagination}
            rowsPerPageOptions={[50, 100, 250, { label: 'Все', value: -1 }]}
            colSpan={3}
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Строк на странице:"
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </div>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(ProductsToCategoriesDataGrid);
