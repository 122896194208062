import React from 'react';
import { CustomEdit } from '../../../common/CustomEdit';
import CashRegisterForm from './CashRegisterForm';

const CashRegisterEdit = props => (
  <CustomEdit redirect="list" {...props}>
    <CashRegisterForm />
  </CustomEdit>
);

export default CashRegisterEdit;
