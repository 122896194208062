import React from 'react';
import { Create } from 'react-admin';
import ProductTypeForm, { transform } from './ProductTypeForm';

const ProductTypeCreate = props => {
  return (
    <Create {...props} transform={transform}>
      <ProductTypeForm />
    </Create>
  );
};

export default ProductTypeCreate;
