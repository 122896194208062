import React from 'react';
import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  NumberInput,
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  required,
  ImageInput,
  BooleanInput,
  ImageField,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import CategoryReferenceInput from './CategoryReferenceInput';
// import { transformImages } from '../../../../utils/imageTransform';

export const styles = {
  width: { width: '5em' },
  widthFormGroup: { display: 'inline-block' },
  height: { width: '5em' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const useStyles = makeStyles({
  inlineBlock: {
    display: 'inline-flex',
    marginRight: '1rem',
  },
});

const unitOptions = [
  {
    name: 'кг',
    alias: 'kg',
    code: '0301',
  },
  {
    name: 'шт',
    alias: 'sht',
    code: '2009',
  },
  {
    name: 'см',
    alias: 'sm',
    code: '0104',
  },
  {
    name: 'пак',
    alias: 'pak',
    code: '2110',
  },
];

async function transform({ images, unit, ...rest }) {
  // return new Promise((res, rej) => {
  //   return transformImages(images).then(unsavedImages => {
  //     res({
  //       unsavedImages,
  //       images,
  //       id: rest.id,
  //       catalogCategory: rest.catalogCategory,
  //       hidden: rest.hidden,
  //       showOnMainPage: rest.showOnMainPage,
  // ! change after attributes will be changed
  // ! also on edit
  //       attributes: rest.attributes,
  //       unit: unit ? unitOptions.find(u => u.code === unit) : null,
  //     });
  //   });
  // });
  return {
    ...rest,
    unit: unit ? unitOptions.find((u) => u.code === unit) : null,
    videos: rest.videos.map((video) => video.url),
  };
}

const ProductCreate = (props) => {
  const classes = useStyles();
  return (
    <Create redirect="details" {...props} transform={transform}>
      <TabbedForm>
        <FormTab label="resources.products.tabs.details">
          <TextInput source="name" />
          <TextInput source="fullName" />
          <TextInput source="code" />
          <TextInput source="alias" />
          <SelectInput source="unit" choices={unitOptions} optionValue="code" />
          <NumberInput source="inPackageCount" formClassName={classes.inlineBlock} />
        </FormTab>
        <FormTab label="resources.products.tabs.attributes" path="attributes">
          <ArrayInput source="attributes">
            <SimpleFormIterator>
              <TextInput source="attribute" formClassName={classes.inlineBlock} />
              <TextInput source="value" formClassName={classes.inlineBlock} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="resources.products.tabs.settings" path="settings">
          <BooleanInput source="hidden" formClassName={classes.inlineBlock} />
          <BooleanInput source="showOnMainPage" formClassName={classes.inlineBlock} />
          <CategoryReferenceInput validate={required()} source="catalogCategory" />
        </FormTab>
        <FormTab label="resources.products.tabs.images" path="images">
          <ImageInput source="images" accept={{ 'image/*': ['.*'] }} multiple={true}>
            <ImageField source="image" />
          </ImageInput>
          <ArrayInput label="Лінки на відео" source="videos">
            <SimpleFormIterator>
              <TextInput source="url" label="Лінк" style={{ width: 450 }} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="resources.products.tabs.stocks-prices" path="stocks-prices">
          <ArrayInput source="prices">
            <SimpleFormIterator>
              <TextInput source="supplier" formClassName={classes.inlineBlock} />
              <TextInput source="priceType" formClassName={classes.inlineBlock} />
              <NumberInput source="price.0" formClassName={classes.inlineBlock} />
              <NumberInput source="price.15" formClassName={classes.inlineBlock} />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="stock">
            <SimpleFormIterator>
              {/** // ! what type of field it would be?
                <ReferenceInput label="label" source="warehouse" reference="warehouses">
                  <SelectInput optionText="name" />
                </ReferenceInput>
               */}
              <TextInput source="warehouse" />
              <NumberInput source="inStock" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default ProductCreate;
