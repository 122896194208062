import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomEdit } from '../../../common/CustomEdit';
import { CronJobEditFormContainer } from './CronJobEditFormContainer';

const CronJobTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? (
    <span>
      {translate('resources.system/cron-jobs.title')}
      &nbsp;{record.name}
    </span>
  ) : null;
};

export const CronJobEdit = props => (
  <CustomEdit title={<CronJobTitle />} component="div" {...props}>
    <Form>
      <CronJobEditFormContainer />
    </Form>
  </CustomEdit>
);
