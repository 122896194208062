import React from 'react';
import { ReferenceInput, SelectInput, TextInput, useRecordContext } from 'react-admin';
import { Card, CardContent, Grid, Box } from '@mui/material';
import { useStyles } from '../styles';
import FormToolbar from '../../../../common/FormToolbar';
import { PageTitle } from '../../../../common/PageTitle';

export const OrderTypesForm = formProps => {
  const classes = useStyles();
  const record = useRecordContext();

  return (
    <Box maxWidth="70em">
      <Card>
        <PageTitle record={record} titleText="Order types" />
        <CardContent className={classes.cardContent}>
          <Grid container>
            <Grid item sm={12} md={4}>
              <TextInput source="code" resource="system/seo/settings" record={record} />
            </Grid>
            <Grid item sm={12} md={4}>
              <ReferenceInput reference="system/suppliers" source="supplier">
                <SelectInput />
              </ReferenceInput>
            </Grid>
            <Grid item sm={12} md={4}>
              <ReferenceInput reference="system/price-types" source="priceType">
                <SelectInput />
              </ReferenceInput>
            </Grid>
          </Grid>
        </CardContent>
        <FormToolbar {...formProps} />
      </Card>
    </Box>
  );
};
