import React from 'react';
import { TextField, NumberField, SearchInput } from 'react-admin';
import { Chip } from '@mui/material';
import { ExpiredCardDetails } from './ExpiredCardDetails';
import LocalDateField from '../../fields/LocalDateField';
import { CustomList } from '../../common/CustomList';
import { NewTabLinkDataGrid } from '../../common/NewTabLinkDataGrid';
import { BooleanColoredField } from '../../fields/BooleanColoredField';

const orderFilter = [
  <SearchInput source="q" alwaysOn />,
  <Chip source="resolved" defaultValue={true} />,
];

export const ExpiredCartsList = props => (
  <CustomList {...props} filters={orderFilter}>
    <NewTabLinkDataGrid expand={<ExpiredCardDetails />} rowClick="edit" padding="checkbox">
      <TextField source="client.customerId" />
      <NumberField source="discount" options={{ style: 'currency', currency: 'UAH' }} />
      <BooleanColoredField source="resolved" />
      <NumberField source="total" options={{ style: 'currency', currency: 'UAH' }} />
      <TextField source="subtotal" />
      <LocalDateField source="createdAt" showTime />
      <LocalDateField source="updatedAt" showTime />
    </NewTabLinkDataGrid>
  </CustomList>
);
