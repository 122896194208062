import React from 'react';
import { Form } from 'react-admin';
import { CustomEdit } from '../../../common/CustomEdit';
import { SliderEditForm } from './components/SliderEditForm';
import { onSave } from './components/sliderSaveTransformer';

export const SliderEdit = ({ classes, ...props }) => (
  <CustomEdit {...props} component="div" transform={onSave}>
    <Form>
      <SliderEditForm />
    </Form>
  </CustomEdit>
);
