import React from 'react';
import { Create, Form } from 'react-admin';
import { onSaveLoyalty } from '../helpers';
import { LoyaltyProgramForm } from './components/LoyaltyProgramForm';

export const LoyaltyProgramCreate = ({ classes, ...props }) => (
  <Create mutationMode="optimistic" {...props} transform={onSaveLoyalty}>
    <Form>
      <LoyaltyProgramForm />
    </Form>
  </Create>
);
