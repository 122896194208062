const nameSort = order => (a, b) => {
  if (order === 'ASC') {
    return a.name.localeCompare(b.name, 'uk-UA', {
      ignorePunctuation: true,
      usage: 'sort',
      numeric: true,
    });
  }
  return b.name.localeCompare(a.name, 'uk-UA', {
    ignorePunctuation: true,
    usage: 'sort',
    numeric: true,
  });
};

const fullNameSort = order => (a, b) => {
  if (order === 'ASC') {
    return a.fullName.localeCompare(b.fullName, 'uk-UA', {
      ignorePunctuation: true,
      usage: 'sort',
      numeric: true,
    });
  }
  return b.fullName.localeCompare(a.fullName, 'uk-UA', {
    ignorePunctuation: true,
    usage: 'sort',
    numeric: true,
  });
};

const codeSort = order => (a, b) => {
  if (order === 'ASC') {
    return a.code.localeCompare(b.code, 'uk-UA', {
      ignorePunctuation: true,
      usage: 'sort',
      numeric: true,
    });
  }
  return b.code.localeCompare(a.code, 'uk-UA', {
    ignorePunctuation: true,
    usage: 'sort',
    numeric: true,
  });
};

const aliasSort = order => (a, b) => {
  if (order === 'ASC') {
    return a.alias.localeCompare(b.alias, 'uk-UA', {
      ignorePunctuation: true,
      usage: 'sort',
      numeric: true,
    });
  }
  return b.alias.localeCompare(a.alias, 'uk-UA', {
    ignorePunctuation: true,
    usage: 'sort',
    numeric: true,
  });
};

const packageCountSort = order => (a, b) => {
  if (
    a.inPackageCount !== undefined &&
    a.inPackageCount !== null &&
    b.inPackageCount !== undefined &&
    b.inPackageCount !== null
  ) {
    if (order === 'ASC') {
      return a.inPackageCount - b.inPackageCount;
    }
    return b.inPackageCount - a.inPackageCount;
  }
  return 0;
};

const updatedAtSort = order => (a, b) => {
  if (order === 'ASC') {
    return a.updatedAt.localeCompare(b.updatedAt, 'uk-UA', {
      ignorePunctuation: true,
      usage: 'sort',
      numeric: true,
    });
  }
  return b.updatedAt.localeCompare(a.updatedAt, 'uk-UA', {
    ignorePunctuation: true,
    usage: 'sort',
    numeric: true,
  });
};

const createdAtSort = order => (a, b) => {
  if (order === 'ASC') {
    return a.createdAt.localeCompare(b.createdAt, 'uk-UA', {
      ignorePunctuation: true,
      usage: 'sort',
      numeric: true,
    });
  }
  return b.createdAt.localeCompare(a.createdAt, 'uk-UA', {
    ignorePunctuation: true,
    usage: 'sort',
    numeric: true,
  });
};

const stockSort = order => (a, b) => {
  if (a.stock && b.stock && a.stock.length && b.stock.length) {
    const aStock = a.stock.reduce((acc, s) => acc + s.inStock, 0);
    const bStock = b.stock.reduce((acc, s) => acc + s.inStock, 0);
    if (order === 'ASC') {
      return aStock - bStock;
    }
    return bStock - aStock;
  }
  return 0;
};

const hiddenSort = order => (a, b) => {
  if (order === 'ASC') {
    return a.hidden - b.hidden;
  }
  return b.hidden - a.hidden;
};

const mainPageSort = order => (a, b) => {
  if (order === 'ASC') {
    return a.showOnMainPage - b.showOnMainPage;
  }
  return b.showOnMainPage - a.showOnMainPage;
};

export function sortFunc(field, order) {
  switch (field) {
    case 'name':
      return nameSort(order);

    case 'fullName':
      return fullNameSort(order);

    case 'code':
      return codeSort(order);

    case 'alias':
      return aliasSort(order);

    case 'inPackageCount':
      return packageCountSort(order);

    case 'updatedAt':
      return updatedAtSort(order);

    case 'createdAt':
      return createdAtSort(order);

    case 'stock':
      return stockSort(order);

    case 'hidden':
      return hiddenSort(order);

    case 'showOnMainPage':
      return mainPageSort(order);

    default:
      return nameSort(order);
  }
}
