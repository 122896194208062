export const COUNTRIES = {
  AD: { code: "AD", name: "Андорра" },
  AE: { code: "AE", name: "Об'Єднані Арабські Емірати" },
  AF: { code: "AF", name: "Афганістан" },
  AG: { code: "AG", name: "Антиґуа І Барбуда" },
  AI: { code: "AI", name: "Анґілья" },
  AL: { code: "AL", name: "Албанія" },
  AM: { code: "AM", name: "Вірменія" },
  AN: { code: "AN", name: "Нідерландські Антильські Острови" },
  AO: { code: "AO", name: "Анґола" },
  AQ: { code: "AQ", name: "Антарктида" },
  AR: { code: "AR", name: "Аргентина" },
  AS: { code: "AS", name: "Американське Самоа" },
  AT: { code: "AT", name: "Австрія" },
  AU: { code: "AU", name: "Австралія" },
  AW: { code: "AW", name: "Аруба" },
  AZ: { code: "AZ", name: "Азербайджан" },
  BA: { code: "BA", name: "Боснія І Герцеговина" },
  BB: { code: "BB", name: "Барбадос" },
  BD: { code: "BD", name: "Бангладеш" },
  BE: { code: "BE", name: "Бельгія" },
  BF: { code: "BF", name: "Буркіна-фасо" },
  BG: { code: "BG", name: "Болгарія" },
  BH: { code: "BH", name: "Бахрейн" },
  BI: { code: "BI", name: "Бурунді" },
  BJ: { code: "BJ", name: "Бенін" },
  BM: { code: "BM", name: "Бермуди" },
  BN: { code: "BN", name: "Бруней-даруссалам" },
  BO: { code: "BO", name: "Болівія" },
  BR: { code: "BR", name: "Бразілія" },
  BS: { code: "BS", name: "Багами" },
  BT: { code: "BT", name: "Бутан" },
  BV: { code: "BV", name: "Острів Буве" },
  BW: { code: "BW", name: "Ботсвана" },
  BY: { code: "BY", name: "Білорусь" },
  BZ: { code: "BZ", name: "Беліз" },
  CA: { code: "CA", name: "Канада" },
  CC: { code: "CC", name: "Кокосові (Кілінґ) Острови" },
  CD: { code: "CD", name: "Демократична Республіка Конґо" },
  CF: { code: "CF", name: "Центральноафриканська Республіка" },
  CG: { code: "CG", name: "Конґо" },
  CH: { code: "CH", name: "Швейцарія" },
  CI: { code: "CI", name: "Кот-д'Ївуар" },
  CK: { code: "CK", name: "Острови Кука" },
  CL: { code: "CL", name: "Чілі" },
  CM: { code: "CM", name: "Камерун" },
  CN: { code: "CN", name: "Китай" },
  CO: { code: "CO", name: "Колумбія" },
  CR: { code: "CR", name: "Коста-Рика" },
  CU: { code: "CU", name: "Куба" },
  CV: { code: "CV", name: "Кабо-верде" },
  CX: { code: "CX", name: "Острів Різдва" },
  CY: { code: "CY", name: "Кіпр" },
  CZ: { code: "CZ", name: "Чеська Республіка" },
  DE: { code: "DE", name: "Німеччина" },
  DJ: { code: "DJ", name: "Джібуті" },
  DK: { code: "DK", name: "Данія" },
  DM: { code: "DM", name: "Домініка" },
  DO: { code: "DO", name: "Домініканська Республіка" },
  DZ: { code: "DZ", name: "Алжир" },
  EC: { code: "EC", name: "Еквадор" },
  EE: { code: "EE", name: "Естонія" },
  EG: { code: "EG", name: "Єгипет" },
  EH: { code: "EH", name: "Західна Сахара" },
  ER: { code: "ER", name: "Ерітрея" },
  ES: { code: "ES", name: "Іспанія" },
  ET: { code: "ET", name: "Ефіопія" },
  FI: { code: "FI", name: "Фінляндія" },
  FJ: { code: "FJ", name: "Фіджі" },
  FK: { code: "FK", name: "Фолклендські Острови" },
  FM: { code: "FM", name: "Мікронезія, Федеративні Штати" },
  FO: { code: "FO", name: "Фарерські Острови" },
  FR: { code: "FR", name: "Франція" },
  GA: { code: "GA", name: "Ґабон" },
  GB: { code: "GB", name: "Велика Британія" },
  GD: { code: "GD", name: "Ґренада" },
  GE: { code: "GE", name: "Грузія" },
  GF: { code: "GF", name: "Французька Ґвіана" },
  GH: { code: "GH", name: "Гана" },
  GI: { code: "GI", name: "Ґібралтар" },
  GL: { code: "GL", name: "Ґренландія" },
  GM: { code: "GM", name: "Ґамбія" },
  GN: { code: "GN", name: "Ґвінея" },
  GP: { code: "GP", name: "Ґваделупа" },
  GQ: { code: "GQ", name: "Екваторіальна Ґвінея" },
  GR: { code: "GR", name: "Греція" },
  GS: { code: "GS", name: "Південна Джорджія та Південні Сандвічеві Острови" },
  GT: { code: "GT", name: "Гватемала" },
  GU: { code: "GU", name: "Ґуам" },
  GW: { code: "GW", name: "Ґвінея-бісау" },
  GY: { code: "GY", name: "Ґайана" },
  HK: { code: "HK", name: "Гонконг" },
  HM: { code: "HM", name: "Острів Херд і Острови Макдональд" },
  HN: { code: "HN", name: "Гондурас" },
  HR: { code: "HR", name: "Хорватія" },
  HT: { code: "HT", name: "Гаїті" },
  HU: { code: "HU", name: "Угорщина" },
  ID: { code: "ID", name: "Індонезія" },
  IE: { code: "IE", name: "Ірландія" },
  IL: { code: "IL", name: "Ізраїль" },
  IN: { code: "IN", name: "Індія" },
  IO: { code: "IO", name: "Британська Територія в Індійському Океані" },
  IQ: { code: "IQ", name: "Ірак" },
  IR: { code: "IR", name: "Іран" },
  IS: { code: "IS", name: "Ісландія" },
  IT: { code: "IT", name: "Італія" },
  JM: { code: "JM", name: "Ямайка" },
  JO: { code: "JO", name: "Йорданія" },
  JP: { code: "JP", name: "Японія" },
  KE: { code: "KE", name: "Кенія" },
  KG: { code: "KG", name: "Киргизстан" },
  KH: { code: "KH", name: "Камбоджа" },
  KI: { code: "KI", name: "Кірібаті" },
  KM: { code: "KM", name: "Комори" },
  KN: { code: "KN", name: "Сент-кітс і Невіс" },
  KP: { code: "KP", name: "Кндр" },
  KR: { code: "KR", name: "Республіка Корея" },
  KW: { code: "KW", name: "Кувейт" },
  KY: { code: "KY", name: "Острови Кайман" },
  KZ: { code: "KZ", name: "Казахстан" },
  LA: { code: "LA", name: "Лаоська Народно-демократична Республіка" },
  LB: { code: "LB", name: "Ліван" },
  LC: { code: "LC", name: "Сент-люсія" },
  LI: { code: "LI", name: "Ліхтенштейн" },
  LK: { code: "LK", name: "Шрі-Ланка" },
  LR: { code: "LR", name: "Ліберія" },
  LS: { code: "LS", name: "Лесото" },
  LT: { code: "LT", name: "Литва" },
  LU: { code: "LU", name: "Люксембург" },
  LV: { code: "LV", name: "Латвія" },
  LY: { code: "LY", name: "Лівійська Арабська Джамахірія" },
  MA: { code: "MA", name: "Марокко" },
  MC: { code: "MC", name: "Монако" },
  MD: { code: "MD", name: "Молдова" },
  MG: { code: "MG", name: "Мадаґаскар" },
  MH: { code: "MH", name: "Маршаллові Острови" },
  MK: { code: "MK", name: "Македонія, Колишня Югославська Республіка" },
  ML: { code: "ML", name: "Малі" },
  MM: { code: "MM", name: "М'Янма" },
  MN: { code: "MN", name: "Монголія" },
  MO: { code: "MO", name: "Макао" },
  MP: { code: "MP", name: "Північні Маріанські Острови" },
  MQ: { code: "MQ", name: "Мартініка" },
  MR: { code: "MR", name: "Мавританія" },
  MS: { code: "MS", name: "Монтсеррат" },
  MT: { code: "MT", name: "Мальта" },
  MU: { code: "MU", name: "Маврикій" },
  MV: { code: "MV", name: "Мальдіви" },
  MW: { code: "MW", name: "Малаві" },
  MX: { code: "MX", name: "Мексика" },
  MY: { code: "MY", name: "Малайзія" },
  MZ: { code: "MZ", name: "Мозамбік" },
  NA: { code: "NA", name: "Намібія" },
  NC: { code: "NC", name: "Нова Каледонія" },
  NE: { code: "NE", name: "Ніґер" },
  NF: { code: "NF", name: "Острів Норфолк" },
  NG: { code: "NG", name: "Ніґерія" },
  NI: { code: "NI", name: "Нікарагуа" },
  NL: { code: "NL", name: "Нідерланди" },
  NO: { code: "NO", name: "Норвеґія" },
  NP: { code: "NP", name: "Непал" },
  NR: { code: "NR", name: "Науру" },
  NU: { code: "NU", name: "Ніуе" },
  NZ: { code: "NZ", name: "Нова Зеландія" },
  OM: { code: "OM", name: "Оман" },
  PA: { code: "PA", name: "Панама" },
  PE: { code: "PE", name: "Перу" },
  PF: { code: "PF", name: "Французька Полінезія" },
  PG: { code: "PG", name: "Папуа-нова Ґвінея" },
  PH: { code: "PH", name: "Філіппіни" },
  PL: { code: "PL", name: "Польща" },
  PM: { code: "PM", name: "Сен-п'єр і Мікелон" },
  PN: { code: "PN", name: "Піткерн" },
  PR: { code: "PR", name: "Пуерто-рико" },
  PS: { code: "PS", name: "Палестина" },
  PT: { code: "PT", name: "Португалія" },
  PW: { code: "PW", name: "Палау" },
  PY: { code: "PY", name: "Параґвай" },
  QA: { code: "QA", name: "Катар" },
  RE: { code: "RE", name: "Реюньйон" },
  RO: { code: "RO", name: "Румунія" },
  RU: { code: "RU", name: "Російська Федерація" },
  RW: { code: "RW", name: "Руанда" },
  SA: { code: "SA", name: "Саудівська Аравія" },
  SB: { code: "SB", name: "Соломонові Острови" },
  SC: { code: "SC", name: "Сейшели" },
  SD: { code: "SD", name: "Судан" },
  SE: { code: "SE", name: "Швеція" },
  SG: { code: "SG", name: "Сінгапур" },
  SH: { code: "SH", name: "Острів Святої Єлени" },
  SI: { code: "SI", name: "Словенія" },
  SJ: { code: "SJ", name: "Острови Свальбард та Ян‑маєн" },
  SK: { code: "SK", name: "Словаччина" },
  SL: { code: "SL", name: "Сьєрра-леоне" },
  SM: { code: "SM", name: "Сан-маріно" },
  SN: { code: "SN", name: "Сенеґал" },
  SO: { code: "SO", name: "Сомалі" },
  SR: { code: "SR", name: "Сурінам" },
  ST: { code: "ST", name: "Сан-томе і Прінсіпі" },
  SV: { code: "SV", name: "Сальвадор" },
  SY: { code: "SY", name: "Сірійська Арабська Республіка" },
  SZ: { code: "SZ", name: "Свазіленд" },
  TC: { code: "TC", name: "Теркс і Кайкос, Острови" },
  TD: { code: "TD", name: "Чад" },
  TF: { code: "TF", name: "Французькі Південні Території" },
  TG: { code: "TG", name: "Того" },
  TH: { code: "TH", name: "Таїланд" },
  TJ: { code: "TJ", name: "Таджикистан" },
  TK: { code: "TK", name: "Токелау" },
  TM: { code: "TM", name: "Туркменістан" },
  TN: { code: "TN", name: "Туніс" },
  TO: { code: "TO", name: "Тонґа" },
  TP: { code: "TP", name: "Східний Тімор" },
  TR: { code: "TR", name: "Туреччина" },
  TT: { code: "TT", name: "Тринідад І Тобаґо" },
  TV: { code: "TV", name: "Тувалу" },
  TW: { code: "TW", name: "Тайвань, Провінція Китаю" },
  TZ: { code: "TZ", name: "Об'єднана Республіка Танзанія" },
  UA: { code: "UA", name: "Україна" },
  UG: { code: "UG", name: "Уганда" },
  UM: { code: "UM", name: "Малі Віддалені Острови Сполучених Штатів" },
  US: { code: "US", name: "США" },
  UY: { code: "UY", name: "Уругвай" },
  UZ: { code: "UZ", name: "Узбекистан" },
  VA: { code: "VA", name: "Папський Престол" },
  VC: { code: "VC", name: "Сент-вінсент 1 Ґренадіни" },
  VE: { code: "VE", name: "Венесуела" },
  VG: { code: "VG", name: "Вірґінські Острови, Британські*" },
  VI: { code: "VI", name: "Вірґінські Острови, Сша *" },
  VN: { code: "VN", name: "В'Єтнам" },
  VU: { code: "VU", name: "Вануату" },
  WF: { code: "WF", name: "Уолліс І Футуна" },
  WS: { code: "WS", name: "Самоа" },
  YE: { code: "YE", name: "Ємен" },
  YN: { code: "YN", name: "Майотта" },
  YU: { code: "YU", name: "Югославія" },
  ZA: { code: "ZA", name: "Південна Африка" },
  ZM: { code: "ZM", name: "Замбія" },
  ZR: { code: "ZR", name: "Заїр" },
  ZW: { code: "ZW", name: "Зімбабве" },
  pk: { code: "pk", name: "Пакистан" }
};
