import React from 'react';
import { SelectInput } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { getUserRegistrationStatusOptions } from '../models/User';

export const ClientStatusSelect = props => {
  const registrationStatus = useWatch({ name: 'registrationStatus' });

  return (
    <SelectInput
      source="registrationStatus"
      choices={getUserRegistrationStatusOptions(registrationStatus)}
      {...props}
    />
  );
};
