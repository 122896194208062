import React from 'react';
import {
  BooleanInput,
  NumberInput,
  useTranslate,
  ReferenceInput,
  SelectInput,
  required,
  useRecordContext,
} from 'react-admin';
import { Card, CardContent, Grid, Box } from '@mui/material';
import { useStyles } from '../styles';
import FormToolbar from '../../../common/FormToolbar';
import { PageTitle } from '../../../common/PageTitle';
import { CountryField } from '../../../fields/CountryField';

const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const PreorderRulesForm = formProps => {
  const translate = useTranslate();
  const classes = useStyles();
  const record = useRecordContext();

  return (
    <Box maxWidth="70em">
      <Card>
        <PageTitle record={record} titleText="Правило предзаказа" />
        <CardContent className={classes.cardContent}>
          <Grid container spacing={5}>
            <Grid item sm={12} md={6}>
              <BooleanInput label="Активный" source="active" record={record} />
              <NumberInput label="Через дней" source="dayMargin" record={record} min={0} />
              <br />
              <ReferenceInput
                source="country"
                reference="sales/shipping/preorder-shipping-rules/get-list/countries"
                label="Страна"
                isRequired
              >
                <SelectInput optionText={<CountryField />} validate={required()} />
              </ReferenceInput>
              <br />
              <ReferenceInput
                source="manufacturer"
                reference="sales/shipping/preorder-shipping-rules/get-list/manufacturers"
                label="Производитель"
              >
                <SelectInput />
              </ReferenceInput>
            </Grid>
            <Grid item sm={12} md={6}>
              {weekDays.map(day => (
                <Grid container spacing={3} alignItems="center" key={day}>
                  <Grid item sm={2}>
                    <BooleanInput label="" source={`days.${day}`} record={record} helperText="" />
                  </Grid>
                  <Grid item sm={4}>
                    {translate(`resources.sales/shipping/locations.days.${day}`)}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </CardContent>
        <FormToolbar {...formProps} />
      </Card>
    </Box>
  );
};
