import React from 'react';
import { CustomEdit } from '../../../common/CustomEdit';
import PriceTypeGroupsForm from './PriceTypeGroupsForm';

const PriceTypeGroupsEdit = props => {
  return (
    <CustomEdit redirect="list" {...props}>
      <PriceTypeGroupsForm />
    </CustomEdit>
  );
};

export default PriceTypeGroupsEdit;
