import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomEdit } from '../../common/CustomEdit';
import { SelfPickupTimesForm, transform } from './SelfPickupTimesForm';

const Title = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? <span>{translate('resources.sales/shipping/delivery-times.name')}</span> : null;
};

export const Edit = props => (
  <CustomEdit title={<Title />} component="div" transform={transform} {...props}>
    <Form>
      <SelfPickupTimesForm />
    </Form>
  </CustomEdit>
);
