import React from 'react';
import { Form } from 'react-admin';
import { CustomEdit } from '../../../common/CustomEdit';
import { onSaveDiscount } from '../helpers';
import { ProductDiscountForm } from './components/ProductDiscountForm';

export const ProductDiscountEdit = ({ classes, ...props }) => (
  <CustomEdit {...props} component="div" transform={onSaveDiscount}>
    <Form>
      <ProductDiscountForm />
    </Form>
  </CustomEdit>
);
