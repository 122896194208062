import React from 'react';
import { ReferenceInput, AutocompleteInput } from 'react-admin';

const CategoryReferenceInput = (props) => {
  return (
    <ReferenceInput
      allowEmpty
      reference={`catalog/categories/quick-list`}
      filter={{ productType: props.productType }}
      sort={{ field: 'name', order: 'ASC' }}
      label={props?.label || 'Категория'}
      source={props?.source || 'catalogCategory'}
      {...props}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  );
};

export default CategoryReferenceInput;
