import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FalseIcon from '@mui/icons-material/Clear';
import TrueIcon from '@mui/icons-material/Done';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { RedirectField } from '../../fields/RedirectField';
import { ClientDataView } from '../../common/ClientDataView';
import ImagePreview from '../../common/ImagePreview';

const useStyles = makeStyles({
  table: {
    width: 'auto',
  },
  orderInfo: {
    display: 'flex',
    '& > div': {
      width: 'auto',
    },
    '& > div:first-child': {
      marginRight: 40,
    },
  },
  reclamationImages: {
    display: 'flex',
    margin: 10,
    flexWrap: 'wrap',
  },
});

export const OrdersReclamationsDetails = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const classes = useStyles();
  const { images, order, reclamation, comment } = record;
  return (
    <div>
      <Grid item>
        <Typography variant="body2">
          {translate('resources.reclamations.fields.reclamation')}:&nbsp;{reclamation}
        </Typography>
      </Grid>
      <br />
      <Grid item>
        <Typography variant="body2">
          {translate('resources.reclamations.fields.comment')}:&nbsp;{comment}
        </Typography>
      </Grid>
      <br />
      <div className={classes.orderInfo}>
        {order && order.client ? <ClientDataView data={order.client} /> : null}
        {order && order.items && order.items.length ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Код</TableCell>
                  <TableCell>Товар</TableCell>
                  <TableCell align="right">Группа</TableCell>
                  <TableCell align="right">Предзаказ</TableCell>
                  <TableCell align="right">Количество</TableCell>
                  <TableCell align="right">Цена за шт</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.items.map(item => (
                  <TableRow key={`cart-item-${item.id}`}>
                    <TableCell align="left">
                      <RedirectField
                        record={(item.flatOffer && item.flatOffer.product) || {}}
                        source="code"
                        linkIdSource="id"
                        linkUrl="catalog/products"
                        label="Код"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {(item.flatOffer &&
                        item.flatOffer.product &&
                        item.flatOffer.product.fullName) ||
                        ''}
                    </TableCell>
                    <TableCell align="right">
                      {(item.flatOffer &&
                        item.flatOffer.product &&
                        item.flatOffer.product.catalogCategory) ||
                        ''}
                    </TableCell>
                    <TableCell align="right">
                      {item.flatOffer &&
                      item.flatOffer.product &&
                      item.flatOffer.product.hasPreOrder ? (
                        <TrueIcon data-testid="true" />
                      ) : (
                        <FalseIcon data-testid="false" />
                      )}
                    </TableCell>
                    <TableCell align="right">{item.qty || ''}</TableCell>
                    <TableCell align="right">{item.price || ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </div>
      <div className={classes.reclamationImages}>
        {images && images.length
          ? images.map(imageId => (
              <ImagePreview
                urlSource="static/feedback-images"
                imageId={imageId}
                imageStyles={{ width: 500, height: 500 }}
              />
            ))
          : null}
      </div>
    </div>
  );
};
