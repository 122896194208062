import React from 'react';
import { TextField } from 'react-admin';
import { CustomList } from '../../../../common/CustomList';
import { NewTabLinkDataGrid } from '../../../../common/NewTabLinkDataGrid';
import { BooleanColoredField } from '../../../../fields/BooleanColoredField';

const AttributesList = props => {
  return (
    <CustomList {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="name" />
        <TextField source="alias" />
        <TextField source="measurement" />
        <TextField source="code" />
        <TextField source="origin" />
        <BooleanColoredField source="deleted" />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};

export default AttributesList;
