import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomEdit } from '../../common/CustomEdit';
import { PreorderRulesForm } from './components/PreorderRulesForm';
import { transformPreorderRules } from './transformPreorderRules';

const PreorderRulesTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? (
    <span>{translate('resources.sales/shipping/preorder-shipping-rules.name')}</span>
  ) : null;
};

export const PreorderRulesEdit = props => (
  <CustomEdit
    title={<PreorderRulesTitle />}
    component="div"
    transform={transformPreorderRules}
    {...props}
  >
    <Form>
      <PreorderRulesForm />
    </Form>
  </CustomEdit>
);
