import React from 'react';
import {
  TextInput,
  SimpleForm,
  BooleanInput,
  SelectArrayInput,
  ReferenceArrayInput,
} from 'react-admin';
import FormToolbar from '../../../common/FormToolbar';

const PriceTypeGroupsForm = props => {
  return (
    <SimpleForm style={{ width: '100%' }} {...props} toolbar={<FormToolbar />}>
      <TextInput source="name" />
      <BooleanInput source="deleted" />
      <BooleanInput source="isDefault" />
      <ReferenceArrayInput source="priceTypes" reference="system/price-types">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};

export default PriceTypeGroupsForm;
