import PriceTypeGroupsList from './PriceTypeGroupsList';
import PriceTypeGroupsCreate from './PriceTypeGroupsCreate';
import PriceTypeGroupsEdit from './PriceTypeGroupsEdit';
import Icon from '@mui/icons-material/House';

export default {
  list: PriceTypeGroupsList,
  create: PriceTypeGroupsCreate,
  edit: PriceTypeGroupsEdit,
  icon: Icon,
};
