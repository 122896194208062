import React from 'react';
import {
  AppBar,
  UserMenu,
  MenuItemLink,
  useTranslate,
  Logout,
  LoadingIndicator,
  useTheme,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { SELF_NAME } from '../consts';
import { IconButton } from '@mui/material';

const CustomUserMenu = () => {
  const translate = useTranslate();
  return (
    <UserMenu>
      <MenuItemLink
        to="/configuration"
        primaryText={translate('pos.configuration')}
        leftIcon={<SettingsIcon />}
      />
      <Logout />
    </UserMenu>
  );
};

const ThemeToggleButton = () => {
  const [theme, setTheme] = useTheme(localStorage.getItem('theme') || 'light');

  const handleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <IconButton color="inherit" onClick={handleTheme}>
      {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
};

const CustomToolbar = () => (
  <>
    <ThemeToggleButton />
    <LoadingIndicator />
  </>
);

const CustomAppBar = () => (
  <AppBar
    style={{ backgroundColor: '#8e3fbe' }}
    userMenu={<CustomUserMenu />}
    toolbar={<CustomToolbar />}
  >
    <Typography
      variant="h6"
      color="inherit"
      sx={{
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'flex',
      }}
      id="react-admin-title"
    />
    <span style={{ flex: 1 }}>{SELF_NAME}</span>
  </AppBar>
);

export default CustomAppBar;
