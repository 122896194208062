import React from 'react';
import { TextInput, TextField, SingleFieldList, ArrayField, useRecordContext } from 'react-admin';
import Chip from '@mui/material/Chip';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { CustomList } from '../../../common/CustomList';

const priceTypeGroupsFilter = [<TextInput source="q" alwaysOn label="Поиск" />];

const Keyword = () => {
  const record = useRecordContext();
  return record ? <Chip label={record} /> : null;
};

export const SearchKeywordsList = props => {
  return (
    <CustomList filters={priceTypeGroupsFilter} {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="keyword" />
        <ArrayField source="replacement">
          <SingleFieldList>
            <Keyword />
          </SingleFieldList>
        </ArrayField>
      </NewTabLinkDataGrid>
    </CustomList>
  );
};
