import React from 'react';
import { SearchInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { filterToQuery } from '../home-page/offer/OfferCreate';
import { CustomList } from '../../common/CustomList';
import { OutletsTable } from './OutletsTable';

const locationsFilter = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    label="Клиент"
    reference="clients"
    source={'client'}
    filterToQuery={filterToQuery}
  >
    <AutocompleteInput optionText="username" />
  </ReferenceInput>,
];

export const OutletsList = props => (
  <CustomList filters={locationsFilter} {...props}>
    <OutletsTable />
  </CustomList>
);

export default OutletsList;
