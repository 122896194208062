import React, { useState } from 'react';
import { Loading, useNotify, useGetList, useUpdate, useCreate } from 'react-admin';
import { SystemConfigsView } from './SystemConfigsView';

function transform(rawData) {
  return rawData ? rawData.reduce((acc, i) => ({ ...acc, [i.name]: i.value }), {}) : [];
}

const SystemConfigsContainer = () => {
  const notify = useNotify();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [update] = useUpdate();
  const [create] = useCreate();

  const { data, isLoading, error, refetch } = useGetList('settings', {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'id', order: 'ASC' },
    filter: {},
  });

  const settingsData = transform(data);

  const updateSetting = ({ key, value }) =>
    update(
      'settings',
      {
        id: key,
        data: { value },
      },
      { returnPromise: true }
    );

  const createSetting = ({ key, value }) =>
    create(
      'settings',
      {
        data: { name: key, value },
      },
      { returnPromise: true }
    );

  const onSubmit = formData => {
    setUpdateLoading(true);
    Promise.all(
      Object.entries(formData).map(i => {
        return settingsData.hasOwnProperty([i[0]])
          ? updateSetting({ key: i[0], value: i[1] })
          : createSetting({ key: i[0], value: i[1] });
      })
    )
      .then(() => {
        notify('Настройки обновлены');
        refetch();
      })
      .catch(() => notify('Произошла ошибка', { type: 'warning' }))
      .finally(() => setUpdateLoading(false));
  };

  if (isLoading) return <Loading />;
  if (error) return <p>ERROR</p>;
  if (!data) return <p>no data</p>;

  return (
    <SystemConfigsView data={settingsData} onSubmit={onSubmit} updateLoading={updateLoading} />
  );
};

export const SystemConfigs = SystemConfigsContainer;
