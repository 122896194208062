import React from 'react';
import classnames from 'classnames';
import { Button, useNotify, useTranslate } from 'react-admin';
import { ImportExportOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import classes from './styles.module.scss';

export const ExportSyncButton = props => {
  const {
    className,
    label = 'ra.action.export',
    disabled,
    exporting = false,
    submitOnEnter,
    variant = 'contained',
    icon = ImportExportOutlined,
    onClick,
    invalid,
    transform,
    children,
    ...rest
  } = props;
  const notify = useNotify();
  const translate = useTranslate();

  const handleClick = event => {
    if (exporting) {
      // prevent double submission
      event.preventDefault();
    } else {
      if (invalid) {
        notify('ra.message.invalid_form', { type: 'warning' });
      }
      // always submit form explicitly regardless of button type
      if (event) {
        event.preventDefault();
      }
    }

    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  const type = submitOnEnter ? 'submit' : 'button';
  const displayedLabel = label && translate(label, { _: label });

  const iconClassName = classnames(classes.leftIcon, classes.icon);

  return (
    <Button
      className={classnames(classes.button, className)}
      variant={variant}
      type={type}
      onClick={handleClick}
      color={exporting ? 'default' : 'primary'}
      aria-label={displayedLabel}
      disabled={disabled}
      {...sanitizeButtonRestProps(rest)}
    >
      <>
        {exporting ? (
          <CircularProgress size={18} thickness={2} className={iconClassName} />
        ) : (
          icon && React.cloneElement(icon, { className: iconClassName })
        )}
        {displayedLabel}
      </>
    </Button>
  );
};

const sanitizeButtonRestProps = ({
  // The next props are injected by Toolbar
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  onSave,
  pristine,
  redirect,
  resource,
  saving,
  submitOnEnter,
  ...rest
}) => rest;

ExportSyncButton.defaultProps = {
  icon: <ImportExportOutlined />,
  label: 'Експорт',
};
