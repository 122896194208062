import React from 'react';
import {
  TextField,
  EmailField,
  SearchInput,
  ReferenceField,
  ChipField,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useRecordContext,
} from 'react-admin';
import Chip from '@mui/material/Chip';
import UserStatusField from '../../fields/UserStatusField';
import LocalDateField from '../../fields/LocalDateField';
import { NewTabLinkDataGrid } from '../../common/NewTabLinkDataGrid';
import { CustomList } from '../../common/CustomList';
import rowStyle from './rowStyle';
import { ClientListAside } from './ClientListAside';

const headerRow = {
  padding: '0 0 40px 0',
  whiteSpace: 'nowrap',
  borderLeftColor: 'transparent',
  borderLeftWidth: 5,
  borderLeftStyle: 'solid',
};

const clientFilter = [
  <BooleanInput source="hasCredit" label="Мають кредит" />,
  <SearchInput source="email" placeholder="Email" />,
  <SearchInput source="phone" placeholder="Телефон" />,
  <SearchInput source="profile.businessName" placeholder="Имя" />,
  <ReferenceArrayInput source="merchant" reference="sales/business/merchants" label="Merchant">
    <AutocompleteArrayInput multiple={true} />
  </ReferenceArrayInput>,
  <AutocompleteArrayInput
    source="paymentMethods"
    label="Payment Methods"
    multiple={true}
    choices={[
      { id: 'cash', name: 'Готівка' },
      { id: 'liqpay', name: 'LiqPay' },
      { id: 'credit', name: 'Кредитна лінія' },
    ]}
  />,
];

const PaymentTypesList = () => {
  const record = useRecordContext();
  return record && record.preferences && record.preferences.paymentMethods
    ? Object.keys(record.preferences.paymentMethods).map((m) =>
        record.preferences.paymentMethods[m] ? <Chip label={m} /> : null
      )
    : null;
};

export const ClientList = ({ ...props }) => (
  <CustomList filters={clientFilter} aside={<ClientListAside />} {...props}>
    <NewTabLinkDataGrid rowClick="edit" style={headerRow} rowStyle={rowStyle}>
      <TextField source="profile.customerId" />
      <TextField source="profile.code" />
      <TextField source="username" />
      <ReferenceField
        reference={'system/suppliers'}
        source="profile.supplier"
        label="resources.users.tabs.region"
      >
        <ChipField source={'displayName'} />
      </ReferenceField>
      <TextField source="profile.businessName" />
      <EmailField source="email" />
      <TextField source="phone" />
      <TextField source="role" />
      <TextField source="declineReason" />
      <ReferenceField
        source="profile.priceTypeGroup"
        reference={'system/price-type-groups'}
        link={false}
      >
        <TextField source={'name'} />
      </ReferenceField>
      <ReferenceField source="profile.merchant" reference={'sales/business/merchants'}>
        <ChipField source={'name'} />
      </ReferenceField>
      <PaymentTypesList label="resources.client/payment-types.fields.paymentType" />
      <UserStatusField />
      <LocalDateField source="createdAt" showTime />
      <LocalDateField source="updatedAt" showTime />
    </NewTabLinkDataGrid>
  </CustomList>
);
