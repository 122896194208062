import React from 'react';
import { SearchInput } from 'react-admin';
import { CustomList } from '../../../common/CustomList';
import { LoyaltyProgramsTable } from './components/LoyaltyProgramsTable';

const loyaltyProgramsFilters = [<SearchInput source="q" alwaysOn />];

export const LoyaltyProgramsList = props => (
  <CustomList filters={loyaltyProgramsFilters} {...props}>
    <LoyaltyProgramsTable />
  </CustomList>
);
