import React from 'react';
import { CustomEdit } from '../../../common/CustomEdit';
import CashierForm from './CashierForm';

const CashierEdit = props => (
  <CustomEdit redirect="list" {...props}>
    <CashierForm />
  </CustomEdit>
);

export default CashierEdit;
