import React from 'react';
import { TextInput, TextField, BooleanInput } from 'react-admin';
import LocalDateField from '../../../fields/LocalDateField';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const errorsFilter = [
  <TextInput source="message" alwaysOn />,
  <BooleanInput source="unique" alwaysOn />,
];

const ErrorList = props => {
  return (
    <CustomList filters={errorsFilter} {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="file" />
        <TextField source="message" />
        <TextField source="total" />
        <LocalDateField source="createdAt" showTime />
        <LocalDateField source="updatedAt" showTime />
        <BooleanColoredField source="isCritical" />
        <BooleanColoredField source="deleted" />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};

export default ErrorList;
