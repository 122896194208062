import React from 'react';
import { Form } from 'react-admin';
import { CustomEdit } from '../../../common/CustomEdit';
import { onSaveSpecialOffers } from '../helpers';
import { SpecialOfferForm } from './components/SpecialOfferForm';

export const SpecialOfferEdit = ({ classes, ...props }) => (
  <CustomEdit {...props} component="div" transform={onSaveSpecialOffers}>
    <Form>
      <SpecialOfferForm />
    </Form>
  </CustomEdit>
);
