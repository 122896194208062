import React from 'react';
import {
  TextInput,
  required,
  // SelectInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  BooleanInput,
  useRecordContext,
} from 'react-admin';
import { Card, CardContent, Grid, Box } from '@mui/material';
// import Typography from '@mui/material/Typography';
import FormToolbar from '../../../../common/FormToolbar';
import { modifyConditionsToArray } from '../../helpers';
import { useStyles } from '../styles';
import { CategoryField } from './CategoryField';
// import { weekDays } from '../../../../../constants/weekDays';

export const LoyaltyProgramForm = formProps => {
  const classes = useStyles();
  const record = useRecordContext();

  const modifiedRecord = modifyConditionsToArray(record);

  return (
    <Box maxWidth="70em">
      <Card>
        <CardContent>
          <Grid container spacing={8}>
            <Grid item sm={12} md={4}>
              <BooleanInput
                label="resources.sales/discounts/rules/balance.fields.isActive"
                source="isActive"
                record={record}
                fullWidth
              />
              <TextInput
                label="resources.sales/discounts/rules/balance.fields.name"
                source="name"
                record={modifiedRecord}
                validate={[required()]}
                fullWidth
              />
              <CategoryField
                label="resources.sales/discounts/rules/balance.fields.productGroupCode"
                source="productGroupCode"
                record={modifiedRecord}
                fullWidth
              />
              {/* <SelectInput
                label="resources.sales/discounts/rules/balance.fields.checkDay"
                source="checkDay"
                choices={weekDays}
                fullWidth
              />
              <Typography variant="body2">День недели и время проверки баланса</Typography>
              <NumberInput
                source="hours"
                min={0}
                max={23}
                record={modifiedRecord}
                style={{ width: 100, marginRight: 20 }}
              /> */}
              <NumberInput source="minutes" min={0} max={59} style={{ width: 100 }} />
            </Grid>
            <Grid item sm={12} md={8}>
              <ArrayInput
                label="resources.sales/discounts/rules/balance.fields.conditionsArray"
                source="conditionsArray"
                record={modifiedRecord}
              >
                <SimpleFormIterator classes={{ form: classes.conditionsContainer }}>
                  <TextInput
                    className={classes.conditionInput}
                    label="resources.sales/discounts/rules/balance.fields.qty"
                    source="value"
                  />
                  <TextInput
                    className={classes.conditionInput}
                    label="resources.sales/discounts/rules/balance.fields.percent"
                    source="key"
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </CardContent>
        <FormToolbar {...formProps} />
      </Card>
    </Box>
  );
};
