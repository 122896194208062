import uaMessages from './messages/ua.js';
import enMessages from './messages/en.js';
import ruMessages from './messages/ru.js';

import {getCookie, setCookie} from '../utils/cookies';

const messages = {
    ua: uaMessages,
    ru: ruMessages,
    en: enMessages,
};

export const getLocale = () => {
    const cookieLang = getCookie('lang');
    if (messages.hasOwnProperty(cookieLang)) {
        return cookieLang;
    }
    return "ru";
};

export const i18nProvider = locale => {
	  if (locale === getLocale()) {
        // initial call, must return synchronously
        return messages[locale];
    }
    
    // change of locale after initial call returns a promise
    setCookie('lang', locale);
    return messages[locale];
}

export default {
	getLocale,
	i18nProvider
}