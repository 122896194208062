import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { RedirectField } from '../../../fields/RedirectField';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';
import { useStyles } from '../styles';
import { useRecordContext } from 'react-admin';

const OrderItemsTable = () => {
  const record = useRecordContext();
  const classes = useStyles();

  const totalClientPriceAll =
    record.cartItems && record.cartItems.length
      ? record.cartItems.reduce((acc, i) => acc + i.clientPrice * i.qty, 0)
      : 0;

  const totalOfferPriceAll =
    record.cartItems && record.cartItems.length
      ? record.cartItems.reduce((acc, i) => acc + i.offerPrice * i.qty, 0)
      : 0;

  const totalPriceAll =
    record.cartItems && record.cartItems.length
      ? record.cartItems.reduce((acc, i) => acc + i.total, 0)
      : 0;

  const totalQtyAll =
    record.cartItems && record.cartItems.length
      ? record.cartItems.reduce((acc, i) => acc + i.qty, 0)
      : 0;

  return (
    <Table className={classes.table} aria-label="simple table" size="small">
      <TableHead>
        <TableRow>
          <TableCell>Имя</TableCell>
          <TableCell>Код</TableCell>
          <TableCell align="right">Группа</TableCell>
          <TableCell align="right">Предзаказ</TableCell>
          <TableCell align="right">Цена за шт клиента, грн</TableCell>
          <TableCell align="right">Цена за шт офера, грн</TableCell>
          <TableCell align="right">Количество</TableCell>
          <TableCell align="right">Скидка, %</TableCell>
          <TableCell align="right">Сумма, грн</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.cartItems?.length
          ? record.cartItems.map((row, index) => (
              <TableRow key={`cart-item-${row.id}`}>
                <TableCell component="th" scope="row">
                  <RedirectField
                    record={row?.product.fullName || {}}
                    linkIdSource={`cartItems[${index}].product.id]`}
                    linkUrl="catalog/products"
                    fieldText={row?.product?.fullName}
                  />
                </TableCell>
                <TableCell align="left">{row?.product?.code || ''}</TableCell>
                <TableCell align="right">{row.product?.catalogCategory || ''}</TableCell>
                <TableCell align="right">
                  <BooleanColoredField
                    record={row.product}
                    source="hasPreOrder"
                    className={classes.field}
                  />
                </TableCell>
                <TableCell align="right">{row.clientPrice || ''}</TableCell>
                <TableCell align="right">{row.offerPrice || ''}</TableCell>
                <TableCell align="right">{row.qty || ''}</TableCell>
                <TableCell align="right">{row.discountPercent}</TableCell>
                <TableCell align="right">{row.total}</TableCell>
              </TableRow>
            ))
          : null}
        <TableRow>
          <TableCell colSpan={4}>Всего, грн</TableCell>
          <TableCell align="right">{totalClientPriceAll} грн</TableCell>
          <TableCell align="right">{totalOfferPriceAll} грн</TableCell>
          <TableCell align="right">{totalQtyAll}</TableCell>
          <TableCell align="right">{record.discount} грн</TableCell>
          <TableCell align="right">{totalPriceAll} грн</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default OrderItemsTable;
