import React from 'react';
import { TextField, List, SearchInput, DatagridConfigurable } from 'react-admin';
import { CustomPagination } from '../../common/CustomPagination';
import { RedirectField } from '../../fields/RedirectField';

const contactUsFilter = [<SearchInput source="q" alwaysOn placeholder="Поиск" />];

export const ContactUsFormsList = (props) => (
  <List
    pagination={<CustomPagination />}
    {...props}
    filters={contactUsFilter}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <DatagridConfigurable>
      <RedirectField
        source="client.businessName"
        linkIdSource="client.id"
        linkUrl="clients"
        label="Бизнес имя"
      />
      <TextField source="name" />
      <TextField source="phone" />
      <TextField source="email" />
      <TextField source="status" />
      <TextField source="message" />
    </DatagridConfigurable>
  </List>
);
