import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomEdit } from '../../common/CustomEdit';
import { TransactionEditForm } from './components/TransactionEditForm';

const TransactionsTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? (
    <span>
      {translate('resources.sales/orders.nameOne')}
      &nbsp;{record.orderId}
    </span>
  ) : null;
};

export const TransactionsEdit = props => (
  <CustomEdit title={<TransactionsTitle />} component="div" {...props}>
    <Form>
      <TransactionEditForm />
    </Form>
  </CustomEdit>
);
