import * as React from 'react';
import classnames from 'classnames';
import { Card as MuiCard, CardContent } from '@mui/material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { makeStyles } from '@mui/styles';
import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';
import grey from '@mui/material/colors/grey';
import brown from '@mui/material/colors/brown';
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';
import { registrationStatusOptions } from '../../models/User';
import {
  USER_REG_STATUS_NO_REG,
  USER_REG_STATUS_APPROVED,
  USER_REG_STATUS_DOC_REQUIRED,
  USER_REG_STATUS_PENDING,
  USER_REG_STATUS_DECLINED,
} from '../../models/User';

const useStyles = makeStyles({
  listItem: {
    position: 'relative',
    whiteSpace: 'nowrap',

    '&::before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      width: 10,
      height: 10,
      left: 7,
      borderRadius: '50%',
    },
  },
  bgBrown: {
    '&::before': {
      backgroundColor: brown[500],
    },
  },
  bgRed: {
    '&::before': {
      backgroundColor: red[500],
    },
  },
  bgGreen: {
    '&::before': {
      backgroundColor: green[500],
    },
  },
  bgOrange: {
    '&::before': {
      backgroundColor: orange[500],
    },
  },
  bgGrey: {
    '&::before': {
      backgroundColor: grey[500],
    },
  },
});

export const ClientListAside = () => {
  const classes = useStyles();

  return (
    <MuiCard sx={{ order: -2, width: '16em', marginRight: '1em' }}>
      <CardContent>
        <FilterLiveSearch sx={{ minWidth: 100 }} />
        {/* <FilterList label="resources.clients.fields.role" icon={<GroupIcon />}>
          {[
            { id: 'admin', name: 'Admin' },
            { id: 'manager', name: 'Manager' },
            { id: 'user', name: 'User' },
          ].map(item => (
            <FilterListItem label={item.name} key={item.id} value={{ role: item.id }} />
          ))}
        </FilterList> */}
        <FilterList
          label="resources.clients.fields.registrationStatus"
          icon={<AssignmentIndIcon />}
        >
          {registrationStatusOptions.map((item) => (
            <FilterListItem
              className={classnames(classes.listItem, {
                [classes.bgBrown]: item.id === `${USER_REG_STATUS_DECLINED}`,
                [classes.bgRed]: item.id === `${USER_REG_STATUS_DOC_REQUIRED}`,
                [classes.bgGreen]: item.id === `${USER_REG_STATUS_APPROVED}`,
                [classes.bgGrey]: item.id === `${USER_REG_STATUS_NO_REG}`,
                [classes.bgOrange]: item.id === `${USER_REG_STATUS_PENDING}`,
              })}
              label={item.name}
              key={item.id}
              value={{ registrationStatus: item.id }}
            />
          ))}
        </FilterList>
      </CardContent>
    </MuiCard>
  );
};
