import React from 'react';
import { CustomEdit } from '../../../common/CustomEdit';
import MerchantForm from './MerchantForm';

const MerchantEdit = props => (
  <CustomEdit redirect="list" {...props}>
    <MerchantForm />
  </CustomEdit>
);

export default MerchantEdit;
