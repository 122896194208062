import React from 'react';
import { TextInput, SimpleForm, ReferenceInput, AutocompleteInput } from 'react-admin';
import FormToolbar from '../../common/FormToolbar';

export const transform = data => ({ ...data });

const OutletForm = props => {
  return (
    <SimpleForm style={{ width: '100%' }} toolbar={<FormToolbar withExport />} {...props}>
      <TextInput source="code" />
      <TextInput source="name" />
      <TextInput source="region" />
      <TextInput source="city" />
      <TextInput source="street" />
      <TextInput source="building" />
      <TextInput source="latitude" />
      <TextInput source="longitude" />

      <ReferenceInput reference={'clients'} source={'client'}>
        <AutocompleteInput optionText={'username'} />
      </ReferenceInput>
      <ReferenceInput reference={'sales/shipping/locations'} source={'location'} perPage={999}>
        <AutocompleteInput source={'name'} />
      </ReferenceInput>
    </SimpleForm>
  );
};

export default OutletForm;
