import CashRegisterList from './CashRegisterList';
import CashRegisterCreate from './CashRegisterCreate';
import CashRegisterEdit from './CashRegisterEdit';
import Icon from '@mui/icons-material/AllInbox';

export default {
  list: CashRegisterList,
  create: CashRegisterCreate,
  edit: CashRegisterEdit,
  icon: Icon,
};
