export const onSaveLoyalty = ({
  conditions,
  conditionsArray,
  productGroupCode,
  hours,
  minutes,
  ...restData
}) => ({
  ...restData,
  discountType: 'balance',
  allCatalog: true,
  allClients: true,
  conditions: conditionsArray.reduce(
    (acc, c) => ({
      ...acc,
      [c.key]: c.value,
    }),
    {}
  ),
  productGroupCode: restData.type === 'personal' ? '' : productGroupCode,
  checkTime: `${hours}:${minutes}`,
});



function getHours(str) {
  return str && typeof str === 'string' ? Number(str.split(':')[0]) : 0;
}

function getMinutes(str) {
  return str && typeof str === 'string' ? Number(str.split(':')[1]) : 0;
}

export const modifyConditionsToArray = record =>
  Object.assign(record, {
    conditionsArray: record.conditions
      ? Object.keys(record.conditions).reduce(
          (acc, key) => [
            ...acc,
            {
              key,
              value: record.conditions[key],
            },
          ],
          []
        )
      : [],
    hours: getHours(record.checkTime),
    minutes: getMinutes(record.checkTime),
  });

export const extractPercent = record =>
  record.conditions && Object.keys(record.conditions) ? Object.keys(record.conditions)[0] : 0;

export const modifyPercent = record =>
  Object.assign(record, {
    percent: extractPercent(record),
  });

export async function onSaveDiscount({ image, percent, ...restData }) {
  const formData = new FormData();
  if (image) {
    formData.append('files', image.rawFile);
  }

  formData.append(
    'data',
    JSON.stringify({
      ...restData,
      discountType: 'static',
      conditions: {
        [percent]: 0,
      },
    })
  );

  return formData;
}

export async function onSaveWebsiteDiscount({ image, percent, ...restData }) {
  const formData = new FormData();
  if (image) {
    formData.append('files', image.rawFile);
  }

  formData.append(
    'data',
    JSON.stringify({
      ...restData,
      discountType: 'website',
      conditions: {
        [percent]: 0,
      },
    })
  );

  return formData;
}

export const onSaveConditionsDiscount = ({
  conditions,
  conditionsArray,
  image,
  categories1C,
  ...restData
}) => {
  const formData = new FormData();
  if (image) {
    formData.append('files', image.rawFile);
  }

  formData.append(
    'data',
    JSON.stringify({
      ...restData,
      discountType: 'purchased-qty',
      conditions: conditionsArray.reduce(
        (acc, c) => ({
          ...acc,
          [c.key]: c.value,
        }),
        {}
      ),
      percent: null,
      categories1C: restData.categories && restData.categories.length ? [] : categories1C,
      categories: categories1C && categories1C.length ? [] : restData.categories,
      products: categories1C && categories1C.length ? [] : restData.products,
    })
  );

  return formData;
};

export const loyaltyTypesLabel = {
  personal: 'Персональная',
  'product-group': 'Групповая',
};

export const loyaltyTypes = [
  {
    id: 'personal',
    name: 'Персональная',
  },
  {
    id: 'product-group',
    name: 'Групповая',
  },
];

export const offersTypeOptions = [
  {
    id: 'preOrder',
    name: 'Предзаказы',
  },
  {
    id: 'inStock',
    name: 'Сток',
  },
];
