import React from 'react';
import { Form } from 'react-admin';
import { CustomEdit } from '../../../common/CustomEdit';
import { onSaveWebsiteDiscount } from '../helpers';
import { WebsiteDiscountForm } from './components/WebsiteDiscountForm';

export const WebsiteDiscountEdit = ({ classes, ...props }) => (
  <CustomEdit {...props} component="div" transform={onSaveWebsiteDiscount}>
    <Form>
      <WebsiteDiscountForm />
    </Form>
  </CustomEdit>
);
