import React from 'react';
import { Admin, Resource, CustomRoutes } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { authProvider, DataProvider } from './providers';
import Dashboard from './components/pages/dashboard/index';
import i18n from './i18n';
import { Layout } from './layout';
import customRoutes from './routes.jsx';
import orders from './components/pages/orders';
import transactions from './components/pages/transactions';
import ordersReclamations from './components/pages/orders-reclamations';
import expiredCarts from './components/pages/expired-carts';
import activeCarts from './components/pages/active-carts';
import locations from './components/pages/locations';
import deliveryTimes from './components/pages/delivery-times';
import selfPickUpTimes from './components/pages/self-pickup-times';
import outlets from './components/pages/outlets';
import outletsSystem from './components/pages/outlets-system';
import users from './components/pages/users';
import usersPayments from './components/pages/payments';
import orderTypes from './components/pages/system/order-types';
import cronJobs from './components/pages/system/cron-jobs';
import errorLog from './components/pages/system/error-log';
import _1cErrorLog from './components/pages/system/1c-log';
import warehouse from './components/pages/system/warehouse';
import productTypes from './components/pages/system/product-types';
import priceTypes from './components/pages/system/price-types';
import priceTypeGroups from './components/pages/system/price-type-groups';
import supplier from './components/pages/system/supplier';
import seoSettings from './components/pages/system/seoSettings';
import searchQueries from './components/pages/system/searchQueries';
import searchKeywords from './components/pages/system/search-keywords';
import searchPreferences from './components/pages/system/search-preferences';
import clients from './components/pages/clients';
import catalog from './components/pages/catalog';
import featureFlags from './components/pages/feature-flags';
import productAttributes from './components/pages/catalog/products/attributes';
import contactUs from './components/pages/contact-us-form';
import loyaltyProgram from './components/pages/loyalty-programs';
import offerRules from './components/pages/offers';
import preorderRules from './components/pages/preorder-rules';
import { Slider, Offer, FeaturedProductsSections } from './components/pages/home-page';
import { catalogProductTypes } from './components/models/ProductType';
import { Settings } from './components/pages/system/settings';
import merchants from './components/pages/business/merchant';
import cashiers from './components/pages/business/cashier';
import cashRegisters from './components/pages/business/cash-register';
import { staticPages } from './components/pages/cms';
import { theme } from './layout/theme.js';

const App = () => {
  const defaultLocale = i18n.getLocale();
  const i18nProvider = polyglotI18nProvider(i18n.i18nProvider, defaultLocale);

  const dynamicResources = [];
  Object.keys(catalogProductTypes).forEach((alias) => {
    dynamicResources.push(
      <Resource key={`catalog/productType/${alias}`} name={`catalog/productType/${alias}`} />
    );
    dynamicResources.push(<Resource key={`catalog/${alias}`} name={`catalog/${alias}`} />);
  });

  return (
    <Admin
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      dataProvider={DataProvider}
      authProvider={authProvider}
      // loginPage={Login}
      layout={Layout}
      theme={theme}
      darkTheme={{ palette: { mode: 'dark' } }}
    >
      {dynamicResources}
      <Resource name="users" {...users} />
      <Resource name="clients" {...clients} />
      <Resource name="system/error-log" {...errorLog} />
      <Resource name="system/1c-log" {..._1cErrorLog} />
      <Resource name="system/feature-flags" {...featureFlags} />
      <Resource name="system/warehouses" {...warehouse} />
      <Resource name="system/suppliers" {...supplier} />
      <Resource name="system/currencies" />
      <Resource name="system/cron-jobs" {...cronJobs} />
      <Resource name="catalog/products" {...catalog.products} />
      <Resource name="products/bulk" />
      <Resource name="sales/orders" {...orders} />
      <Resource name="sales/orders-refund" {...orders} />
      <Resource name="sales/expired-carts" {...expiredCarts} />
      <Resource name="sales/active-carts" {...activeCarts} />
      <Resource name="sales/transactions" {...transactions} />
      <Resource name="sales/payments" {...usersPayments} />
      <Resource name="sales/payments-refund" {...usersPayments} />
      <Resource name="sales/discounts/rules/website" {...loyaltyProgram.websiteDiscount} />
      <Resource name="sales/discounts/rules/balance" {...loyaltyProgram.loyaltyProgram} />
      <Resource name="sales/discounts/rules/catalog" {...loyaltyProgram.productsDiscount} />
      <Resource name="sales/discounts/rules/progressive" {...loyaltyProgram.conditionDiscounts} />
      <Resource name="sales/offers/special-offers" {...offerRules.specialOffers} />
      <Resource name="sales/offers/transit-offers" {...offerRules.transitOffers} />
      <Resource name="sales/orders-reclamations" {...ordersReclamations} />
      <Resource name="sales/shipping/locations" {...locations} />
      <Resource name="sales/shipping/delivery-times" {...deliveryTimes} />
      <Resource name="sales/shipping/self-pickup-times" {...selfPickUpTimes} />
      <Resource name="sales/shipping/preorder-shipping-rules" {...preorderRules} />
      <Resource name="sales/shipping/preorder-shipping-rules/get-list/countries" />
      <Resource name="sales/shipping/preorder-shipping-rules/get-list/manufacturers" />
      <Resource name="system/migrations/full" />
      <Resource name="system/product-types" {...productTypes} />
      <Resource name="common/primary-product-types" />
      <Resource name="system/price-types" {...priceTypes} />
      <Resource name="system/price-type-groups" {...priceTypeGroups} />
      <Resource name="system/outlets" {...outletsSystem} />
      <Resource name="system/order-types" {...orderTypes} />
      <Resource name="system/seo/settings" {...seoSettings} />
      <Resource name="system/seo/search-queries" {...searchQueries} />
      <Resource name="system/seo/search-keywords" {...searchKeywords} />
      <Resource name="system/seo/search-preferences" {...searchPreferences} />
      <Resource name="client/outlets" {...outlets} />
      <Resource name="client/payment-types" />
      <Resource name="catalog/categories" />
      <Resource name="catalog/categories/quick-list" />
      <Resource name="catalog/productType/all" />
      <Resource name="catalog/category/productTypes/all" />
      <Resource name="catalog/categories-1c" />
      <Resource name="sales/shipping/methods" />
      <Resource name="common/product-types" />
      <Resource name="system/attributes" {...productAttributes} />
      <Resource name="home/sliders" {...Slider} />
      <Resource name="home/offers" {...Offer} />
      <Resource name="home/featured-products-sections" {...FeaturedProductsSections} />
      <Resource name="settings" {...Settings} />
      <Resource name="catalog/products-to-categories/expand-panel" />
      <Resource name="forms/contact-us" {...contactUs} />
      <Resource name="sales/business/merchants" {...merchants} />
      <Resource name="sales/business/cashiers" {...cashiers} />
      <Resource name="sales/business/cash-registers" {...cashRegisters} />
      <Resource name="sales/shipping/locations/regions" />
      <Resource name="cms/static-pages" {...staticPages} />
      <CustomRoutes>{customRoutes}</CustomRoutes>
    </Admin>
  );
};
export default App;
