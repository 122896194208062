import React from 'react';
import { Create, FormTab, TabbedForm, TextInput, SelectInput, BooleanInput } from 'react-admin';
import FormToolbar from '../../common/FormToolbar';

const transform = data => {
  const newData = {
    ...data,
    profile: {
      ...data.profile,
      priceType: data.profile.priceType.id,
    },
  };
  return newData;
};

const UserCreate = props => (
  <Create redirect="list" {...props}>
    <TabbedForm toolbar={<FormToolbar />} transform={transform}>
      <FormTab label="resources.users.tabs.identity">
        <TextInput source="username" autoFocus />
        <TextInput source="password" />
        <TextInput
          type="email"
          source="email"
          validation={{ email: true }}
          fullWidth={true}
          sx={{ width: 544 }}
        />
        <TextInput source="phone" />
        <TextInput source="profile.businessName" />
      </FormTab>
      <FormTab label="resources.users.tabs.system" path="system">
        <SelectInput
          source="status"
          choices={[
            { id: 'active', name: 'Active' },
            { id: 'disabled', name: 'Disabled' },
            { id: 'deleted', name: 'Deleted' },
          ]}
        />
        <SelectInput
          source="role"
          choices={[
            { id: 'user', name: 'Client' },
            { id: 'admin', name: 'Admin' },
            { id: 'manager', name: 'Manager' },
          ]}
        />
        <BooleanInput source='allowMultipleSessions' label='Дозволити авторизацію з кількох девайсів' />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default UserCreate;
