import React from 'react';
import { TextField, TextInput } from 'react-admin';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const productTypesFilter = [<TextInput source="q" alwaysOn label="Поиск" />];

const ProductTypeList = props => {
  return (
    <CustomList filters={productTypesFilter} {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="code" />
        <TextField source="name" />
        <TextField source="alias" />
        <TextField source="sortOrder" />
        <BooleanColoredField source="deleted" />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};

export default ProductTypeList;
