import * as React from 'react';
import { Fragment, useState } from 'react';
import { VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Confirm,
  useRefresh,
  useNotify,
  useUnselectAll,
  useListContext,
  useCreate,
} from 'react-admin';

const HideButton = ({ onSuccessCallBack }) => {
  const { selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('catalog/products');
  const [updateMany, { isLoading }] = useCreate('catalog/products/bulk', undefined, {
    onSuccess: () => {
      refresh();
      notify('Продукти оновлені', { type: 'info' });
      unselectAll();
      if (onSuccessCallBack) {
        onSuccessCallBack();
      }
    },
    onError: (error) => {
      console.error(error);
      notify('Помилка: продукти не оновлені', { type: 'warning' });
    },
  });
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany(undefined, {
      data: {
        ids: selectedIds,
        hidden: true,
      },
    });
    setOpen(false);
  };

  return (
    <Fragment>
      <Button label="Сховати продукти" onClick={handleClick} disabled={isLoading}>
        <VisibilityOff />
      </Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Сховати продукти"
        content="Ви дійсно бажаєте сховати вибрані продукти і не показувати їх на сайті?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default HideButton;
