import React from 'react';
import {
  TextInput,
  SimpleForm,
  SelectInput,
  BooleanInput,
  ReferenceInput,
  required,
} from 'react-admin';
import FormToolbar from '../../../common/FormToolbar';

const CashierForm = props => {
  return (
    <SimpleForm style={{ width: '100%' }} {...props} toolbar={<FormToolbar />}>
      <TextInput source="name" required={true} />
      <TextInput source="login" required={true} />
      <TextInput source="pinCode" required={true} />
      <BooleanInput source="isActive" />
      <ReferenceInput
        source="cashRegister"
        reference="sales/business/cash-registers"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText="name" validate={[required()]} label="Касса" />
      </ReferenceInput>
      <TextInput source="token" disabled={true} label="Checkbox Auth Token" />
    </SimpleForm>
  );
};

export default CashierForm;
