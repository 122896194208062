import React from 'react';
import { TextField, NumberField, ShowButton } from 'react-admin';
import LocalDateField from '../../fields/LocalDateField';
import { NewTabLinkDataGrid } from '../../common/NewTabLinkDataGrid';
import { RedirectField } from '../../fields/RedirectField';
import { BooleanColoredField } from '../../fields/BooleanColoredField';

export const PaymentsTable = props => (
  <NewTabLinkDataGrid rowClick="edit" {...props}>
    <TextField source="alias" />
    <TextField source="name" />
    <TextField source="docType" />
    <TextField source="description" />
    <RedirectField
      source="client.businessName"
      linkIdSource="client.id"
      linkUrl="clients"
      label="Бизнес имя"
    />
    <TextField source="balance_1c" />
    <TextField source="code" />
    <TextField source="currencyCode" />
    <TextField source="status" />
    <TextField source="type" />
    <TextField source="paymentId" />
    <BooleanColoredField source="deleted" />
    <BooleanColoredField source="isSynced" />
    <NumberField source="amount" options={{ style: 'currency', currency: 'UAH' }} />
    <LocalDateField source="createdAt" showTime />
    <LocalDateField source="updatedAt" showTime />
    <LocalDateField source="syncDate" showTime />
    <NumberField source="syncAttempts" />
    <TextField source="currency.name" label={'Валюта'} />
    <ShowButton />
  </NewTabLinkDataGrid>
);
