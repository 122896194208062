import { useRecordContext, useTranslate } from 'react-admin';

export const DaysField = ({ source }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record[source] && typeof record[source] === 'object'
    ? Object.keys(record[source])
        .map(day =>
          record[source][day] ? translate(`resources.sales/shipping/locations.days.${day}`) : ''
        )
        .filter(i => i)
        .join(', ')
    : null;
};
