import React from 'react';
import { NumberField, TextField } from 'react-admin';
import LocalDateField from '../../../fields/LocalDateField';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

/**
  * * * * * *
  | | | | | |
  | | | | | day of week
  | | | | month
  | | | day of month
  | | hour
  | minute
  second ( optional )
 */

export const CronJobsList = props => {
  return (
    <CustomList {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="name" />
        <BooleanColoredField source="isSystem" />
        <TextField source="status" />
        <TextField source="description" />
        <TextField source="tickRate" />
        {/* <TimeoutField /> */}
        <NumberField source="timeout" />
        <NumberField source="executionProgress" />
        <BooleanColoredField reverse source="disabled" />
        <BooleanColoredField source="options.ignoreMaintenance" />
        <BooleanColoredField source="options.silent" />
        <BooleanColoredField source="options.startNow" />
        <BooleanColoredField source="options.runOnInit" />
        <TextField source="lastError" />
        <LocalDateField source="createdAt" showTime />
        <LocalDateField source="updatedAt" showTime />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};
