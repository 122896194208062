import React from 'react';
import { TextInput, TextField, ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import LocalDateField from '../../../fields/LocalDateField';
import { CustomList } from '../../../common/CustomList';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';

const priceTypeGroupsFilter = [<TextInput source="q" alwaysOn label="Поиск" />];

const PriceTypeGroupsList = props => {
  return (
    <CustomList filters={priceTypeGroupsFilter} {...props}>
      <NewTabLinkDataGrid rowClick="edit">
        <TextField source="name" />
        <BooleanColoredField source="deleted" />
        <BooleanColoredField source="isDefault" />
        <ReferenceArrayField source="priceTypes" reference="system/price-types">
          <SingleFieldList link={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <LocalDateField source="createdAt" showTime />
        <LocalDateField source="updatedAt" showTime />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};

export default PriceTypeGroupsList;
