import React from 'react';
import { NumberField, DateInput, SearchInput } from 'react-admin';
import { OrdersReclamationsDetails } from './OrdersReclamationsDetails';
import { RedirectField } from '../../fields/RedirectField';
import { ShortTextField } from '../../fields/ShortTextField';
import LocalDateField from '../../fields/LocalDateField';
import { NewTabLinkDataGrid } from '../../common/NewTabLinkDataGrid';
import { CustomList } from '../../common/CustomList';

const orderFilter = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="createdAt" label={'Дата заказа'} />,
];

export const OrdersReclamationsList = props => (
  <CustomList filters={orderFilter} {...props}>
    <NewTabLinkDataGrid expand={<OrdersReclamationsDetails />}>
      <RedirectField
        fieldText="Заказ"
        linkIdSource="order.id"
        linkUrl="sales/orders"
        label="resources.reclamations.fields.order"
      />
      <ShortTextField source="reclamation" label="resources.reclamations.fields.reclamation" />
      <ShortTextField source="comment" label="resources.reclamations.fields.comment" />
      <NumberField source="ratingQuality" label="resources.reclamations.fields.ratingQuality" />
      <NumberField source="ratingManager" label="resources.reclamations.fields.ratingManager" />
      <NumberField source="ratingDelivery" label="resources.reclamations.fields.ratingDelivery" />
      <LocalDateField source="createdAt" label="resources.reclamations.fields.createdAt" showTime />
      <LocalDateField source="updatedAt" label="resources.reclamations.fields.updatedAt" showTime />
    </NewTabLinkDataGrid>
  </CustomList>
);
