import React from 'react';
import {
  DateInput,
  SearchInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput, BooleanInput,
} from 'react-admin';
import { filterToQuery } from '../home-page/offer/OfferCreate';
import { TransactionsTable } from './TransactionsTable';
import { CustomList } from '../../common/CustomList';

const OPERATIONS_TYPES = ['debit', 'credit'];

export const transactionsFilter = [
  <SearchInput source="q" alwaysOn placeholder="Поиск" />,
  <ReferenceInput label="Клиент" reference="clients" source="client" filterToQuery={filterToQuery}>
    <AutocompleteInput optionText="username" />
  </ReferenceInput>,
  <SelectInput
    source="operationType"
    choices={OPERATIONS_TYPES.map(status => ({ id: status, name: status }))}
  />,
  <DateInput source="createdAt" label="Дата" />,
  <BooleanInput source='onlyCompleted' label="Фактичний баланс" alwaysOn />,
];

export const TransactionsList = props => {
  return (
    <CustomList {...props} filters={transactionsFilter}>
      <TransactionsTable />
    </CustomList>
  );
};
