import React from 'react';
import { API_SERVER } from '../../../../consts';
import { useRecordContext } from 'react-admin';

const ThumbnailField = () => {
  const record = useRecordContext();
  const placeholderImage = '/flower-no-image.svg';
  return (
    <div
      style={{
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: 60,
        height: 60,
        backgroundImage:
          record?.images?.length && record.images[0]
            ? `url(${API_SERVER}/static/product/image/thumbnail/${record.images[0]})`
            : `url(${placeholderImage})`,
      }}
    />
  );
};

export default ThumbnailField;
