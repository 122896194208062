import clientsDiscount from './clientDiscount';
import productsDiscount from './productDiscount';
import loyaltyProgram from './loyalty-program';
import conditionDiscounts from './condition-discounts';
import websiteDiscount from './website-discounts';

export default {
  clientsDiscount,
  productsDiscount,
  loyaltyProgram,
  conditionDiscounts,
  websiteDiscount,
};
