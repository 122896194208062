import React, { Fragment } from 'react';
import {
  DateInput,
  NumberInput,
  FormTab,
  TabbedForm,
  SelectInput,
  TextInput,
  PasswordInput,
  useRecordContext, BooleanInput,
} from 'react-admin';
import { API_SERVER } from '../../../consts';
import FormToolbar from '../../common/FormToolbar';
import { CustomerTitle } from '../../fields/CustomerTitle';
import { CustomEdit } from '../../common/CustomEdit';
import { CustomList } from '../../common/CustomList';
import { OrdersTable } from '../orders/components/OrderTable';
import { TransactionsTable } from '../transactions/TransactionsTable';
import { PaymentsTable } from '../payments/PaymentsTable';
import { ClientStatusSelect } from '../../fields/ClientStatusSelect';
import { UserIPsWhitelist } from '../../fields/UserIPsWhitelist';
import { useParams } from 'react-router-dom';

const ScansList = () => {
  const record = useRecordContext();
  return record && record.profile && record.profile.files && record.profile.files.length > 0 ? (
    <Fragment>
      {record.profile.files.map((fileId, key) => {
        const url = `${API_SERVER}/static/client-uploads/${fileId}.jpg`;
        return (
          <a href={url} key={key} target="_blank" rel="noopener noreferrer">
            <img
              src={url}
              alt={fileId}
              style={{ border: '1px solid gray', margin: 10, maxHeight: 300, maxWidth: 300 }}
            />
          </a>
        );
      })}
    </Fragment>
  ) : null;
};

const transform = data => {
  const { role } = data;
  if (['admin', 'manager'].includes(role)) {
    delete data.profile;
    return data;
  } else {
    return {
      ...data,
      profile: {
        ...data.profile,
        priceType: data.profile.priceType.id,
      },
    };
  }
};

const UserEdit = props => {
  const params = useParams();
  if (!params) return null;
  return (
    <CustomEdit title={<CustomerTitle />} transform={transform} {...props}>
      <TabbedForm toolbar={<FormToolbar />}>
        <FormTab label="resources.users.tabs.identity">
          <TextInput source="username" />
          <TextInput type="email" source="email" validation={{ email: true }} />
          <TextInput source="phone" />
          <TextInput source="profile.firstName" />
          <TextInput source="profile.lastName" />
          <TextInput source="profile.businessName" />
        </FormTab>
        <FormTab label="resources.users.tabs.documents">
          <ScansList />
        </FormTab>
        <FormTab label="resources.users.tabs.system" path="system">
          <PasswordInput source="password" />
          <ClientStatusSelect />
          <SelectInput
            source="role"
            choices={[
              { id: 'user', name: 'Client' },
              { id: 'admin', name: 'Admin' },
              { id: 'manager', name: 'Manager' },
            ]}
          />
          <BooleanInput source='allowMultipleSessions' label='Дозволити авторизацію з кількох девайсів' />
          <UserIPsWhitelist source="allowedIPs" label="IP Whitelist" />
        </FormTab>
        <FormTab label="resources.users.tabs.balance" path="balance">
          <NumberInput source={'profile.balance_1c'} />
          <NumberInput source={'profile.tmpCredit'} />
          <DateInput source={'profile.tmpCreditExpireOn'} style={{ width: 350 }} />
          <NumberInput source={'profile.credit'} />
        </FormTab>
        <FormTab label="resources.users.tabs.orders" path="orders">
          <CustomList
            basePath="/sales/orders"
            resource="sales/orders"
            filter={{ client: params.id }}
            classes={{
              content: { boxShadow: 'none' },
              root: {
                '& > .MuiToolbar-root': {
                  marginRight: 80,
                },
              },
            }}
            fullWidth
          >
            <OrdersTable />
          </CustomList>
        </FormTab>
        <FormTab label="resources.users.tabs.transactions" path="transactions">
          <CustomList
            basePath="/sales/transactions"
            resource="sales/transactions"
            filter={{ client: params.id }}
            classes={{
              content: { boxShadow: 'none' },
              root: {
                '& > .MuiToolbar-root': {
                  marginRight: 80,
                },
              },
            }}
            fullWidth
          >
            <TransactionsTable />
          </CustomList>
        </FormTab>
        <FormTab label="Платежи" path="payments">
          <CustomList
            basePath="/sales/payments"
            resource="sales/payments"
            filter={{ client: params.id }}
            classes={{
              content: { boxShadow: 'none' },
              root: {
                '& > .MuiToolbar-root': {
                  marginRight: 80,
                },
              },
            }}
            fullWidth
          >
            <PaymentsTable />
          </CustomList>
        </FormTab>
      </TabbedForm>
    </CustomEdit>
  );
};

export default UserEdit;
