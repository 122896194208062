import { CustomerTitle } from "../../fields/CustomerTitle";
import { CustomEdit } from "../../common/CustomEdit";
import ClientEditForm from "./ClientEditForm";

function transform(data) {
  const { __v, _id, files, profile, ...rest } = data;

  // TODO refactor this
  let transformedOldFiles = [];
  if (profile.files && profile.files.length) {
    transformedOldFiles = profile.files.map((f) =>
      typeof f !== "string" ? f._id : f
    );
  }

  let formData = new FormData();
  if (files && files.length) {
    files.forEach((f) => {
      formData.append("files[]", f.rawFile);
    });
  }

  formData.append(
    "data",
    JSON.stringify({
      ...rest,
      preferences: {
        ...rest.preferences,
        paymentMethods: Object.keys(rest.preferences.paymentMethods).reduce(
          (acc, key) =>
            rest.preferences.paymentMethods[key]
              ? { ...acc, [key]: true }
              : acc,
          {}
        ),
      },
      profile: {
        ...profile,
        files: transformedOldFiles,
      },
    })
  );

  return formData;
}

const ClientEdit = (props) => (
  <CustomEdit title={<CustomerTitle />} transform={transform} {...props}>
    <ClientEditForm />
  </CustomEdit>
);

export default ClientEdit;
