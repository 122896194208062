import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';

export const BackButton = (props) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />} {...props}>
      {translate('resources.categories.back')}
    </Button>
  );
};
