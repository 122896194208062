import React from 'react';
import { TextInput, BooleanInput, useRecordContext } from 'react-admin';
import { Card, CardContent, Grid, Box } from '@mui/material';
import { useStyles } from '../styles';
import FormToolbar from '../../../../common/FormToolbar';
import { PageTitle } from '../../../../common/PageTitle';

export const SeoSettingsForm = formProps => {
  const classes = useStyles();
  const record = useRecordContext();

  return (
    <Box maxWidth="70em">
      <Card>
        <PageTitle record={record} titleText="SEO settings" />
        <CardContent className={classes.cardContent}>
          <Grid container>
            <Grid item sm={12} md={4}>
              <TextInput source="name" resource="system/seo/settings" record={record} />
            </Grid>
            <Grid item sm={12} md={4}>
              <TextInput source="url" resource="system/seo/settings" record={record} />
            </Grid>
            <Grid item sm={12} md={4}>
              <BooleanInput source="active" resource="system/seo/settings" record={record} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <TextInput
                multiline
                resource="system/seo/settings"
                source="metaTitle"
                record={record}
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <TextInput
                multiline
                resource="system/seo/settings"
                source="metaDesc"
                record={record}
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <TextInput
                multiline
                resource="system/seo/settings"
                source="metaTagsH1"
                record={record}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <FormToolbar {...formProps} />
      </Card>
    </Box>
  );
};
