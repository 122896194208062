import React from 'react';
import { SimpleForm, TextInput } from 'react-admin';
import { CustomEdit } from '../../common/CustomEdit';
import FormToolbar from '../../common/FormToolbar';

const FeatureFlagEdit = ({ classes, ...props }) => (
  <CustomEdit redirect="list" {...props}>
    <SimpleForm toolbar={<FormToolbar />}>
      <TextInput source="code" autoFocus />
      <TextInput source="name" />
      <TextInput multiline source="description" />
    </SimpleForm>
  </CustomEdit>
);

export default FeatureFlagEdit;
