import React from 'react';
import { Edit } from 'react-admin';

export const CustomEdit = ({ children, ...rest }) => {
  return (
    <Edit mutationMode="optimistic" {...rest}>
      {children}
    </Edit>
  );
};
