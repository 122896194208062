import React from 'react';
import { TextField, NumberField, ReferenceField, ChipField } from 'react-admin';
import OrderPanel from '../OrderView';
import OutletAddressField from '../../../fields/OutletAddressField';
import OrderStatusField from '../../../fields/OrderStatusField';
import LocalDateField from '../../../fields/LocalDateField';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { RedirectField } from '../../../fields/RedirectField';
import { BooleanColoredField } from '../../../fields/BooleanColoredField';
import DeliveryTimeField from './DeliveryTimeField';

export const OrdersTable = props => {
  return (
    <NewTabLinkDataGrid expand={<OrderPanel />} rowClick="edit" padding="checkbox" {...props}>
      <TextField source="orderId" />
      <TextField source="docType" />
      <TextField source="code" />
      <RedirectField
        source="client.businessName"
        linkIdSource="client.id"
        linkUrl="clients"
        label="Бизнес имя"
      />
      <ReferenceField link={false} source={'deliveryMethod'} reference="sales/shipping/methods">
        <ChipField source="name" />
      </ReferenceField>
      <OutletAddressField source="outlet" />
      <LocalDateField source="deliveryDate" />
      <DeliveryTimeField source="deliveryTime.name" />
      <OrderStatusField source="orderStatus" />
      <NumberField source="totalQty" />
      <NumberField source="totalSum" options={{ style: 'currency', currency: 'UAH' }} />
      <NumberField source="totalDiscount" options={{ style: 'currency', currency: 'UAH' }} />
      <LocalDateField source="createdAt" showTime />
      <LocalDateField source="updatedAt" showTime />
      <TextField source="orderDetails.warehouse.displayName" />
      <TextField source="notes" />
      <BooleanColoredField source="packageReturn" />
      <BooleanColoredField source="isSynced" />
    </NewTabLinkDataGrid>
  );
};
