import { Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';

export const LoyaltyConditionsField = () => {
  const record = useRecordContext();
  return record.conditions
    ? Object.keys(record.conditions).map(key => (
        <Typography variant="body2" key={key}>
          {key}%&nbsp;&nbsp;-&nbsp;&nbsp;{record.conditions[key]} шт
        </Typography>
      ))
    : null;
};
