import React from 'react';
import { TextInput, SimpleForm, SelectInput, BooleanInput, ReferenceInput } from 'react-admin';
import FormToolbar from '../../../common/FormToolbar';

const PriceTypeForm = props => {
  return (
    <SimpleForm style={{ width: '100%' }} {...props} toolbar={<FormToolbar />}>
      <TextInput source="code" />
      <TextInput source="name" />
      <TextInput source="alias" />
      <ReferenceInput reference="system/currencies" source="currency.id" isRequired={true}>
        <SelectInput />
      </ReferenceInput>
      <ReferenceInput
        reference="system/price-type-groups"
        source="priceTypeGroup"
        isRequired={true}
      >
        <SelectInput />
      </ReferenceInput>
      <BooleanInput source="allowImport" />
      <BooleanInput source="isPreOrder" />
      <BooleanInput source="isTransit" />
      <hr />
      <BooleanInput source="isDefault" />
      <BooleanInput source="deleted" />
    </SimpleForm>
  );
};

export default PriceTypeForm;
