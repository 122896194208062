import React from 'react';
import { TextField, EmailField, SearchInput } from 'react-admin';
import UserStatusField from '../../fields/UserStatusField';
import LocalDateField from '../../fields/LocalDateField';
import { NewTabLinkDataGrid } from '../../common/NewTabLinkDataGrid';
import { CustomList } from '../../common/CustomList';
import { UserListAside } from './UserListAside';
import rowStyle from './rowStyle';

const userFilter = [<SearchInput source="email" />, <SearchInput source="phone" />];

export const UserList = (props) => (
  <CustomList filters={userFilter} aside={<UserListAside />} {...props}>
    <NewTabLinkDataGrid rowClick="edit" rowStyle={rowStyle}>
      <TextField source="profile.code" />
      <TextField source="username" />
      <TextField source="profile.businessName" />
      <EmailField source="email" />
      <TextField source="phone" />
      <TextField source="role" />
      <TextField source="profile.priceType.name" />
      <UserStatusField />
      <LocalDateField source="createdAt" showTime />
      <LocalDateField source="updatedAt" showTime />
    </NewTabLinkDataGrid>
  </CustomList>
);
