import { useEffect, useState } from 'react';
import SortableTree from '@nosferatu500/react-sortable-tree';
import { Paper, Toolbar, TextField, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import '@nosferatu500/react-sortable-tree/style.css';
import { CreateButton, useTheme, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import blue from '@mui/material/colors/blue';
import * as _ from 'lodash';
import IconClear from '@mui/icons-material/Clear';
import IconSave from '@mui/icons-material/Save';
import LoopIcon from '@mui/icons-material/Loop';

const styles = (theme) => ({
  wrapper: {
    height: '100%',
    width: '100%',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  barWrapper: {
    marginTop: 5,
    marginLeft: 5,
  },
  treeWrapper: {
    height: 'calc(100% - 120px)',
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
  },
  createButton: {
    align: 'right',
  },
  button: {
    color: '#777',
    '&:hover': {
      cursor: 'pointer',
      color: blue,
    },
  },
  resetButton: {
    marginLeft: '10px',
    position: 'relative',
  },
  leftIcon: {
    marginRight: 8,
  },
  icon: {
    fontSize: 18,
  },
  sortableTreeRow: {
    '& .rst__rowContents': {
      backgroundColor: '#3c3c3c',
    },
  },
});

const CreateNodeButton = withStyles(styles)(({ classes, ...props }) => {
  const translate = useTranslate();
  return (
    <CreateButton {...props} className={classes.createButton}>
      {translate('ra.action.create')}
    </CreateButton>
  );
});

/*
const ExpandButton = withStyles(styles)(translate(({translate, classes, ...props}) => (
  <Button {...props} size="small" variant="outlined"
          className={classes.textField}>{translate('pos.tree.expandAll')}</Button>
)));

const CollapseButton = withStyles(styles)(translate(({translate, classes, ...props}) => (
  <Button {...props} size="small" variant="outlined"
          className={classes.textField}>{translate('pos.tree.collapseAll')}</Button>
)));

const NextButton = withStyles(styles)(translate(({translate, classes, ...props}) => (
  <Button {...props} size="small" variant="outlined"
          className={classes.textField}>{translate('ra.navigation.next')}</Button>
)));

const PrevButton = withStyles(styles)(translate(({translate, classes, ...props}) => (
  <Button {...props} size="small" variant="outlined"
          className={classes.textField}>{translate('ra.navigation.prev')}</Button>
)));
*/

const SearchField = withStyles(styles)(({ classes, ...props }) => {
  const translate = useTranslate();
  return (
    <TextField
      {...props}
      label={translate('ra.action.search')}
      size="small"
      margin="dense"
      variant="outlined"
    />
  );
});

const TreeGrid = (props) => {
  const [theme] = useTheme();
  const translate = useTranslate();
  const navigate = useNavigate();
  const [state, setState] = useState({
    searchString: '',
    searchFocusIndex: 1,
    initialData: props.data,
    pending: false,
    ...props,
  });

  useEffect(() => {
    setState({ ...state, data: props.data, initialData: props.data });
  }, [props.data, props.data.length]);

  const productTypeResource = `catalog/productType/${state.type}`;

  const handleTreeOnMove = ({ treeData }) => {
    handleTreeOnChange(treeData);
  };

  const removeExpand = (treeData, nullifyExpand = false) => {
    const dataArr = _.cloneDeep(treeData);
    const removeItemExpand = (item, { parent = null, index = 0 }) => {
      item.sortOrder = index;
      if (nullifyExpand) {
        item.expanded = false;
      }
      item.parentId = parent;
      if (item.children && item.children.length > 0) {
        const parentId = item.id;
        item.children = item.children.map((child, index) =>
          removeItemExpand(child, { parent: parentId, index })
        );
      }
      return item;
    };
    return dataArr.map((item, index) => removeItemExpand(item, index));
  };

  const handleTreeOnChange = (data) => {
    const preparedData = removeExpand(data);
    setState({ ...state, data: preparedData });
  };

  const handleSearchOnChange = (e) => {
    setState({ ...state, searchString: e.target.value });
  };

  const goToCategoryProducts = (id) => {
    navigate(`/catalog/category/${state.type}/products/${id}`);
  };

  const saveCategories = () => {
    const currentTreeNoExpand = removeExpand(state.data, true);
    const initialTreeNoExpand = removeExpand(state.initialData, true);
    if (!_.isEqual(currentTreeNoExpand, initialTreeNoExpand)) {
      setState({ ...state, pending: true });
      state
        .onSave(state.data)
        .then(() => {
          setState({ ...state, initialData: state.data });
        })
        .finally(() => {
          setState({ ...state, pending: false });
        });
    }
  };

  const cancelCategories = () => {
    setState({ ...state, data: state.initialData });
  };

  const createClickHandler = () => {
    state.onCreate();
  };

  const { data, searchString, searchFocusIndex } = state;
  const { classes, children } = props;

  const sameAsInitial = JSON.stringify(state.data) === JSON.stringify(state.initialData);

  return data ? (
    <div className={classes.wrapper}>
      <Toolbar className={classes.barWrapper}>
        <SearchField onChange={handleSearchOnChange} />
        <CreateNodeButton
          basePath={state.basePath}
          onClick={createClickHandler}
          resource={productTypeResource}
        />
      </Toolbar>
      <Paper className={classes.treeWrapper}>
        <SortableTree
          className={theme === 'dark' ? classes.sortableTreeRow : ''}
          treeData={data}
          onChange={handleTreeOnChange}
          onMoveNode={handleTreeOnMove}
          maxDepth={5}
          searchQuery={searchString}
          searchFocusOffset={searchFocusIndex}
          canDrag={({ node }) => !node.noDragging}
          canDrop={({ nextParent }) => !nextParent || !nextParent.noChildren}
          /*searchFinishCallback={matches =>
              setState({
                searchFoundCount: matches.length,
                searchFocusIndex:
                  matches.length > 0 ? searchFocusIndex % matches.length : 0,
              })
            }*/
          isVirtualized={false}
          generateNodeProps={(rowInfo) => ({
            buttons: [
              <EditIcon className={classes.button} onClick={() => state.onEdit(rowInfo.node.id)} />,
              <DeleteIcon
                className={classes.button}
                onClick={() => state.onDelete(rowInfo.node)}
              />,
              <Button onClick={() => goToCategoryProducts(rowInfo.node.id)}>
                {translate('resources.categories.viewProducts')}
              </Button>,
            ],
          })}
        />
        {children}
        <Button className={classes.resetButton} onClick={cancelCategories} disabled={sameAsInitial}>
          <IconClear /> Скинути
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={saveCategories}
          disabled={sameAsInitial || state.pending}
        >
          {state.pending ? <LoopIcon /> : <IconSave />} Зберегти
        </Button>
      </Paper>
    </div>
  ) : null;
};

const enhance = withStyles(styles);

export default enhance(TreeGrid);
