import React from 'react';
import { Create, Form } from 'react-admin';
import { onSaveDiscount } from '../helpers';
import { ProductDiscountForm } from './components/ProductDiscountForm';

export const ProductDiscountCreate = ({ classes, ...props }) => (
  <Create mutationMode="optimistic" {...props} transform={onSaveDiscount}>
    <Form>
      <ProductDiscountForm />
    </Form>
  </Create>
);
