import { CustomList } from '../../../common/CustomList';
import { NewTabLinkDataGrid } from '../../../common/NewTabLinkDataGrid';
import { TextField } from 'react-admin';

const StaticPagesList = (props) => {
  return (
    <CustomList {...props}>
      <NewTabLinkDataGrid rowClick='edit'>
        <TextField source="alias" />
      </NewTabLinkDataGrid>
    </CustomList>
  );
};

export default StaticPagesList;