import Avatar from '@mui/material/Avatar';
import { useRecordContext } from 'react-admin';

const AvatarField = ({ size }) => {
  const record = useRecordContext();
  return (
    <Avatar
      src={`${record.avatar}?size=${size}x${size}`}
      size={size}
      style={{ width: size, height: size }}
    />
  );
};

AvatarField.defaultProps = {
  size: 25,
};

export default AvatarField;
