import React from 'react';
import {
  TextInput,
  required,
  ReferenceArrayInput,
  NumberInput,
  BooleanInput,
  AutocompleteArrayInput,
  ImageInput,
  ImageField,
  DateTimeInput,
  SelectArrayInput,
  FormDataConsumer,
  useRecordContext,
} from 'react-admin';
import { Card, CardContent, Grid, Box } from '@mui/material';
import FormToolbar from '../../../../common/FormToolbar';
import ImagePreview from '../../../../common/ImagePreview';
import { offersTypeOptions, modifyPercent } from '../../helpers';
import CategoriesReferenceInput from './CategoriesReferenceInput';

function filterToQuery(searchText, categories) {
  return { q: searchText, catalogCategory: categories };
}

export const WebsiteDiscountForm = (formProps) => {
  const record = useRecordContext();

  const modifiedRecord = modifyPercent(record);

  return (
    <Box maxWidth="70em">
      <Card>
        <CardContent>
          <Grid container spacing={8}>
            <Grid item sm={12} md={4}>
              <TextInput
                label="resources.sales/discounts/rules/website.fields.name"
                source="name"
                record={record}
                validate={[required()]}
                fullWidth
              />
              <NumberInput
                label="resources.sales/discounts/rules/website.fields.percent"
                source="percent"
                record={modifiedRecord}
                validate={[required()]}
                min={0.01}
                fullWidth
              />
              <BooleanInput
                label="resources.sales/discounts/rules/website.fields.isActive"
                source="isActive"
                record={record}
                fullWidth
              />
              <BooleanInput
                label="resources.sales/discounts/rules/website.fields.allCatalog"
                source="allCatalog"
                record={record}
                fullWidth
              />
              <BooleanInput
                label="resources.sales/discounts/rules/website.fields.allClients"
                source="allClients"
                record={record}
                fullWidth
              />
              <TextInput
                label="resources.sales/discounts/rules/website.fields.description"
                source="description"
                record={record}
                fullWidth
              />
              <DateTimeInput
                source="startDate"
                label="resources.sales/discounts/rules/website.fields.startDate"
                fullWidth
              />
              <DateTimeInput
                source="endDate"
                label="resources.sales/discounts/rules/website.fields.endDate"
                fullWidth
              />
              <SelectArrayInput
                source="offersType"
                choices={offersTypeOptions}
                label="resources.sales/discounts/rules/website.fields.offersType"
                fullWidth
              />
            </Grid>
            <Grid item sm={12} md={8}>
              <ReferenceArrayInput
                source="productType"
                reference="system/product-types"
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth
              >
                <SelectArrayInput
                  optionText="name"
                  label="resources.sales/discounts/rules/website.fields.productType"
                />
              </ReferenceArrayInput>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData && formData.productType && formData.productType.length ? (
                    <CategoriesReferenceInput
                      productType={formData.productType}
                      perPage={999}
                      fullWidth
                      {...rest}
                    />
                  ) : null
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData && formData.categories && formData.categories.length ? (
                    <ReferenceArrayInput
                      label="resources.sales/discounts/rules/website.fields.products"
                      source="products"
                      perPage={9999}
                      reference="catalog/products"
                      filterToQuery={(q) => filterToQuery(q, formData.categories)}
                      fullWidth
                      {...rest}
                    >
                      <AutocompleteArrayInput optionText="name" />
                    </ReferenceArrayInput>
                  ) : null
                }
              </FormDataConsumer>
              <ReferenceArrayInput
                reference="clients"
                label="resources.sales/discounts/rules/website.fields.clients"
                source="clients"
                record={record}
                fullWidth
              >
                <AutocompleteArrayInput optionText="profile.businessName" />
              </ReferenceArrayInput>
              <ImagePreview
                urlSource="static/product-discount-images"
                imageStyles={{ width: 300, height: 300 }}
                record={record}
              />
              <ImageInput
                label="resources.sales/discounts/rules/website.fields.image"
                source="image"
                accept={{ 'image/*': ['.*'] }}
              >
                <ImageField source="src" title="Background image" />
              </ImageInput>
            </Grid>
          </Grid>
        </CardContent>
        <FormToolbar {...formProps} />
      </Card>
    </Box>
  );
};
