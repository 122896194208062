import React from 'react';
import {
  TextInput,
  SimpleForm,
  SelectInput,
  BooleanInput,
  ReferenceInput,
  required,
  useRecordContext,
} from 'react-admin';
import FormToolbar from '../../../common/FormToolbar';

import 'react-json-editor-ui/dist/react-json-editor-ui.cjs.development.css';
// import JsonInput from '../../../inputs/JsonInput';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

// @todo replace with API-ref
const getMerchantPaymentSystemOptions = () => [
  { id: 'liqpay', name: 'LiqPay' },
  { id: 'portmone', name: 'Portmone' },
];

const getMerchantTypeOptions = () => [
  { id: 'fop', name: 'ФОП' },
  { id: 'tov', name: 'ТОВ' },
];

const MerchantForm = props => {
  const record = useRecordContext();
  return (
    <SimpleForm style={{ width: '100%' }} {...props} toolbar={<FormToolbar />}>
      <TextInput source="code" />
      <TextInput source="name" />
      <BooleanInput source="isActive" />
      <SelectInput
        source="paymentSystem"
        choices={getMerchantPaymentSystemOptions()}
        record={record}
      />
      <SelectInput source="merchantType" choices={getMerchantTypeOptions()} record={record} />
      <ReferenceInput
        source="cashRegister"
        reference="sales/business/cash-registers"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText="name" label="Каса" validate={[required()]} />
      </ReferenceInput>

      <TextInput source="configuration.publicKey" label="Public Key" />
      <TextInput source="configuration.privateKey" label="Private Key" />

      {record?.shift ? (
        <>
          <JsonView name={'Активна зміна'} collapsed={true} src={record.shift} />
        </>
      ) : (
        ''
      )}
      {/*<JsonInput source="configuration" label="Конфигурация" />*/}
    </SimpleForm>
  );
};

export default MerchantForm;
