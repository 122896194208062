import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomEdit } from '../../../common/CustomEdit';
import { SeoSettingsForm } from './components/SeoSettingsForm';

const CronJobTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? (
    <span>
      {translate('resources.system/seo/settings.title')}
      &nbsp;{record.name}
    </span>
  ) : null;
};

export const SeoSettingsEdit = props => (
  <CustomEdit title={<CronJobTitle />} component="div" {...props}>
    <Form>
      <SeoSettingsForm />
    </Form>
  </CustomEdit>
);
