import React from 'react';
import {
  SimpleForm,
  FormDataConsumer,
  BooleanInput,
  NumberInput,
  SelectInput,
  ImageInput,
  ImageField,
  required,
  ReferenceInput,
} from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';
import { CustomEdit } from '../../../common/CustomEdit';
import FormToolbar from '../../../common/FormToolbar';
import ImagePreview from '../../../common/ImagePreview';
import CategoryReferenceInput from './CategoryReferenceInput';

async function onSave({ image, ...restData }) {
  const formData = new FormData();
  if (image) {
    formData.append('files[]', image.rawFile);
  }

  formData.append('data', JSON.stringify(restData));

  return formData;
}

export const FeaturedProductsSectionEdit = ({ classes, ...props }) => (
  <CustomEdit redirect="list" {...props} transform={onSave}>
    <SimpleForm toolbar={<FormToolbar />}>
      <ReferenceInput
        source={'productType'}
        reference="system/product-types"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData &&
          formData.productType && (
            <CategoryReferenceInput productType={formData.productType} {...rest} />
          )
        }
      </FormDataConsumer>
      <NumberInput source={'order'} min={0} />
      <BooleanInput source={'enabled'} />
      <ImagePreview
        urlSource="static/offer-images"
        label="resources.homePage.offers.fields.image"
        imageStyles={{ width: 300, height: 300 }}
      />
      <ImageInput source="image" accept={{ 'image/*': ['.*'] }}>
        <ImageField source="image" title="Card image" validate={[required()]} />
      </ImageInput>
      <ColorInput source="color" />
    </SimpleForm>
  </CustomEdit>
);
