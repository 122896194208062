import React from 'react';
import { Labeled } from 'react-admin';
import { makeStyles } from '@mui/styles';
import { CardHeader, Grid, Typography } from '@mui/material';
import LocalDateField from '../fields/LocalDateField';

const useStyles = makeStyles({
  title: {
    paddingBottom: 0,
    borderBottom: '1px solid lightgrey',
  },
  titleLabel: {
    '&:first-of-type': {
      marginRight: 40,
    },

    '& :last-child': {
      padding: 0,
      margin: 0,
    },
  },
  boldCell: {
    fontWeight: 'bold',
  },
});

export const PageTitle = ({ titleText }) => {
  const classes = useStyles();

  return (
    <CardHeader
      className={classes.title}
      title={
        <Grid container alignItems="center">
          <Grid item md={6}>
            <Typography className={classes.boldCell} variant="h6">
              {titleText}
            </Typography>
          </Grid>
          <Grid container item md={6} justify="flex-end">
            <Labeled label="Обновлён" className={classes.titleLabel}>
              <LocalDateField source="updatedAt" showTime />
            </Labeled>
            <Labeled label="Создан" className={classes.titleLabel}>
              <LocalDateField source="createdAt" showTime />
            </Labeled>
          </Grid>
        </Grid>
      }
    />
  );
};
