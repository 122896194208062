import { useEffect, useState } from 'react';
import { DashboardMenuItem, Menu, useDataProvider, useTranslate } from 'react-admin';
import { CircularProgress } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import Timer from '@mui/icons-material/Timer';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CategoriesIcon from '@mui/icons-material/FormatLineSpacing';
import WarningIcon from '@mui/icons-material/Warning';
import AppsIcon from '@mui/icons-material/Apps';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StoreIcon from '@mui/icons-material/Store';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import HomeIcon from '@mui/icons-material/Home';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import { icon as FindInPageIcon } from '../components/pages/system/searchQueries';
import { icon as SearchIcon } from '../components/pages/system/search-keywords';
import { icon as SearchPrefIcon } from '../components/pages/system/search-preferences';
import { Icon as LocalOfferIcon } from '../components/pages/system/order-types';
import users from '../components/pages/users';
import catalog from '../components/pages/catalog';
import featureFlags from '../components/pages/feature-flags';
import orders from '../components/pages/orders';
import transactions from '../components/pages/transactions';
import ordersReclamations from '../components/pages/orders-reclamations';
import SubMenu from './SubMenu';
import {
  Slider,
  HomePageIcon,
  Offer,
  FeaturedProductsSections,
} from '../components/pages/home-page';
import contactUs from '../components/pages/contact-us-form';
import merchants from '../components/pages/business/merchant';
import cashiers from '../components/pages/business/cashier';
import cashRegisters from '../components/pages/business/cash-register';

import classes from './layout-style.module.scss';

const products = catalog.products;

const MENU_STATE = {
  menuSystem: true,
  menuCatalog: true,
  menuSales: true,
  menuHomePage: true,
  logistic: true,
  settings: true,
  search: true,
  loyaltyProgram: true,
  offers: true,
  cms: true,
};

const CustomMenu = () => {
  const translate = useTranslate();
  const [menuState, setMenuState] = useState(MENU_STATE);
  const dataProvider = useDataProvider();
  const [productTypes, setProductTypes] = useState([]);
  const [pending, setPending] = useState(true);
  const sidebarIsOpen = true;

  const handleToggle = menu => {
    setMenuState(state => ({ ...state, [menu]: !state[menu] }));
  };

  const MenuItem = props => (
    <Menu.Item  className={classes.menuItem} {...props} />
  );

  useEffect(() => {
    dataProvider
      .getList('common/primary-product-types', {
        pagination: { page: 1, perPage: 1000000 },
        sort: {},
      })
      .then(data => setProductTypes(data?.data || []))
      .catch(console.error)
      .finally(() => setPending(false));
  }, []);

  return (
    <Menu>
      <DashboardMenuItem  className={classes.menuItem} />
      <MenuItem
        to={`/users`}
        primaryText={translate(`resources.users.name`, {
          smart_count: 2,
        })}
        leftIcon={<users.icon />}
      />
      <MenuItem
        to={`/clients`}
        primaryText={translate(`resources.clients.name`, {
          smart_count: 2,
        })}
        leftIcon={<users.icon />}
      />
      <SubMenu
        handleToggle={() => handleToggle('menuSales')}
        isOpen={menuState.menuSales}
        sidebarIsOpen={sidebarIsOpen}
        name="pos.menu.sales"
        classes={{ sidebarIsClosed: classes.sidebarIsClosed, listItem: classes.menuItem }}
        className={classes.menuItem}
        icon={<MonetizationOnIcon />}
      >
        <MenuItem
          to={`/sales/orders`}
          primaryText={translate(`resources.sales/orders.name`, {
            smart_count: 2,
          })}
          leftIcon={<orders.icon />}
        />
        <MenuItem
          to={`/sales/expired-carts`}
          primaryText={translate(`resources.sales/expired-carts.name`)}
          leftIcon={<orders.icon />}
        />
        <MenuItem
          to={`/sales/active-carts`}
          primaryText={translate(`resources.sales/active-carts.name`)}
          leftIcon={<orders.icon />}
        />
        <MenuItem
          to={`/sales/orders-refund`}
          primaryText={translate(`resources.sales/orders-refund.name`, {
            smart_count: 2,
          })}
          leftIcon={<orders.icon />}
        />
        <MenuItem
          to={`/sales/transactions`}
          primaryText={translate(`resources.transactions.name`, {
            smart_count: 2,
          })}
          leftIcon={<transactions.icon />}
        />
        <MenuItem
          to={`/sales/payments`}
          primaryText={translate(`resources.sales/payments.name`, {
            smart_count: 2,
          })}
          leftIcon={<transactions.icon />}
        />
        <MenuItem
          to={`/sales/payments-refund`}
          primaryText={translate(`resources.sales/payments-refund.name`, {
            smart_count: 2,
          })}
          leftIcon={<transactions.icon />}
        />
        <MenuItem
          to={`/sales/orders-reclamations`}
          primaryText={translate(`resources.reclamations.name`, {
            smart_count: 2,
          })}
          leftIcon={<ordersReclamations.icon />}
        />

        <hr />

        <MenuItem
          to={`/sales/business/merchants`}
          primaryText={translate(`resources.sales/business/merchants.name`, { smart_count: 2 })}
          leftIcon={<merchants.icon />}
        />
        <MenuItem
          to={`/sales/business/cashiers`}
          primaryText={translate(`resources.sales/business/cashiers.name`, { smart_count: 2 })}
          leftIcon={<cashiers.icon />}
        />
        <MenuItem
          to={`/sales/business/cash-registers`}
          primaryText={translate(`resources.sales/business/cash-registers.name`, {
            smart_count: 2,
          })}
          leftIcon={<cashRegisters.icon />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('offers')}
        isOpen={menuState.offers}
        sidebarIsOpen={sidebarIsOpen}
        name="pos.menu.offers"
        classes={{ sidebarIsClosed: classes.sidebarIsClosed, listItem: classes.menuItem }}
        className={classes.menuItem}
        icon={<LoyaltyIcon />}
      >
        <MenuItem
          to={`/sales/offers/special-offers`}
          primaryText={translate(`resources.sales/offers/special-offers.name`)}
          leftIcon={<LoyaltyIcon />}
        />
        <MenuItem
          to={`/sales/offers/transit-offers`}
          primaryText={translate(`resources.sales/offers/transit-offers.name`)}
          leftIcon={<LoyaltyIcon />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('loyaltyProgram')}
        isOpen={menuState.loyaltyProgram}
        sidebarIsOpen={sidebarIsOpen}
        name="pos.menu.loyaltyProgram"
        classes={{ sidebarIsClosed: classes.sidebarIsClosed, listItem: classes.menuItem }}
        className={classes.menuItem}
        icon={<LoyaltyIcon />}
      >
        <MenuItem
          to={`/sales/discounts/rules/balance`}
          primaryText={translate(`resources.sales/discounts/rules/balance.name`)}
          leftIcon={<LoyaltyIcon />}
        />
        <MenuItem
          to={`/sales/discounts/rules/website`}
          primaryText={translate(`resources.sales/discounts/rules/website.name`)}
          leftIcon={<LoyaltyIcon />}
        />
        <MenuItem
          to={`/sales/discounts/rules/catalog`}
          primaryText={translate(`resources.sales/discounts/rules/catalog.name`)}
          leftIcon={<LoyaltyIcon />}
        />
        <MenuItem
          to={`/sales/discounts/rules/progressive`}
          primaryText={translate(`resources.sales/discounts/rules/progressive.name`)}
          leftIcon={<LoyaltyIcon />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuHomePage')}
        isOpen={menuState.menuHomePage}
        sidebarIsOpen={sidebarIsOpen}
        name="pos.menu.homePage"
        icon={<HomePageIcon />}
        classes={{ sidebarIsClosed: classes.sidebarIsClosed, listItem: classes.menuItem }}
        className={classes.menuItem}
      >
        <MenuItem
          to={`/home/sliders`}
          primaryText={translate(`resources.homePage.sliders.name`)}
          leftIcon={<Slider.icon />}
        />
        <MenuItem
          to={`/home/featured-products-sections`}
          primaryText={translate(`resources.homePage.productSections.name`)}
          leftIcon={<FeaturedProductsSections.icon />}
        />
        <MenuItem
          to={`/home/offers`}
          primaryText={translate(`resources.homePage.offers.name`)}
          leftIcon={<Offer.icon />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuCatalog')}
        isOpen={menuState.menuCatalog}
        sidebarIsOpen={sidebarIsOpen}
        name="pos.menu.catalog"
        icon={<products.icon />}
        classes={{ sidebarIsClosed: classes.sidebarIsClosed, listItem: classes.menuItem }}
        className={classes.menuItem}
      >
        <MenuItem
          to={`/catalog/products`}
          primaryText={translate(`resources.products.name`, {
            smart_count: 2,
          })}
          leftIcon={<products.icon />}
        />
        <MenuItem
          to={`/catalog/products-to-categories`}
          primaryText={translate(`resources.products.productsToCategories`)}
          leftIcon={<products.icon />}
        />
        {!pending ? (
          productTypes.map(productType => (
            <MenuItem
              key={`product-type-${productType.id}`}
              to={`/catalog/productType/${productType.id}`}
              primaryText={productType.name}
              leftIcon={<CategoriesIcon />}
            />
          ))
        ) : (
          <CircularProgress size={25} thickness={4} style={{ marginLeft: 20 }} />
        )}
        <MenuItem
          to={`/catalog/productType/other`}
          primaryText={translate(`resources.categories.nameOther`, {
            smart_count: 2,
          })}
          leftIcon={<CategoriesIcon />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('logistic')}
        isOpen={menuState.logistic}
        sidebarIsOpen={sidebarIsOpen}
        name="pos.menu.logistic"
        icon={<products.icon />}
        classes={{ sidebarIsClosed: classes.sidebarIsClosed, listItem: classes.menuItem }}
        className={classes.menuItem}
      >
        <MenuItem
          to={`/sales/shipping/locations`}
          primaryText={translate(`resources.sales/shipping/locations.name`, {
            smart_count: 2,
          })}
          leftIcon={<LocationCityIcon />}
        />
        <MenuItem
          to={`/sales/shipping/delivery-times`}
          primaryText={translate(`resources.sales/shipping/delivery-times.name`, {
            smart_count: 2,
          })}
          leftIcon={<ScheduleIcon />}
        />
        <MenuItem
          to={`/sales/shipping/self-pickup-times`}
          primaryText={translate(`resources.sales/shipping/self-pickup-times.name`, {
            smart_count: 2,
          })}
          leftIcon={<ScheduleIcon />}
        />
        <MenuItem
          to={`/client/outlets`}
          primaryText={translate(`resources.client/outlets.name`, {
            smart_count: 2,
          })}
          leftIcon={<HomeIcon />}
        />
        <MenuItem
          to={`/system/outlets`}
          primaryText={translate(`resources.system/outlets.name`, {
            smart_count: 2,
          })}
          leftIcon={<HomeIcon />}
        />
        <MenuItem
          to={`/sales/shipping/preorder-shipping-rules`}
          primaryText={translate(`resources.sales/shipping/preorder-shipping-rules.name`, {
            smart_count: 2,
          })}
          leftIcon={<AddShoppingCartIcon />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('cms')}
        isOpen={menuState.cms}
        sidebarIsOpen={sidebarIsOpen}
        name="pos.menu.cms"
        icon={<featureFlags.icon />}
        classes={{ sidebarIsClosed: classes.sidebarIsClosed, listItem: classes.menuItem }}
        className={classes.menuItem}
      >
        <MenuItem
          to={`/cms/static-pages`}
          primaryText={translate('pos.menu.staticPage')}
          leftIcon={<TuneIcon />}
        />
      </SubMenu>
      <SubMenu
        handleToggle={() => handleToggle('menuSystem')}
        isOpen={menuState.menuSystem}
        sidebarIsOpen={sidebarIsOpen}
        name="pos.menu.system"
        icon={<featureFlags.icon />}
        classes={{ sidebarIsClosed: classes.sidebarIsClosed, listItem: classes.menuItem }}
        className={classes.menuItem}
      >
        <MenuItem
          to={`/system/configs`}
          primaryText={translate('pos.menu.configs')}
          leftIcon={<TuneIcon />}
        />
        <MenuItem
          to={`/system/cron-jobs`}
          primaryText={translate('pos.menu.cronJobs')}
          leftIcon={<Timer />}
        />
        <MenuItem
          to={`/system/error-log`}
          primaryText={translate('pos.menu.errorLog')}
          leftIcon={<WarningIcon />}
        />
        <MenuItem
          to={`/system/1c-log`}
          primaryText={translate('pos.menu.log1c')}
          leftIcon={<WarningIcon />}
        />
        <SubMenu
          handleToggle={() => handleToggle('search')}
          isOpen={menuState.search}
          sidebarIsOpen={sidebarIsOpen}
          name="pos.menu.search"
          icon={<TuneIcon />}
          classes={{ sidebarIsClosed: classes.sidebarIsClosed, listItem: classes.menuItem }}
          className={classes.menuItem}
        >
          <MenuItem
            to={`/system/seo/search-queries`}
            primaryText={translate('pos.menu.searchQueries')}
            leftIcon={<FindInPageIcon />}
          />
          <MenuItem
            to={`/system/seo/search-keywords`}
            primaryText={translate('pos.menu.searchKeywords')}
            leftIcon={<SearchIcon />}
          />
          <MenuItem
            to={`/system/seo/search-preferences`}
            primaryText={translate('pos.menu.searchPreferences')}
            leftIcon={<SearchPrefIcon />}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => handleToggle('settings')}
          isOpen={menuState.settings}
          sidebarIsOpen={sidebarIsOpen}
          name="pos.menu.settings"
          icon={<TuneIcon />}
          classes={{ sidebarIsClosed: classes.sidebarIsClosed, listItem: classes.menuItem }}
          className={classes.menuItem}
        >
          <MenuItem
            to={`/system/product-types`}
            primaryText={translate('pos.menu.productTypes')}
            leftIcon={<LocalAtmIcon />}
          />
          <MenuItem
            to={`/system/price-types`}
            primaryText={translate('pos.menu.priceTypes')}
            leftIcon={<LocalAtmIcon />}
          />
          <MenuItem
            to={`/system/price-type-groups`}
            primaryText={translate('pos.menu.priceGroups')}
            leftIcon={<LocalAtmIcon />}
          />
          <MenuItem
            to={`/system/suppliers`}
            primaryText={translate('pos.menu.suppliers')}
            leftIcon={<LocalShippingIcon />}
          />
          <MenuItem
            to={`/system/warehouses`}
            primaryText={translate('pos.menu.warehouses')}
            leftIcon={<StoreIcon />}
          />
          <MenuItem
            to={`/system/attributes`}
            primaryText={translate('pos.menu.attributes')}
            leftIcon={<AppsIcon />}
          />
          <MenuItem
            to={`/system/order-types`}
            primaryText={translate('pos.menu.orderTypes')}
            leftIcon={<LocalOfferIcon />}
          />
        </SubMenu>
        <MenuItem
          to={`/system/seo/settings`}
          primaryText={translate('pos.menu.seoSettings')}
          leftIcon={<SettingsEthernetIcon />}
        />
      </SubMenu>
      <MenuItem
        to={`/forms/contact-us`}
        primaryText="Контакты с нами"
        leftIcon={<contactUs.icon />}
      />
    </Menu>
  );
};

export default CustomMenu;
