import React from 'react';
import classnames from 'classnames';
import LaunchIcon from '@mui/icons-material/Launch';
import { DatagridConfigurable, useRecordContext } from 'react-admin';
import { useLocation } from 'react-router-dom';
import classes from './data-grid.module.scss';

const OpenInNewTab = ({ rowClick }) => {
  const record = useRecordContext();
  const location = useLocation();

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`#${location.pathname}/${record.id}${rowClick === 'show' ? '/show' : ''}`);
  };

  return <LaunchIcon onClick={handleClick} />;
};

export const NewTabLinkDataGrid = ({ style, className, ...props }) => (
  <DatagridConfigurable className={classnames(classes.table, className)} sx={style} {...props}>
    <OpenInNewTab rowClick={props.rowClick} cellClassName={classes.openNewTab} />
    {props.children}
  </DatagridConfigurable>
);
