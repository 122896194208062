import React from 'react';
import { SearchInput } from 'react-admin';
import { CustomList } from '../../../common/CustomList';
import { ProductDiscountTable } from './components/ProductDiscountTable';

const productDiscountsFilters = [<SearchInput source="q" alwaysOn />];

export const ProductDiscountList = props => (
  <CustomList filters={productDiscountsFilters} {...props}>
    <ProductDiscountTable />
  </CustomList>
);
