import React from 'react';
import { Form } from 'react-admin';
import { CustomEdit } from '../../../common/CustomEdit';
import { onSaveConditionsDiscount } from '../helpers';
import { ConditionDiscountsForm } from './components/ConditionDiscountsForm';

export const ConditionDiscountsEdit = ({ classes, ...props }) => (
  <CustomEdit {...props} component="div" transform={onSaveConditionsDiscount}>
    <Form>
      <ConditionDiscountsForm />
    </Form>
  </CustomEdit>
);
