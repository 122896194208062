import React from 'react';
import { RedirectField } from './RedirectField';
import { useRecordContext } from 'react-admin';

const OutletAddressField = () => {
  const record = useRecordContext();
  const outlet = record?.orderDetails?.outlet;
  if (!outlet) {
    return '';
  }

  const linkIdSource = typeof record.outlet === 'string' ? 'outlet' : 'outlet.id';

  return (
    <RedirectField
      record={record}
      linkUrl="client/outlets"
      linkIdSource={linkIdSource}
      fieldText={`${outlet.city}, ${outlet.street} ${outlet.building}${
        outlet.unit ? `-${outlet.unit}` : ''
      } ${outlet.room || ''}`}
      style={{ whiteSpace: 'nowrap' }}
    />
  );
};

export default OutletAddressField;
