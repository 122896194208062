export const STATUS_CHECKOUT_PREVIEW = 'CHECKOUT';
export const STATUS_PREORDER_PENDING = '000000027';
export const STATUS_PREORDER_APPROVED = '000000002';
export const STATUS_PENDING = '000000023';
export const STATUS_RESERVED = '000000024';
export const STATUS_PACKED = '000000025';
export const STATUS_SHIPPED = '000000003';
export const STATUS_RECEIVED = '000000026';
export const STATUS_DONE = '000000022';
export const STATUS_RETURN = 'RETURN';
export const STATUS_CANCELED = '000000004';

export const OrderStatusOptions = {
  [STATUS_CHECKOUT_PREVIEW]: 'Формується користовачем',
  [STATUS_PREORDER_PENDING]: 'Передзамовлення очікує підтвердження',
  [STATUS_PREORDER_APPROVED]: 'Передзамовлення пiдтверджено',
  [STATUS_PENDING]: 'Очікує підтвердження',
  [STATUS_RESERVED]: 'Зарезервовано',
  [STATUS_PACKED]: 'Скомплектовано',
  [STATUS_SHIPPED]: 'Доставка',
  [STATUS_RECEIVED]: 'Доставлено',
  [STATUS_DONE]: 'Виконано',
  [STATUS_CANCELED]: 'Скасовано',
  [STATUS_RETURN]: 'Повернення',
};

export const getOrderStatusOptions = () => {
  const options = [];
  for (const [key, value] of Object.entries(OrderStatusOptions)) {
    options.push({
      id: key,
      name: value,
    });
  }
  return options;
};

export const deliveryTimeOptions = [
  {
    id: '8:00 - 20:00',
    name: '8:00 - 20:00',
  },
  {
    id: '8:00 - 14:00',
    name: '8:00 - 14:00',
  },
  {
    id: '14:00 - 20:00',
    name: '14:00 - 20:00',
  },
];

export const deliveryMethodOptions = [
  {
    id: 'floroteka',
    name: 'Флоротека',
  },
  {
    id: 'novaposhta',
    name: 'Нова Пошта',
  },
  {
    id: 'selfpickup',
    name: 'Самовывоз',
  },
];
