import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { SupplierPrices } from './components/SupplierPrices';
import { useRecordContext } from 'react-admin';

const cardStyle = {
  width: '100%',
  margin: '0.5em',
  display: 'block',
  verticalAlign: 'top',
};

const ProductPanel = () => {
  const record = useRecordContext();
  return (
    <Card style={cardStyle}>
      <CardContent>
        <SupplierPrices record={record} />
      </CardContent>
    </Card>
  );
};

export default ProductPanel;
