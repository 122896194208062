import React from 'react';
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';

const CustomLayout = ({ classes, ...props }) => (
  <Layout
    {...props}
    appBar={AppBar}
    menu={Menu}
    style={{
      '& main > div > div > div': {
        position: 'relative',
      },
    }}
  />
);

export default CustomLayout;
