import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomCreate } from '../../../common/CustomCreate';
import { SeoSettingsForm } from './components/SeoSettingsForm';

const SeoSettingsTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record ? <span>{translate('resources.system/seo/settings.title')}</span> : null;
};

export const SeoSettingsCreate = props => (
  <CustomCreate title={<SeoSettingsTitle />} component="div" {...props}>
    <Form>
      <SeoSettingsForm />
    </Form>
  </CustomCreate>
);
