import { BooleanInput, NumberInput, ReferenceArrayInput, SelectArrayInput, useTranslate } from 'react-admin';

const DeliverySchedule = () => {
  const translate = useTranslate();

  return ['mon','tue','wed','thu','fri','sat','sun'].map(day => (
    <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
      <BooleanInput source={`selfPickUpConfig.${day}.isActive`} label='Активный' style={{height: 40}}/>
      <div style={{minWidth: 120 }}>
        {translate(`resources.sales/shipping/days.${day}`)}
      </div>
      <ReferenceArrayInput
        label="resources.system/warehouses.fields.selfPickUpTimeSlots"
        reference="sales/shipping/self-pickup-times"
        source={`selfPickUpConfig.${day}.timeSlots`}
        fullWidth
        style={{ minWidth: 500 }}
      >
        <SelectArrayInput source="name" />
      </ReferenceArrayInput>
      <NumberInput
        label="resources.system/warehouses.fields.maxClientsOnTimeSlot"
        source={`selfPickUpConfig.${day}.maxClientsOnSlot`}
        style={{minWidth: 170 }}
      />
    </div>
  ));
};

export default DeliverySchedule;