import React from 'react';
import { Form, useRecordContext, useTranslate } from 'react-admin';
import { CustomCreate } from '../../../common/CustomCreate';
import { transformWarehouse } from './transformWarehouse';
import WarehouseForm from './WarehouseForm';

const WarehouseTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.system/warehouses.title')} {(record && record.displayName) || ''}
    </span>
  );
};

const WarehouseCreate = props => {
  return (
    <CustomCreate
      title={<WarehouseTitle />}
      component="div"
      transform={transformWarehouse}
      {...props}
    >
      <Form>
        <WarehouseForm />
      </Form>
    </CustomCreate>
  );
};

export default WarehouseCreate;
