import { Create } from 'react-admin';
import React from 'react';
import OutletForm, { transform } from './OutletForm';


const OutletsCreate = (props) => (
  <Create {...props} transform={transform}>
    <OutletForm />
  </Create>
);

export default OutletsCreate;