import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  DateTimeInput,
  required,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import FormToolbar from '../../../common/FormToolbar';
import { ColorInput } from 'react-admin-color-picker';

async function onSave({ image, ...restData }) {
  const formData = new FormData();
  if (image) {
    formData.append('files[]', image.rawFile);
  }

  formData.append('data', JSON.stringify(restData));

  return formData;
}

export function filterToQuery(searchText) {
  return { q: searchText };
}

export const OfferCreate = ({ classes, ...props }) => (
  <Create redirect="list" {...props} transform={onSave}>
    <SimpleForm toolbar={<FormToolbar />}>
      <ReferenceInput
        label="resources.homePage.offers.fields.product"
        source="product"
        reference="catalog/products"
        filterToQuery={filterToQuery}
      >
        <AutocompleteInput optionText="name" validate={[required()]} />
      </ReferenceInput>
      <TextInput
        source="header"
        label="resources.homePage.offers.fields.header"
        validate={[required()]}
        autoFocus
      />
      <ColorInput
        source="color"
        label="resources.homePage.offers.fields.color"
        validate={[required()]}
      />
      <DateTimeInput source="expiredTime" label="resources.homePage.offers.fields.expiredTime" />
      <ImageInput
        label="resources.homePage.offers.fields.image"
        source="image"
        accept={{ 'image/*': ['.*'] }}
      >
        <ImageField source="src" title="Background image" validate={[required()]} />
      </ImageInput>
    </SimpleForm>
  </Create>
);
