import React from 'react';
import { CustomEdit } from '../../../common/CustomEdit';
import ProductTypeForm, { transform } from './ProductTypeForm';

const ProductTypeEdit = props => (
  <CustomEdit {...props} transform={transform}>
    <ProductTypeForm />
  </CustomEdit>
);

export default ProductTypeEdit;
