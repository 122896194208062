import React from 'react';
import {
  TextInput,
  required,
  ReferenceArrayInput,
  BooleanInput,
  AutocompleteArrayInput,
  SelectArrayInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  ImageInput,
  ImageField,
  useRecordContext,
} from 'react-admin';
import { Card, CardContent, Grid, Box } from '@mui/material';
import FormToolbar from '../../../../common/FormToolbar';
import { modifyConditionsToArray, offersTypeOptions } from '../../helpers';
import CategoriesReferenceInput from './CategoriesReferenceInput';
import { useStyles } from '../styles';
import ImagePreview from '../../../../common/ImagePreview';

function filterToQuery(searchText, categories) {
  return { q: searchText, catalogCategory: categories };
}

export const ConditionDiscountsForm = (formProps) => {
  const classes = useStyles();
  const record = useRecordContext();

  const modifiedRecord = modifyConditionsToArray(record);
  const notEmptyArray = (data) => Array.isArray(data) && data.length;

  return (
    <Box maxWidth="70em">
      <Card>
        <CardContent>
          <Grid container spacing={8}>
            <Grid item sm={12} md={4}>
              <TextInput
                label="resources.sales/discounts/rules/progressive.fields.name"
                source="name"
                record={record}
                validate={[required()]}
                fullWidth
              />
              <BooleanInput
                label="resources.sales/discounts/rules/progressive.fields.isActive"
                source="isActive"
                record={record}
                fullWidth
              />
              <BooleanInput
                label="resources.sales/discounts/rules/progressive.fields.allCatalog"
                source="allCatalog"
                record={record}
                fullWidth
              />
              <BooleanInput
                label="resources.sales/discounts/rules/progressive.fields.allClients"
                source="allClients"
                record={record}
                fullWidth
              />
              <TextInput
                label="resources.sales/discounts/rules/progressive.fields.description"
                source="description"
                record={record}
                fullWidth
              />
              <SelectArrayInput
                source="offersType"
                choices={offersTypeOptions}
                label="resources.sales/discounts/rules/progressive.fields.offersType"
                fullWidth
              />
            </Grid>
            <Grid item sm={12} md={8}>
              <ReferenceArrayInput
                source="productType"
                reference="system/product-types"
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth
              >
                <SelectArrayInput
                  optionText="name"
                  label="resources.sales/discounts/rules/progressive.fields.productType"
                />
              </ReferenceArrayInput>
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                  <ReferenceArrayInput
                    label="resources.sales/discounts/rules/progressive.fields.categories1C"
                    source="categories1C"
                    perPage={9999}
                    reference="catalog/categories-1c"
                    fullWidth
                    disabled={formData && notEmptyArray(formData.categories)}
                    {...rest}
                  >
                    <AutocompleteArrayInput optionText="name" />
                  </ReferenceArrayInput>
                )}
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData &&
                  notEmptyArray(formData.productType) &&
                  !notEmptyArray(formData.categories1C) ? (
                    <CategoriesReferenceInput
                      productType={formData.productType}
                      fullWidth
                      perPage={999}
                      {...rest}
                    />
                  ) : null
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData &&
                  notEmptyArray(formData.categories) &&
                  !notEmptyArray(formData.categories1C) ? (
                    <ReferenceArrayInput
                      label="resources.sales/discounts/rules/progressive.fields.products"
                      source="products"
                      perPage={9999}
                      reference="catalog/products"
                      filterToQuery={(q) => filterToQuery(q, formData.categories)}
                      fullWidth
                      {...rest}
                    >
                      <AutocompleteArrayInput optionText="name" />
                    </ReferenceArrayInput>
                  ) : null
                }
              </FormDataConsumer>
              <ReferenceArrayInput
                reference="clients"
                label="resources.sales/discounts/rules/progressive.fields.clients"
                source="clients"
                record={record}
                fullWidth
              >
                <AutocompleteArrayInput optionText="profile.businessName" />
              </ReferenceArrayInput>
              <ArrayInput
                label="resources.sales/discounts/rules/progressive.fields.conditions"
                source="conditionsArray"
                record={modifiedRecord}
              >
                <SimpleFormIterator classes={{ form: classes.conditionsContainer }}>
                  <TextInput
                    className={classes.conditionInput}
                    label="resources.sales/discounts/rules/progressive.fields.qty"
                    source="value"
                  />
                  <TextInput
                    className={classes.conditionInput}
                    label="resources.sales/discounts/rules/progressive.fields.percent"
                    source="key"
                  />
                </SimpleFormIterator>
              </ArrayInput>
              <ImagePreview
                urlSource="static/product-discount-images"
                imageStyles={{ width: 300, height: 300 }}
                record={record}
              />
              <ImageInput
                label="resources.sales/discounts/rules/progressive.fields.image"
                source="image"
                accept={{ 'image/*': ['.*'] }}
              >
                <ImageField source="src" title="Background image" />
              </ImageInput>
            </Grid>
          </Grid>
        </CardContent>
        <FormToolbar {...formProps} />
      </Card>
    </Box>
  );
};
