import React from 'react';
import { Create, Form } from 'react-admin';
import { onSaveWebsiteDiscount } from '../helpers';
import { WebsiteDiscountForm } from './components/WebsiteDiscountForm';

export const WebsiteDiscountCreate = ({ classes, ...props }) => (
  <Create mutationMode="optimistic" {...props} transform={onSaveWebsiteDiscount}>
    <Form>
      <WebsiteDiscountForm />
    </Form>
  </Create>
);
