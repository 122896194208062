import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  chipsFields: {
    margin: 0,
  },
  conditionsContainer: {
    display: 'flex',
  },
  conditionInput: {
    width: 160,

    '& :not(:last-child)': {
      marginRight: 20,
    },
  },
});
