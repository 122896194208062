import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { RedirectField } from '../fields/RedirectField';

export const ClientDataView = ({ data }) => {
  const { name, phone, availableFunds, credit, tmpCredit } = data;
  return (
    <Grid container style={{ width: 280 }}>
      <Typography variant="h6">Данные клиента</Typography>
      <Grid container justify={'space-between'} spacing={0}>
        <Grid item>
          <Typography variant="body2">Имя:&nbsp;</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{name}</Typography>
        </Grid>
      </Grid>
      <Grid container justify={'space-between'} spacing={0}>
        <Grid item>
          <Typography variant="body2">Телефон:&nbsp;</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{phone}</Typography>
        </Grid>
      </Grid>
      <Grid container justify={'space-between'} spacing={0}>
        <Grid item>
          <Typography variant="body2">Доступный кредит:&nbsp;</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{availableFunds}</Typography>
        </Grid>
      </Grid>
      <Grid container justify={'space-between'} spacing={0}>
        <Grid item>
          <Typography variant="body2">Кредит:&nbsp;</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{credit}</Typography>
        </Grid>
      </Grid>
      <Grid container justify={'space-between'} spacing={0}>
        <Grid item>
          <Typography variant="body2">Временный кредит:&nbsp;</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{tmpCredit}</Typography>
        </Grid>
      </Grid>
      <Grid container justify={'space-between'} spacing={0}>
        <Grid item>
          <Typography variant="body2">Бизнес имя:&nbsp;</Typography>
        </Grid>
        <Grid item>
          <RedirectField
            record={data}
            source="businessName"
            linkIdSource="user"
            linkUrl="clients"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
