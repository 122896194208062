import React from 'react';
import {
  ArrayField,
  ChipField,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { NewTabLinkDataGrid } from '../../../../common/NewTabLinkDataGrid';
import { BooleanColoredField } from '../../../../fields/BooleanColoredField';
import LocalDateField from '../../../../fields/LocalDateField';

export const SpecialOffersTable = (props) => (
  <NewTabLinkDataGrid rowClick="edit" {...props}>
    <TextField source="name" />
    <BooleanColoredField source="isActive" />
    <ReferenceField reference="system/suppliers" source="supplier.id" label="Supplier">
      <TextField source="name" />
    </ReferenceField>

    {/*<ReferenceField reference="system/warehouses" source="warehouse.id" label="Warehouse">
      <TextField source="name" />
    </ReferenceField>*/}

    <ReferenceArrayField label="Warehouses" reference="system/warehouses" source="warehouses">
      <SingleFieldList>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceArrayField>

    {/*<ArrayField label="Products" source="productsPricing">
      <SingleFieldList>
        <ReferenceField reference="catalog/products" source="product" target="_id">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceField>
      </SingleFieldList>
    </ArrayField>*/}

    <ArrayField label="Products" source="productsPricing">
      <SingleFieldList>
        <ReferenceField reference="catalog/products" source="product">
          {/*<ChipField source="name" />*/}
          <TextField source="name" />
        </ReferenceField>
      </SingleFieldList>
    </ArrayField>

    {/*<ArrayField source="productsPricing">
      <SingleFieldList>
        <ReferenceField reference="catalog/products" source="product">
          <ChipField source="name" />
        </ReferenceField>
      </SingleFieldList>
    </ArrayField>*/}

    <LocalDateField source="startDate" showTime />
    <LocalDateField source="endDate" showTime />
  </NewTabLinkDataGrid>
);
