import React from 'react';
import { TextField, NumberField, SearchInput } from 'react-admin';
import { NewTabLinkDataGrid } from '../../common/NewTabLinkDataGrid';
import { CustomList } from '../../common/CustomList';
import { BooleanColoredField } from '../../fields/BooleanColoredField';
import { CountryField } from '../../fields/CountryField';
import { DaysField } from '../../fields/DaysField';

const preorderRulesFilter = [<SearchInput source="q" alwaysOn />];

export const PreorderRulesList = props => (
  <CustomList filters={preorderRulesFilter} {...props}>
    <NewTabLinkDataGrid rowClick="edit">
      <CountryField source="country" />
      <TextField source="manufacturer" />
      <NumberField source="dayMargin" />
      <BooleanColoredField source="active" />
      <DaysField source="days" />
    </NewTabLinkDataGrid>
  </CustomList>
);
