import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import IconClear from '@mui/icons-material/Clear';

const useStyles = makeStyles(
  (theme) => ({
    button: {
      marginLeft: '10px',
      position: 'relative',
    },
    leftIcon: {
      marginRight: 8,
    },
    icon: {
      fontSize: 18,
    },
  }),
  { name: 'ResetButton' }
);

const sanitizeRestProps = ({
  invalid,
  pristine,
  saving,
  handleSubmit,
  handleSubmitWithRedirect,
  onSave,
  ...rest
}) => rest;

export const ResetButton = ({ className, icon, ...rest }) => {
  const classes = useStyles();
  const { reset } = useFormContext();

  const handleClick = useCallback(() => {
    reset({}, { keepDirtyValues: false });
    reset({}, { keepDirtyValues: true });
  }, []);

  return (
    <Button
      className={classnames(classes.button, className)}
      onClick={handleClick}
      {...sanitizeRestProps(rest)}
    >
      {icon
        ? React.cloneElement(icon, { className: classnames(classes.leftIcon, classes.icon) })
        : null}
    </Button>
  );
};

ResetButton.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  icon: PropTypes.element,
};

ResetButton.defaultProps = {
  icon: <IconClear />,
  label: 'Очистити',
};
